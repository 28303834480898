// ThemeContext.js

import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {muiDarkTheme, muiLigthTheme, muiTestTheme} from "./Theme";

const ThemeContext = createContext();

export function useThemeContext() {
  return useContext(ThemeContext);
}

export function MyThemeProvider({ children }) {
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
          <ThemeProvider theme={darkMode ? muiDarkTheme : muiLigthTheme}>
          {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}
