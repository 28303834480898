import * as React from "react";

import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import Sidenav from "../Sidenav";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useNavigate } from "react-router-dom";

import DataServiceForAds from "../services/ads.service";
import service from "../services";
import AlertAddressDialog from "../others/alertAddress";
import AlertAdsDialog from "../others/alertAds";

import PreviewAds from "../others/previewAds";
import CameraCapture from "../others/CameraCapture";
import PaymentSummary from "../billing/paymentSummary";
import InputRRSS from "../others/inputRRSS";
import PaymentButton from "../billing/paymentButton";
import HelpSpeedDial from "../others/FloatingActionsHelp";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import "../dashboard/Ads.css";

import {
  States,
  Cities,
  Categories,
  Agreements,
  KindsOf,
  AdsStyles,
} from "../services/dbAds";

import ImageUpload from "../others/ImageUpload";
import { isValidPhone, isValidFile } from "../utils/utils";

const steps = [
  {
    label: "Tipo de Anuncio",
    description: `Seleccione el tipo de anuncio.`,
  },

  {
    label: "Informacion Personal",
    description: `Ingrese sus datos de contacto.`,
  },
  {
    label: "Informacion Geografica",
    description: `Ingrese la ubicacion del producto/servicio. La opcion de "usar ubicacion actual" permitira 
                  a sus clientes conocer la distancia a la cual se encuentra del producto/servicio ofrecido.`,
  },
  {
    label: "Informacion del Producto o Servicio",
    description: `Ingrese la categoria a la que pertenece su producto/servicio. Esto mejorara la busqueda.`,
  },
  {
    label: "Informacion a Publicar",
    description:
      `Ingrese una descripcion del producto/servicio. El valor del anuncio es de un valor base (que 
                  depende del tipo de anuncio) mas un adicional de $100/dia. ` +
      (process.env.REACT_APP_CE_FREE_PUBLICATION
        ? " Por tiempo limitado el anuncio standar esta con costo $0."
        : ""),
  },

  {
    label: "Informacion del Periodo de Publicacion",
    description: `Ingrese el rango de fechas en el que quieres publicar. Si publicas la semana corrida, entonces sabado y
                  domingo son gratis!`,
  },
  {
    label: "Informacion Legal",
    description: ``,
  },
  {
    label: "Informacion del Pago",
    description: `Su anuncio sera publicado una vez que el pago haya sido realizado.`,
  },
];

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

dayjs.extend(utc);
dayjs.extend(timezone);

export default function PublishAds(props) {
  const navigate = useNavigate();

  const [cities, setCities] = React.useState([]);
  const [usarGPS, setUsarGPS] = React.useState(false);
  const [disabledUsarGPS, setDisabledUsarGPS] = React.useState(true);

  const [kindsof, setKindsOf] = React.useState([]);

  const [coordenadas, setCoordenadas] = React.useState({});
  const [redesSociales, setRedesSociales] = React.useState([]);

  const [textoBasic, setTextoBasic] = React.useState("");
  const [disabledTextoBasic, setDisabledTextoBasic] = React.useState(false);
  const [textoFull, setTextoFull] = React.useState("");
  const [disabledTextoFull, setDisabledTextoFull] = React.useState(true);
  const [tituloPremium, setTituloPremium] = React.useState("");
  const [disabledTituloPremium, setDisabledTituloPremium] =
    React.useState(true);

  const [piePremium, setPiePremium] = React.useState("");
  const [disabledPiePremium, setDisabledPiePremium] = React.useState(true);

  const [textoPremium, setTextoPremium] = React.useState("");
  const [disabledTextoPremium, setDisabledTextoPremium] = React.useState(true);

  const [errorAnuncio, setErrorAnuncio] = React.useState(false);
  const [tituloAnuncio, setTituloAnuncio] = React.useState("");
  const [pieAnuncio, setPieAnuncio] = React.useState("");

  const [textoAnuncio, setTextoAnuncio] = React.useState("");
  const [tipoAnuncio, setTipoAnuncio] = React.useState(AdsStyles[0]);

  const [initialDate, setInitialDate] = React.useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = React.useState(
    dayjs().startOf("day").add(process.env.REACT_APP_MAX_PUBLISH_DAY, "day")
  );

  const [isLoading, setIsLoading] = React.useState(true);
  const [tariff, setTariff] = React.useState(null);
  const [valorBase, setValorBase] = React.useState(0);
  const [valorDiario, setValorDiario] = React.useState(0);
  const [valorAnuncio, setValorAnuncio] = React.useState(0);
  const [numDiasNormal, setNumDiasNormal] = React.useState(0);
  const [numDiasPromocion, setNumDiasPromocion] = React.useState(0);

  //const [theme, setTheme]= React.useState(props.theme);
  //React.useEffect(() => {
  //    setTheme(props.theme)
  //}, [props.theme]);

  React.useEffect(() => {
    service
      .getTariff({ adsDate: dayjs().format("YYYY-MM-DD"), adsType: "anuncio" })
      .then((response) => {
        setIsLoading(false);
        setTariff(response.tariff);
        setValorBase(response.tariff.valorBasico);
        setValorDiario(response.tariff.valorDia.lunes);
        setValorAnuncio(
          response.tariff.valorBasico + response.tariff.valorDia.lunes
        );
      });

    handleReset();
  }, []);

  React.useEffect(() => {
    let diferenciaEnDias = endDate.diff(initialDate, "day") - 1;
    let numDiasPromocion = Math.floor((diferenciaEnDias + 1) / 7) * 2;
    let numDiasNormal = diferenciaEnDias + 1 - numDiasPromocion;

    setNumDiasPromocion(numDiasPromocion);
    setNumDiasNormal(numDiasNormal);
    setValorAnuncio(valorBase + valorDiario * numDiasNormal);
  }, [initialDate, endDate, valorBase, valorDiario]);

  const handleRRSS = (nuevoArreglo) => {
    setRedesSociales(nuevoArreglo);
  };

  const [typeSelected, setTypeSelected] = React.useState("texto"); // anuncio o banner
  const handleTextoSelected = () => {
    setTypeSelected("texto");
  };
  const handleBannerSelected = () => {
    setTypeSelected("banner");
  };

  const [returnUrl, setReturnUrl] = React.useState(
    window.location.protocol +
      "//" +
      window.location.host +
      "/" +
      process.env.REACT_APP_CE_COMMIT_TRANSACTION_URL
  );
  const [isPending, setIsPending] = React.useState(true);
  const [token, setToken] = React.useState(undefined);
  const [url, setUrl] = React.useState(undefined);

  const saveAds = (event) => {
    event.preventDefault();

    setIsSaving(true);

    var data = {
      order: 0,
      rut: null,
      nombre: nombre,
      email: email,
      telefono: "+" + process.env.REACT_APP_NUMERIC_COUNTRY_CODE + telefono,
      usarRRSS: redesSociales.length > 0 ? true : false,
      redesSociales: redesSociales,

      region: region,
      comuna: comuna,
      direccion: direccion,
      coordenadas: coordenadas,
      usarGPS: usarGPS,

      categoria: categoria,
      operacion: operacion,
      producto: producto,
      estadoProducto: estadoProducto,
      imagen: flagImage ? imageData : null,

      fechaIni: initialDate.startOf("day"),
      fechaFin: endDate.endOf("day"),
      diasSemana: diasSemana,

      tipoPublicacion: typeSelected === "texto" ? "anuncio" : "banner",
      tipoAnuncio: tipoAnuncio.type,
      textoAnuncio: textoAnuncio,
      tituloAnuncio: tituloAnuncio,
      pieAnuncio: pieAnuncio,

      banner:
        typeSelected === "texto"
          ? null
          : {
              nombre: selectedImage.name,
              tamano: selectedImage.size,
              tipo: selectedImage.type,
              imagen: imageBase64,
            },

      numDiasNormal: numDiasNormal,
      numDiasPromocion: numDiasPromocion,
      valorAnuncio: valorAnuncio,
      valorBase: valorBase,
      valorDiario: valorDiario,

      aceptaCondiciones: checkedLegal,

      estadoPago:
        process.env.REACT_APP_CE_FREE_PUBLICATION &&
        tipoAnuncio.type === "basico"
          ? "libre"
          : "Pendiente",
      modoPago:
        process.env.REACT_APP_CE_FREE_PUBLICATION &&
        tipoAnuncio.type === "basico"
          ? "no aplica"
          : "Pendiente",
      fechaPago:
        process.env.REACT_APP_CE_FREE_PUBLICATION &&
        tipoAnuncio.type === "basico"
          ? dayjs()
          : null,

      estadoAnuncio: {
        estado: "Nuevo",
        fecha: dayjs(),
        nota: "Ingreso Web",
        usuario: "web",
      },

      estadoRegistro: {
        estado: "Ingresado",
        fecha: dayjs(),
        nota: "Ingreso Web",
        usuario: "web",
      },

      anuncioRelacionado: 0,
    };

    DataServiceForAds.create(data) // guarda anuncio
      .then((response) => {
        setId(response.data.id);
        var dataTbk = {
          orderId: response.data.orden,
          sessionId: response.data.id,
          amount: valorAnuncio,
          url: returnUrl,
        };

        if (
          process.env.REACT_APP_CE_FREE_PUBLICATION &&
          tipoAnuncio.type === "basico"
        ) {
          setIsPending(false);
          setIsSaving(false);
          handleOpenAlert();
        } else {
          // a cobrarle a todos
          DataServiceForAds.createTransactionWebPayPlus(dataTbk) // crea transaccion webpay
            .then((response) => {
              setToken(response.data.token);
              setUrl(response.data.url);
            })
            .finally(() => {
              setIsPending(false);
              setIsSaving(false);
              handleOpenAlert();
            });
        }
      })

      .catch((e) => {
        setIsSaving(false);
        handleOpenErrorAlert();
      });
  };

  const handleFreePublication = (event) => {
    event.preventDefault();
    if (id) {
      //marcar aviso como pagado pero libre de cobro
      DataServiceForAds.updateStatus(id, {
        estado: "Pagado",
        fecha: dayjs(),
        nota: "publicacion gratis",

        usuario: "web",
      })
        .then((response) => {
          navigate("/?banner=false");
        })
        .catch((e) => {
          setIsSaving(false);
          handleOpenErrorAlert();
          console.log(e);
          return;
        });
    } else {
      setIsSaving(false);
      handleOpenErrorAlert();
      console.log("ops.. no tengo id");
    }
  };

  const handleCancelPublication = (event) => {
    event.preventDefault();
    if (id) {
      //marcar aviso como cancelado, solo para fines estadisticos
      DataServiceForAds.updateStatus(id, {
        estado: "Cancelado",
        fecha: dayjs(),
        nota: "usuario no termino el proceso de publicacion",
        usuario: "web",
      })
        .then((response) => {
          navigate("/?banner=true");
        })
        .catch((e) => {
          setIsSaving(false);
          handleOpenErrorAlert();
          console.log(e);
          return;
        });
    } else {
      setIsSaving(false);
      handleOpenErrorAlert();
      console.log("ops.. no tengo id");
    }
  };

  // identificador del anuncio
  const [id, setId] = React.useState(undefined);

  // validaciones informacion personal
  const [nombre, setNombre] = React.useState("");
  const [errorNombre, setErrorNombre] = React.useState(false);
  const handleNombreChange = (event) => {
    setNombre(event.target.value);
    if (event.target.value.length > 0) setErrorNombre(false);
  };

  const [errorEmail, setErrorEmail] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (event.target.value.length > 0) setErrorEmail(false);
  };

  const [errorTelefono, setErrorTelefono] = React.useState(false);
  const [telefono, setTelefono] = React.useState("");
  const [telefonoAnterior, setTelefonoAnterior] = React.useState("");
  const [contAvisosLibres, setContAvisosLibres] = React.useState(0);

  const handleTelefonoChange = (event) => {
    setTelefono(event.target.value);
    if (event.target.value.length > 0) setErrorTelefono(false);
  };

  // validaciones informacion geografica
  const [errorRegion, setErrorRegion] = React.useState(false);
  const [region, setRegion] = React.useState("");
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
    setComuna("");
    setDireccion("");
    setValidDireccion(false);
    setUsarGPS(false);
    setDisabledUsarGPS(true);
    setCities(Cities.filter((city) => city.region === event.target.value));
    if (event.target.value.length > 0) setErrorRegion(false);
  };

  const [errorComuna, setErrorComuna] = React.useState(false);
  const [comuna, setComuna] = React.useState("");
  const handleComunaChange = (event) => {
    setComuna(event.target.value);
    setDireccion("");
    setValidDireccion(false);
    setUsarGPS(false);
    setDisabledUsarGPS(true);
    if (event.target.value.length > 0) setErrorComuna(false);
  };

  const [errorGeocode, setErrorGeocode] = React.useState(true);
  const [validDireccion, setValidDireccion] = React.useState(false);
  const [errorDireccion, setErrorDireccion] = React.useState(false);
  const [direccion, setDireccion] = React.useState("");
  const handleDireccionChange = (event) => {
    setDireccion(event.target.value);
    setValidDireccion(false);
    setUsarGPS(false);
    if (event.target.value.length > 0) {
      setErrorDireccion(false);
      setDisabledUsarGPS(false);
    }
  };

  const handleUsarGPS = (event) => {
    if (direccion.length > 0) {
      setUsarGPS(event.target.checked);
      let googleAddres =
        direccion +
        "," +
        comuna +
        "," +
        process.env.REACT_APP_ALPHA_COUNTRY_CODE;
      if (!validDireccion) {
        service
          .getCoordinates({ address: googleAddres })
          .then((response) => {
            if (response.status === 200) {
              setErrorGeocode(false);
              if (response.location.location_type === "ROOFTOP") {
                setValidDireccion(true);
                setErrorDireccion(false);
                setCoordenadas(response.location);
                setUsarGPS(true);
              } else {
                setCoordenadas(response.location);
                handleClickOpenAlertAddress(
                  "Se obtubo un resultado aproximado para la direccion -" +
                    response.location.formatted_address +
                    "-. Desea itentar nuevamente?"
                );
              }
            } else {
              setValidDireccion(false);
              setErrorDireccion(true);
              setUsarGPS(false);
              setErrorGeocode(true);

              handleClickOpenAlertAddress(
                "No pude validar la direccion, desea intentar nuevamente?"
              );
            }
          })
          .catch((error) => {
            setCoordenadas({});
            setValidDireccion(false);
            setErrorDireccion(true);
            setUsarGPS(false);
            setErrorGeocode(true);
            handleOpenErrorGPS();
            console.log(error.message);
          });
      }
    } else {
      setDisabledUsarGPS(false);
    }
  };

  const [openErrorGPS, setOpenErrorGPS] = React.useState(false);
  const handleOpenErrorGPS = () => {
    setOpenErrorGPS(true);
  };
  const handleCloseErrorGPS = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorGPS(false);
  };

  const [openAlertAddress, setOpenAlertAddress] = React.useState(false);
  const [msgeAlertAddress, setMsgeAlertAddress] = React.useState("");
  const handleClickOpenAlertAddress = (msg) => {
    setMsgeAlertAddress(msg);
    setOpenAlertAddress(true);
  };
  const handleCloseAlertAddress = (opt) => {
    setOpenAlertAddress(false);
    if (opt === "continue") {
      if (errorGeocode) {
        setUsarGPS(false);
      } else {
        setUsarGPS(true);
      }
      setValidDireccion(true);
      setErrorDireccion(false);
    } else {
      setValidDireccion(false);
      setErrorDireccion(true);
      setUsarGPS(false);
    }
  };

  const [openAlertFreeAds, setOpenAlertFreeAds] = React.useState(false);
  const [msgeAlertFreeAds, setMsgeAlertFreeAds] = React.useState("");
  const handleClickOpenAlertFreeAds = () => {
    setMsgeAlertFreeAds();
    setOpenAlertFreeAds(true);
  };
  const handleClickCloseAlerFreeAds = () => {
    setOpenAlertFreeAds(false);
  };

  // validaciones informacion categoria
  const [errorCategoria, setErrorCategoria] = React.useState(false);
  const [categoria, setCategoria] = React.useState("");
  const handleCategoriaChange = (event) => {
    setCategoria(event.target.value);
    if (event.target.value.length > 0) setErrorCategoria(false);
    setKindsOf(
      KindsOf.filter((kindOf) => kindOf.Category === event.target.value)
    );
  };

  const [errorOperacion, setErrorOperacion] = React.useState(false);
  const [operacion, setOperacion] = React.useState("");
  const handleOperacionChange = (event) => {
    setOperacion(event.target.value);
    if (event.target.value.length > 0) setErrorOperacion(false);
  };

  const [errorProducto, setErrorProducto] = React.useState(false);
  const [producto, setProducto] = React.useState("");
  const handleProductoChange = (event) => {
    setProducto(event.target.value);
    if (event.target.value.length > 0) setErrorProducto(false);
  };

  const [estadoProducto, setEstadoProducto] = React.useState("na");
  const handleRadioEstadoProducto = (event) => {
    setEstadoProducto(event.target.value);
  };

  const [flagImage, setFlagImage] = React.useState(false);
  const [imageData, setImageData] = React.useState("nodisponible.jpg");
  const [openCameraCapture, setOpenCameraCapture] = React.useState(false);
  const handleClickOpenCameraCapture = () => {
    setOpenCameraCapture(true);
  };
  const handleCloseCameraCapture = (imageData) => {
    if (imageData) {
      setFlagImage(true);
      setImageData(imageData);
    }
    setOpenCameraCapture(false);
  };

  const [openImageUpload, setOpenImageUpload] = React.useState(false);
  const handleClickOpenImageUpload = () => {
    setOpenImageUpload(true);
  };
  const handleCloseImageUpload = (imageData) => {
    if (imageData) {
      setFlagImage(true);
      setImageData(imageData);
    }
    setOpenImageUpload(false);
  };

  // validaciones informacion del periodo
  const minDate = dayjs();
  const maxDate = dayjs().add(1, "month");
  const [errorInitialDate, setErrorInitialDate] = React.useState(null);
  const [errorEndDate, setErrorEndDate] = React.useState(null);

  const errorMessageInitialDate = React.useMemo(() => {
    switch (errorInitialDate) {
      case "maxDate": {
        return "Por favor, ingrese un rango de fechas menor a un año";
      }
      case "minDate": {
        return "Por favor, ingrese una mecha mayor a la de hoy";
      }

      case "invalidDate": {
        return "La fecha ingresada no es valida";
      }

      default: {
        return "";
      }
    }
  }, [errorInitialDate]);

  const errorMessageEndDate = React.useMemo(() => {
    switch (errorEndDate) {
      case "maxDate": {
        return "Por favor, ingrese un rango de fechas menor a un año";
      }
      case "minDate": {
        return "Por favor, ingrese una fecha mayor a la fecha inicial de publicacion";
      }

      case "invalidDate": {
        return "La fecha ingresada no es valida";
      }

      default: {
        return "";
      }
    }
  }, [errorEndDate]);

  const handleInitialDate = (date) => {
    if (date.isAfter(minDate) || date.isSame(minDate, "day")) {
      setInitialDate(date);
      setErrorInitialDate("");
    } else {
      if (date.isBefore(minDate)) {
        setErrorInitialDate("invalidDate");
      } else {
        setErrorInitialDate("invalidDate");
      }
    }
  };

  const handleEndDate = (date) => {
    if (date.isAfter(initialDate)) {
      setEndDate(date);
      setErrorEndDate("");
    } else {
      if (date.isBefore(initialDate) || date.isSame(initialDate, "day")) {
        setErrorEndDate("minDate");
      } else {
        setErrorEndDate("invalidDate");
      }
    }
  };

  const [diasSemana, setDiasSemana] = React.useState({
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false,
  });

  // validaciones informacion a publicar
  const MAX_LARGO_TEXTO_BASICO = 140;
  const MAX_LARGO_TEXTO_FULL = 140;
  const MAX_LARGO_TEXTO_PREMIUM = 100;
  const MAX_LARGO_ENC_PREMIUM = 15;
  const MAX_LARGO_PIE_PREMIUM = 25;

  const [largoTextoBasico, setLargoTextoBasico] = React.useState(0);
  const [largoTextoFull, setLargoTextoFull] = React.useState(0);
  const [largoTextoPremium, setLargoTextoPremium] = React.useState(0);
  const [largoEncPremium, setLargoEncPremium] = React.useState(0);
  const [largoPiePremium, setLargoPiePremium] = React.useState(0);

  const handleTextoAnuncioChange = (event) => {
    setTextoAnuncio(event.target.value);
    if (tipoAnuncio.type === "basico") {
      if (event.target.value.length <= MAX_LARGO_TEXTO_BASICO) {
        setTextoBasic(event.target.value);
        setLargoTextoBasico(event.target.value.length);
      }
    }
    if (tipoAnuncio.type === "full") {
      if (event.target.value.length <= MAX_LARGO_TEXTO_FULL) {
        setTextoFull(event.target.value);
        setLargoTextoFull(event.target.value.length);
      }
    }
    if (tipoAnuncio.type === "premium") {
      if (event.target.value.length <= MAX_LARGO_TEXTO_FULL) {
        setTextoPremium(event.target.value);
        setLargoTextoPremium(event.target.value.length);
      }
    }

    if (event.target.value.length > 0) setErrorAnuncio(false);
  };

  const handleTituloAnuncioChange = (event) => {
    if (event.target.value.length <= MAX_LARGO_ENC_PREMIUM) {
      setTituloPremium(event.target.value);
      setTituloAnuncio(event.target.value);
      setLargoEncPremium(event.target.value.length);
    }
  };

  const handlePieAnuncioChange = (event) => {
    if (event.target.value.length <= MAX_LARGO_PIE_PREMIUM) {
      setPiePremium(event.target.value);
      setPieAnuncio(event.target.value);
      setLargoPiePremium(event.target.value.length);
    }
  };

  const handleRadioadsStyle = (event) => {
    setTipoAnuncio(
      AdsStyles.filter((adsStyle) => adsStyle.type === event.target.value)[0]
    );
    if (event.target.value === "basico") {
      setValorBase(tariff.valorBasico);
      setValorAnuncio(tariff.valorBasico + valorDiario * numDiasNormal);

      setTextoAnuncio(textoBasic);
      setDisabledTextoBasic(false);
      setDisabledTextoFull(true);
      setDisabledTextoPremium(true);
      setDisabledTituloPremium(true);
      setDisabledPiePremium(true);
      handleOpenPromotion();
    }
    if (event.target.value === "full") {
      setValorBase(tariff.valorFull);
      setValorAnuncio(tariff.valorFull + valorDiario * numDiasNormal);

      setTextoAnuncio(textoFull);
      setDisabledTextoBasic(true);
      setDisabledTextoFull(false);
      setDisabledTextoPremium(true);
      setDisabledTituloPremium(true);
      setDisabledPiePremium(true);
    }
    if (event.target.value === "premium") {
      setValorBase(tariff.valorPremium);
      setValorAnuncio(tariff.valorPremium + valorDiario * numDiasNormal);

      setTextoAnuncio(textoPremium);
      setDisabledTextoBasic(true);
      setDisabledTextoFull(true);
      setDisabledTextoPremium(false);
      setDisabledTituloPremium(false);
      setDisabledPiePremium(false);
    }
  };

  const handleTextoBasicChange = (event) => {
    setTextoAnuncio(event.target.value);
    if (event.target.value.length <= MAX_LARGO_TEXTO_BASICO) {
      setTextoBasic(event.target.value);
      setLargoTextoBasico(event.target.value.length);
    }

    if (event.target.value.length > 0) setErrorAnuncio(false);
  };

  const handleBannerRadioadsStyle = (event) => {
    setTipoAnuncio(AdsStyles.filter((adsStyle) => adsStyle.type === event.target.value)[0]);
    setTextoAnuncio(textoBasic);
    setDisabledTextoBasic(false);

    if (event.target.value === "basico") {
      setValorBase(tariff.valorBasico);
      setValorAnuncio(tariff.valorBasico + valorDiario * numDiasNormal);
    }
    if (event.target.value === "full") {
      setValorBase(tariff.valorFull);
      setValorAnuncio(tariff.valorFull + valorDiario * numDiasNormal);
    }
    if (event.target.value === "premium") {
      setValorBase(tariff.valorPremium);
      setValorAnuncio(tariff.valorPremium + valorDiario * numDiasNormal);
    }
  };

  // validaciones informacion legal
  const [errorLegal, setErrorLegal] = React.useState(false);
  const [checkedLegal, setCheckedLegal] = React.useState(false);
  const handleChangeCheckedLegal = (event) => {
    setCheckedLegal(event.target.checked);
    if (event.target.checked) setErrorLegal(false);
  };

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = (event) => {
    if (activeStep === 0) {
    }

    if (activeStep === 1) {
      // informacion Personal
      if (nombre.length === 0) {
        setErrorNombre(true);
        return;
      }
      if (telefono.length === 0) {
        setErrorTelefono(true);
        return;
      } else {
        if (!isValidPhone(telefono)) {
          setErrorTelefono(true);
          return;
        }
      }

      if (email.length > 0) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
          setErrorEmail(true);
          return;
        }
      }

      let telefonoActual =
        telefono.length === 12 ? telefono.substring(3) : telefono;
      if (telefonoActual !== telefonoAnterior) {
        setTelefonoAnterior(telefonoActual);
        DataServiceForAds.countFreeAds(telefonoActual)
          .then((response) => {
            setContAvisosLibres(response.data.contador);
          })
          .catch((e) => {
            console.log(e);
            setContAvisosLibres(0);
          });
      }
    }

    if (activeStep === 2) {
      // informacion geografica
      if (region.length === 0) {
        setErrorRegion(true);
        return;
      }
      if (comuna.length === 0) {
        setErrorComuna(true);
        return;
      }
      if (usarGPS && !validDireccion) {
        setErrorDireccion(true);
        return;
      }
    }

    if (activeStep === 3) {
      // informacion de la categoria
      if (categoria.length === 0) {
        setErrorCategoria(true);
        return;
      }
      if (operacion.length === 0) {
        setErrorOperacion(true);
        return;
      }
      if (producto.length === 0) {
        setErrorProducto(true);
        return;
      }
    }

    if (activeStep === 4) {
      if (typeSelected === "texto") {
        // informacion del anuncio
        if (tipoAnuncio.type === "basico" && textoBasic.length === 0) {
          setErrorAnuncio(true);
          return;
        }
        if (tipoAnuncio.type === "full" && textoFull.length === 0) {
          setErrorAnuncio(true);
          return;
        }
        if (
          tipoAnuncio.type === "premium" &&
          (textoPremium.length === 0 || tituloPremium.length === 0)
        ) {
          setErrorAnuncio(true);
          return;
        }
      } else {
        //imageUrl && selectedImage
        if (!imageUrl || !selectedImage) {
          setErrorBanner(true);
          setErrorMsg("Debe cargar un banner");
          return;
        }
        setErrorBanner(false);

        // textoBasic
        if (textoBasic.length === 0) {
          setErrorAnuncio(true);
          setErrorMsg(
            "Debe ingresar una descripcion del producto/servicio ofrecido"
          );
          return;
        }
      }

      if (
        process.env.REACT_APP_CE_FREE_PUBLICATION &&
        tipoAnuncio.type === "basico"
      ) {
        if (contAvisosLibres + 1 > process.env.REACT_APP_MAX_FREE_ADS) {
          setOpenAlertFreeAds(true);
          return;
        } else {
          handleOpenPromotion();
        }
      }

      setErrorMsg("");
      setErrorAnuncio(false);
    }

    if (activeStep === 5) {
      // validacion de fecha
      if (endDate.isBefore(initialDate)) {
        setErrorEndDate("invalidDate");
        return;
      }
      setErrorEndDate("");
      setErrorInitialDate("");
      //handleOpenPromotion();
    }

    if (activeStep === 6) {
      // informacion legal
      if (!checkedLegal) {
        setErrorLegal(true);
        return;
      }
      saveAds(event);
    }

    if (activeStep === 7) {
      //saveAds(event);
      console("ir a algun lado");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (event) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = (event) => {
    setActiveStep(0);
    setNombre("");
    setEmail("");
    setRedesSociales([]);
    setTelefono("");
    setRegion("");
    setComuna("");
    setDireccion("");
    setCategoria("");
    setOperacion("");
    setProducto("");
    setEstadoProducto("");

    setInitialDate(dayjs().startOf("day"));
    setEndDate(dayjs().startOf("day").add( process.env.REACT_APP_MAX_PUBLISH_DAY, "day"));
    setDiasSemana({
      lunes: false,
      martes: false,
      miercoles: false,
      jueves: false,
      viernes: false,
      sabado: false,
      domingo: false,
    });

    setTipoAnuncio(AdsStyles[0]);
    setDisabledTextoBasic(false);
    setDisabledTextoFull(true);
    setDisabledTextoPremium(true);
    setDisabledTituloPremium(true);
    setDisabledPiePremium(true);

    setTextoBasic("");
    setTextoFull("");
    setTituloPremium("");
    setTextoPremium("");
    setPiePremium("");
    setTituloAnuncio("");
    setTextoAnuncio("");
    setPieAnuncio("");

    setSelectedImage(null);
    setImageUrl(null);

    setCheckedLegal(false);
  };

  const [locale, setLocale] = React.useState("es");
  const [timezone, setTimezone] = React.useState("America/Santiago");

  const [isSaving, setIsSaving] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
  const handleOpenErrorAlert = () => {
    setOpenErrorAlert(true);
  };
  const handleCloseErrorAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };

  const [openPromotion, setOpenPromotion] = React.useState(false);
  const handleOpenPromotion = () => {
    setOpenPromotion(true);
  };
  const handleClosePromotion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenPromotion(false);
  };

  const [imageBase64, setImageBase64] = React.useState("");
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [errorBanner, setErrorBanner] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  React.useEffect(() => {
    if (selectedImage) {
      const fileSizeKiloBytes = selectedImage.size / 1024;

      //console.log(selectedImage);

      if (fileSizeKiloBytes < process.env.REACT_APP_MIN_FILE_SIZE) {
        setErrorMsg(
          "El archivo es muy pequeno. Minimo requerido es " +
            process.env.REACT_APP_MIN_FILE_SIZE +
            " kb"
        );
        setErrorBanner(true);
        setIsSuccess(false);
        setImageUrl(null);
        return;
      }
      if (fileSizeKiloBytes > process.env.REACT_APP_MAX_FILE_SIZE) {
        setErrorMsg(
          "El archivo es muy grande. Maximo permitido es " +
            process.env.REACT_APP_MAX_FILE_SIZE +
            " kb"
        );
        setErrorBanner(true);
        setIsSuccess(false);
        setImageUrl(null);

        return;
      }
      if (!isValidFile(selectedImage)) {
        setErrorMsg(
          "El archivo tiene un formato no conocido, formatos validos son jpeg, jpg, png y gif"
        );
        setErrorBanner(true);
        setIsSuccess(false);
        setImageUrl(null);

        return;
      }

      setErrorMsg("");
      setErrorBanner(false);
      setImageUrl(URL.createObjectURL(selectedImage));

      const reader = new FileReader();
      reader.onload = (e) => {
        setImageBase64(e.target.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage]);

  const labelStyle = {
    fontSize: "12px", // Ajusta el tamaño de fuente según tus necesidades
  };

  return (
    <>
      <Box sx={{ height: 15 }}></Box>

      <Sidenav />

      <div>
        <Snackbar
          open={openPromotion}
          autoHideDuration={3000}
          onClose={handleClosePromotion}
        >
          <Alert
            onClose={handleClosePromotion}
            severity="info"
            sx={{ width: "100%" }}
          >
            Publicacion auncio standar con cobro cero, por tiempo limitado!
          </Alert>
        </Snackbar>

        <Snackbar
          open={openAlert}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity="success"
            sx={{ width: "100%" }}
          >
            Estamos casi listos!
          </Alert>
        </Snackbar>

        <Snackbar
          open={openErrorAlert}
          autoHideDuration={3000}
          onClose={handleCloseErrorAlert}
        >
          <Alert
            onClose={handleCloseErrorAlert}
            severity="error"
            sx={{ width: "100%" }}
          >
            Lo sentimos, no pudimos grabar su anuncio — Intenta mas tarde o
            contactate con nosotros!
          </Alert>
        </Snackbar>

        <Snackbar
          open={openErrorGPS}
          autoHideDuration={3000}
          onClose={handleCloseErrorGPS}
        >
          <Alert
            onClose={handleCloseErrorGPS}
            severity="error"
            sx={{ width: "100%" }}
          >
            Lo sentimos, no pudimos validar su direccion — Intenta mas tarde o
            contactate con nosotros!
          </Alert>
        </Snackbar>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isSaving}
        >
          <Box component="main" sx={{ display: "flex" }}>
            <center>
              <Typography paragraph variant="h3" gutterBottom>
                grabando anuncio
                <br></br>
                <CircularProgress color="inherit" />
              </Typography>
            </center>
          </Box>
        </Backdrop>
      </div>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <Box component="main" sx={{ display: "flex" }}>
            <center>
              <Typography paragraph variant="h3" gutterBottom>
                Espere un momento
                <br></br>
                <CircularProgress color="inherit" />
              </Typography>
            </center>
          </Box>
        </Backdrop>
      </div>

      <CameraCapture
        open={openCameraCapture}
        handleClose={handleCloseCameraCapture}
      />

      <ImageUpload
        open={openImageUpload}
        handleClose={handleCloseImageUpload}
      />

      <AlertAddressDialog
        msge={msgeAlertAddress}
        open={openAlertAddress}
        handleClose={handleCloseAlertAddress}
      />

      <AlertAdsDialog
        title={"Aviso"}
        message={
          "Lo sentimos, ha alcanzado el maximo numero de anuncios activos gratis"
        }
        open={openAlertFreeAds}
        handleClose={handleClickCloseAlerFreeAds}
      />

      <HelpSpeedDial pantalla={"publish"} />

      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Centrar horizontalmente
            maxWidth: "920px", // Establece el ancho máximo a 400px (ajusta según tus necesidades)
            margin: "0 auto", // Para centrar horizontalmente
            padding: "10px", // Añade algún relleno para que se vea mejor
          }}
        >
          <Typography variant="body1" gutterBottom>
            <strong>ClasificadosExpress.Cl</strong> es un lugar ideal para
            promocionar productos, servicios o anuncios clasificados de manera
            sencilla y rápida. Te ayudaremos a destacar tu anuncio
            proporcionando información clara y precisa. Sigue estos pasos para
            publicar.
          </Typography>
        </Box>

        {!isLoading ? (
          <Box
            component="main"
            sx={{
              display: "flex",
              justifyContent: "center", // Centrar horizontalmente
              maxWidth: "920px", // Establece el ancho máximo a 400px (ajusta según tus necesidades)
              margin: "0 auto", // Para centrar horizontalmente
              padding: "10px", // Añade algún relleno para que se vea mejor
            }}
          >
            <FormControl>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        index === 7 ? (
                          <Typography variant="caption">Ultimo paso</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>

                      {index === 0 ? (
                        <>
                          <Grid container>
                            <Grid xs={12}>
                              <Box sx={{ height: 5 }}></Box>
                            </Grid>

                            <Grid xs={12}>
                              <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                  title="Clasico"
                                  action={
                                    <IconButton
                                      aria-label="settings"
                                      onClick={handleTextoSelected}
                                    >
                                      {typeSelected === "texto" ? (
                                        <CheckCircleIcon
                                          color="success"
                                          fontSize="large"
                                        />
                                      ) : (
                                        <RemoveCircleIcon fontSize="large" />
                                      )}
                                    </IconButton>
                                  }
                                ></CardHeader>

                                <CardActionArea onClick={handleTextoSelected}>
                                  <CardMedia
                                    component="img"
                                    image="images/tipo_texto_xs.png"
                                    alt="texto"
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                </CardActionArea>
                              </Card>
                            </Grid>

                            <Grid xs={12}>
                              <Box sx={{ height: 15 }}></Box>
                            </Grid>

                            <Grid xs={12}>
                              <Card sx={{ maxWidth: 345 }}>
                                <CardHeader
                                  title="Banner"
                                  action={
                                    <IconButton
                                      aria-label="settings"
                                      onClick={handleBannerSelected}
                                    >
                                      {typeSelected === "banner" ? (
                                        <CheckCircleIcon
                                          color="success"
                                          fontSize="large"
                                        />
                                      ) : (
                                        <RemoveCircleIcon fontSize="large" />
                                      )}
                                    </IconButton>
                                  }
                                />

                                <CardActionArea onClick={handleBannerSelected}>
                                  <CardMedia
                                    component="img"
                                    image="images/tipo_imagen_xs.png"
                                    alt="texto"
                                    style={{ width: "100%" }}
                                  />
                                </CardActionArea>
                              </Card>
                            </Grid>

                            <Grid xs={12}>
                              <Box sx={{ height: 15 }}></Box>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}

                      {index === 1 ? ( //informacion personal
                        <>
                          <Grid container>
                            <Grid xs={12}>
                              <TextField
                                required
                                value={nombre}
                                id="nombre"
                                label="Nombre"
                                variant="filled"
                                sx={{ m: 1, width: "100%" }}
                                onChange={handleNombreChange}
                                helperText={
                                  errorNombre ? "Debe ingresar un nombre" : ""
                                }
                                error={errorNombre}
                              />
                            </Grid>
                            <Grid xs={12}>
                              <TextField
                                required
                                value={telefono}
                                id="telefono"
                                label="Telefono de Contacto"
                                variant="filled"
                                sx={{ m: 1, width: "100%" }}
                                onChange={handleTelefonoChange}
                                helperText={
                                  errorTelefono
                                    ? "Debe ingresar un numero de telefono valido"
                                    : ""
                                }
                                error={errorTelefono}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">+56</InputAdornment>
                                  ),
                                }}

                              />
                            </Grid>
                            <Grid xs={12}>
                              <TextField
                                value={email}
                                id="email"
                                label="Email (Opcional)"
                                variant="filled"
                                sx={{ m: 1, width: "100%" }}
                                onChange={handleEmailChange}
                                helperText={
                                  errorEmail
                                    ? "Debe ingresar un e-mail valido"
                                    : ""
                                }
                                error={errorEmail}
                              />
                            </Grid>
                            <Grid xs={12}>
                              <InputRRSS recibirRedesSociales={handleRRSS} />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}

                      {index === 2 ? ( // informacion geografica
                        <>
                          <Grid container>
                            <Grid xs={12}>
                              <FormControl
                                required
                                sx={{ m: 1, width: "100%" }}
                                error={errorRegion}
                              >
                                <InputLabel id="region-helper-label">
                                  Region
                                </InputLabel>
                                <Select
                                  labelId="region-helper-label"
                                  id="region"
                                  value={region}
                                  label="Region"
                                  displayEmpty
                                  onChange={handleRegionChange}
                                  variant="filled"
                                >
                                  {States.map((state) => (
                                    <MenuItem key={state.id} value={state.id}>
                                      {state.nombre}
                                    </MenuItem>
                                  ))}
                                </Select>

                                <FormHelperText>
                                  {errorRegion
                                    ? "Debe seleccionar una region"
                                    : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid xs={12}>
                              <FormControl
                                required
                                sx={{ m: 1, width: "100%" }}
                                error={errorComuna}
                              >
                                <InputLabel id="city-helper-label">
                                  Comuna
                                </InputLabel>
                                <Select
                                  labelId="city-helper-label"
                                  id="city"
                                  value={comuna}
                                  label="Comuna"
                                  onChange={handleComunaChange}
                                  displayEmpty
                                  variant="filled"
                                >
                                  {cities.map((city) => (
                                    <MenuItem key={city.id} value={city.nombre}>
                                      {city.nombre}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorComuna
                                    ? "Debe seleccionar una comuna"
                                    : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid xs={12}>
                              <TextField
                                value={direccion}
                                id="dreccion"
                                label="Direccion (Opcional)"
                                variant="filled"
                                sx={{ m: 1, width: "100%" }}
                                onChange={handleDireccionChange}
                                helperText={
                                  errorDireccion
                                    ? usarGPS
                                      ? "Debe ingresar una direccion valida"
                                      : "Debe ingresar una direccion"
                                    : ""
                                }
                                error={errorDireccion}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton></IconButton>
                                      {validDireccion ? (
                                        <LocationOnIcon color="success" />
                                      ) : (
                                        <LocationOffIcon color="action" />
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>

                            <Grid xs={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      disabled={disabledUsarGPS}
                                      checked={usarGPS}
                                      onChange={handleUsarGPS}
                                    />
                                  }
                                  label="Permite el uso de servicios google para determinar las coordenadas geograficas de la direccion ingresada?"
                                  labelPlacement="end"
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}

                      {index === 3 ? ( // informacion del producto o servicio
                        <>
                          <Grid container>
                            <Grid xs={12}>
                              <FormControl
                                sx={{ m: 1, width: "100%" }}
                                error={errorCategoria}
                              >
                                <InputLabel id="region-helper-label">
                                  Categoria
                                </InputLabel>
                                <Select
                                  labelId="category-helper-label"
                                  id="category"
                                  value={categoria}
                                  label="Categoria"
                                  displayEmpty
                                  onChange={handleCategoriaChange}
                                  variant="filled"
                                >
                                  {Categories.map((category) => (
                                    <MenuItem
                                      key={category.id}
                                      value={category.name}
                                    >
                                      {category.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorCategoria
                                    ? "Debe seleccionar una categoria"
                                    : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid xs={12}>
                              <FormControl
                                sx={{ m: 1, width: "100%" }}
                                error={errorProducto}
                              >
                                <InputLabel id="kindof-helper-label">
                                  Tipo
                                </InputLabel>
                                <Select
                                  labelId="kindof-helper-label"
                                  id="kindof"
                                  value={producto}
                                  label="Tipo"
                                  onChange={handleProductoChange}
                                  displayEmpty
                                  variant="filled"
                                >
                                  {kindsof.map((kindof) => (
                                    <MenuItem
                                      key={kindof.id}
                                      value={kindof.name}
                                    >
                                      {kindof.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorProducto
                                    ? "Debe seleccionar un producto o servicio"
                                    : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid xs={12}>
                              <FormControl
                                sx={{ m: 1, width: "100%" }}
                                error={errorOperacion}
                              >
                                <InputLabel id="agreement-helper-label">
                                  Operacion
                                </InputLabel>
                                <Select
                                  labelId="agreement-helper-label"
                                  id="agreement"
                                  value={operacion}
                                  label="Operacion"
                                  onChange={handleOperacionChange}
                                  displayEmpty
                                  variant="filled"
                                >
                                  {Agreements.map((agreement) => (
                                    <MenuItem
                                      key={agreement.id}
                                      value={agreement.name}
                                    >
                                      {agreement.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorOperacion
                                    ? "Debe seleccionar un tipo de operacion"
                                    : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid xs={12}>
                              <FormControl sx={{ m: 1, width: "100%" }}>
                                <FormLabel id="estado-group-label">
                                  Estado
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="estado-group-label"
                                  name="row-radio-buttons-group"
                                  onChange={handleRadioEstadoProducto}
                                  value={estadoProducto}
                                >
                                  <FormControlLabel
                                    value="Nuevo"
                                    control={<Radio />}
                                    label="Nuevo"
                                  />
                                  <FormControlLabel
                                    value="Usado"
                                    control={<Radio />}
                                    label="Usado"
                                  />
                                  <FormControlLabel
                                    value="NA"
                                    control={<Radio />}
                                    label="N/A"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid
                              xs={12}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Item
                                sx={{
                                  m: 1,
                                  width: "95%",
                                  border: "1px dashed grey",
                                  maxWidth: "400px",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={handleClickOpenCameraCapture}
                                  endIcon={<AddAPhotoIcon />}
                                >
                                  Capturar
                                </Button>
                                {"  "}
                                <Button
                                  variant="outlined"
                                  onClick={handleClickOpenImageUpload}
                                  endIcon={<AddPhotoAlternateIcon />}
                                >
                                  Subir
                                </Button>

                                <img
                                  src={imageData}
                                  srcSet={imageData}
                                  alt="imagen anuncio"
                                  loading="lazy"
                                  style={{ width: "100%" }}
                                />
                              </Item>
                            </Grid>
                          </Grid>
                          <br />
                        </>
                      ) : (
                        ""
                      )}

                      {index === 4 ? ( //informacion del anuncio
                        <>
                          {typeSelected === "banner" ? (
                            <Grid container>
                              <Grid xs={12}>
                                <Item
                                  sx={{
                                    width: "100%",
                                    border: "1px dashed grey",
                                  }}
                                >
                                  <FormControl
                                    variant="filled"
                                    required
                                    error={errorAnuncio}
                                  >
                                    <RadioGroup
                                      aria-labelledby="tipo-group-label"
                                      name="row-radio-buttons-group"
                                      onChange={handleBannerRadioadsStyle}
                                      value={tipoAnuncio.type}
                                    >
                                      <Stack
                                        spacing={0}
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="baseline"
                                        sx={{
                                          width: "100%",
                                          p: 1,
                                          border: "1px ",
                                        }}
                                        component="span"
                                      >
                                        <FormControlLabel
                                          value="basico"
                                          control={<Radio />}
                                          label={
                                            process.env
                                              .REACT_APP_CE_FREE_PUBLICATION ? (
                                              <>
                                                <span style={labelStyle}>
                                                  {"Standar ($0)"}
                                                </span>
                                              </>
                                            ) : (
                                              <span style={labelStyle}>
                                                {"Standard ($" +
                                                  tariff.valorBasico +
                                                  ")"}
                                              </span>
                                            )
                                          }
                                        />

                                        <FormControlLabel
                                          value="full"
                                          control={<Radio />}
                                          label={
                                            <span style={labelStyle}>
                                              {"Full ($" +
                                                tariff.valorFull +
                                                ")"}
                                            </span>
                                          }
                                        />

                                        <FormControlLabel
                                          value="premium"
                                          control={<Radio />}
                                          label={
                                            <span style={labelStyle}>
                                              {"Premium ($" +
                                                tariff.valorPremium +
                                                ")"}
                                            </span>
                                          }
                                        />
                                      </Stack>

                                      <Stack
                                        direction="column"
                                        spacing={0}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        sx={{
                                          maxWidth: "300px",
                                          minWidth: "285px",
                                          p: 1,
                                          border: "1px ",
                                        }}
                                      >
                                        {tipoAnuncio.type === "basico" ? (
                                          <Box
                                            sx={{
                                              height: 55,
                                              width: "97%",
                                              border: "1px solid #000",
                                            }}
                                          >
                                            {(!imageUrl || !selectedImage) && (
                                              <div>Suba un banner de 300px de ancho por 55px de alto</div>
                                            )}
                                            {imageUrl && selectedImage && (
                                              <img
                                                src={imageUrl}
                                                alt={selectedImage.name}
                                                width={"100%"}
                                                height={"100%"}
                                              />
                                            )}
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                        {tipoAnuncio.type === "full" ? (
                                          <Box
                                            sx={{
                                              height: 155,
                                              width: "97%",
                                              border: "1px solid #000",
                                            }}
                                          >
                                            {(!imageUrl || !selectedImage) && (
                                              <div>Suba un banner de 300px de ancho por 155px de alto</div>
                                            )}

                                            {imageUrl && selectedImage && (
                                              <img
                                                src={imageUrl}
                                                alt={selectedImage.name}
                                                width={"100%"}
                                                height={"100%"}
                                              />
                                            )}
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                        {tipoAnuncio.type === "premium" ? (
                                          <Box
                                            sx={{
                                              height: 205,
                                              width: "97%",
                                              border: "1px solid #000",
                                            }}
                                          >
                                            {(!imageUrl || !selectedImage) && (
                                              <div>Suba un banner de 300px de ancho por 205px de alto</div>
                                            )}
                                            {imageUrl && selectedImage && (
                                              <img
                                                src={imageUrl}
                                                alt={selectedImage.name}
                                                width={"100%"}
                                                height={"100%"}
                                              />
                                            )}
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                        <br />
                                        <Button
                                          component="label"
                                          variant="contained"
                                          startIcon={<CloudUploadIcon />}
                                        >
                                          Subir Banner
                                          <VisuallyHiddenInput
                                            accept="image/*"
                                            type="file"
                                            onChange={(e) =>
                                              setSelectedImage(
                                                e.target.files[0]
                                              )
                                            }
                                          />
                                        </Button>
                                      </Stack>

                                      <Stack
                                        spacing={0}
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="baseline"
                                        sx={{
                                          width: "100%",
                                          p: 1,
                                          border: "1px ",
                                        }}
                                      >
                                        <TextField
                                          id="filled-multiline-flexible"
                                          label={
                                            "Descripcion (" +
                                            largoTextoBasico +
                                            "/" +
                                            MAX_LARGO_TEXTO_BASICO +
                                            ")"
                                          }
                                          multiline
                                          rows={3}
                                          variant="filled"
                                          sx={{ width: "97%" }}
                                          onChange={handleTextoBasicChange}
                                          disabled={disabledTextoBasic}
                                          value={textoBasic}
                                          inputProps={{
                                            maxLength: MAX_LARGO_TEXTO_BASICO,
                                          }}
                                        />
                                      </Stack>
                                    </RadioGroup>
                                    <FormHelperText>{errorMsg}</FormHelperText>
                                  </FormControl>
                                </Item>
                              </Grid>
                              <Grid xs={12}>
                                <div
                                  style={{
                                    width: "285px",
                                  }}
                                ></div>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container>
                              <Grid xs={12}>
                                <Item
                                  sx={{
                                    width: "100%",
                                    border: "1px dashed grey",
                                  }}
                                >
                                  <FormControl
                                    variant="filled"
                                    required
                                    error={errorAnuncio}
                                  >
                                    <RadioGroup
                                      aria-labelledby="tipo-group-label"
                                      name="row-radio-buttons-group"
                                      onChange={handleRadioadsStyle}
                                      value={tipoAnuncio.type}
                                    >
                                      <Stack
                                        spacing={0}
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="baseline"
                                        sx={{
                                          width: "100%",
                                          p: 1,
                                          border: "1px ",
                                        }}
                                        component="span"
                                      >
                                        <FormControlLabel
                                          value="basico"
                                          control={<Radio />}
                                          label={
                                            process.env
                                              .REACT_APP_CE_FREE_PUBLICATION ? (
                                              <>
                                                <span style={labelStyle}>
                                                  {"Standar ($0)"}
                                                </span>
                                              </>
                                            ) : (
                                              <span style={labelStyle}>
                                                {"Standard ($" +
                                                  tariff.valorBasico +
                                                  ")"}
                                              </span>
                                            )
                                          }
                                        />

                                        <FormControlLabel
                                          value="full"
                                          control={<Radio />}
                                          label={
                                            <span style={labelStyle}>
                                              {"Full ($" +
                                                tariff.valorFull +
                                                ")"}
                                            </span>
                                          }
                                        />

                                        <FormControlLabel
                                          value="premium"
                                          control={<Radio />}
                                          label={
                                            <span style={labelStyle}>
                                              {"Premium ($" +
                                                tariff.valorPremium +
                                                ")"}
                                            </span>
                                          }
                                        />
                                      </Stack>

                                      <Stack
                                        direction="column"
                                        spacing={0}
                                        justifyContent="flex-start"
                                        alignItems="baseline"
                                        sx={{
                                          width: "100%",
                                          p: 1,
                                          border: "1px ",
                                        }}
                                      >
                                        {tipoAnuncio.type === "basico" ? (
                                          <TextField
                                            id="filled-multiline-flexible"
                                            label={
                                              "Aviso (" +
                                              largoTextoBasico +
                                              "/" +
                                              MAX_LARGO_TEXTO_BASICO +
                                              ")"
                                            }
                                            multiline
                                            maxRows={3}
                                            variant="filled"
                                            sx={{ width: "97%" }}
                                            onChange={handleTextoAnuncioChange}
                                            disabled={disabledTextoBasic}
                                            value={textoBasic}
                                            inputProps={{
                                              maxLength: MAX_LARGO_TEXTO_BASICO,
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}

                                        {tipoAnuncio.type === "full" ? (
                                          <TextField
                                            id="filled-textarea"
                                            label={
                                              "Aviso (" +
                                              largoTextoFull +
                                              "/" +
                                              MAX_LARGO_TEXTO_FULL +
                                              ")"
                                            }
                                            multiline
                                            variant="filled"
                                            sx={{ width: "97%" }}
                                            onChange={handleTextoAnuncioChange}
                                            disabled={disabledTextoFull}
                                            value={textoFull}
                                            inputProps={{
                                              maxLength: MAX_LARGO_TEXTO_FULL,
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}

                                        {tipoAnuncio.type === "premium" ? (
                                          <>
                                            <TextField
                                              id="filled-multiline-flexible"
                                              label={
                                                "Encabezado (" +
                                                largoEncPremium +
                                                "/" +
                                                MAX_LARGO_ENC_PREMIUM +
                                                ")"
                                              }
                                              maxRows={1}
                                              variant="filled"
                                              sx={{ width: "97%" }}
                                              onChange={
                                                handleTituloAnuncioChange
                                              }
                                              disabled={disabledTituloPremium}
                                              value={tituloPremium}
                                              inputProps={{
                                                maxLength:
                                                  MAX_LARGO_ENC_PREMIUM,
                                              }}
                                            />

                                            <TextField
                                              id="filled-multiline-static"
                                              label={
                                                "Anuncio (" +
                                                largoTextoPremium +
                                                "/" +
                                                MAX_LARGO_TEXTO_PREMIUM +
                                                ")"
                                              }
                                              multiline
                                              rows={3}
                                              variant="filled"
                                              sx={{ width: "97%" }}
                                              onChange={
                                                handleTextoAnuncioChange
                                              }
                                              disabled={disabledTextoPremium}
                                              value={textoPremium}
                                              inputProps={{
                                                maxLength:
                                                  MAX_LARGO_TEXTO_PREMIUM,
                                              }}
                                            />
                                            <TextField
                                              id="filled-multiline-flexible"
                                              label={
                                                "Pie (" +
                                                largoPiePremium +
                                                "/" +
                                                MAX_LARGO_PIE_PREMIUM +
                                                ")"
                                              }
                                              maxRows={1}
                                              variant="filled"
                                              sx={{ width: "97%" }}
                                              onChange={handlePieAnuncioChange}
                                              disabled={disabledPiePremium}
                                              value={piePremium}
                                              inputProps={{
                                                maxLength:
                                                  MAX_LARGO_PIE_PREMIUM,
                                              }}
                                            />
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        spacing={0}
                                        justifyContent="flex-start"
                                        alignItems="baseline"
                                        sx={{
                                          width: "100%",
                                          p: 1,
                                          border: "1px ",
                                        }}
                                      >
                                        <Stack sx={{ width: "100%" }}></Stack>
                                      </Stack>
                                    </RadioGroup>
                                  </FormControl>

                                  <FormHelperText>
                                    {errorAnuncio
                                      ? " ... Debe ingresar el texto del anuncio ... "
                                      : ""}
                                  </FormHelperText>
                                </Item>
                              </Grid>
                              <Grid xs={12}>
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid spacing={1}>
                                    <Grid
                                      xs
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Typography
                                        paragraph
                                        variant="h5"
                                        gutterBottom
                                      >
                                        Asi se ve!
                                      </Typography>{" "}
                                    </Grid>
                                    <Grid
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <PreviewAds
                                        tipoAnuncio={tipoAnuncio.type}
                                        texto={textoAnuncio + ". " + comuna}
                                        titulo={tituloPremium}
                                        pie={piePremium}
                                      />{" "}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {index === 5 ? ( // informacion del periodo
                        <>
                          <Box component="main" sx={{ display: "flex" }}>
                            <Grid container>
                              <Grid xs={7} md={8} minWidth="285px">
                                <Item
                                  sx={{
                                    m: 1,
                                    width: "100%",
                                    border: "1px dashed grey",
                                  }}
                                >
                                  <Typography
                                    paragraph
                                    variant="h6"
                                    gutterBottom
                                  >
                                    Periodo de publicacion
                                  </Typography>

                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale={locale}
                                  >
                                    <DemoContainer components={["DatePicker"]}>
                                      <Stack direction="row" spacing={2}>
                                        <DemoItem label="Inicio">
                                          <DatePicker
                                            value={initialDate}
                                            onChange={handleInitialDate}
                                            format="dddd DD/MM/YYYY"
                                            placeholderText="DD-MM-YYYY"
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            onError={(newError) =>
                                              setErrorInitialDate(newError)
                                            }
                                            slotProps={{
                                              textField: {
                                                helperText:
                                                  errorMessageInitialDate,
                                              },
                                            }}
                                            timezone={timezone}
                                          />
                                        </DemoItem>
                                        <DemoItem label="Fin">
                                          <DatePicker
                                            value={endDate}
                                            onChange={handleEndDate}
                                            format="dddd DD/MM/YYYY"
                                            placeholderText="DD-MM-YYYY"
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            onError={(newError) =>
                                              setErrorEndDate(newError)
                                            }
                                            slotProps={{
                                              textField: {
                                                helperText: errorMessageEndDate,
                                              },
                                            }}
                                            timezone={timezone}
                                          />
                                        </DemoItem>
                                      </Stack>
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Item>
                              </Grid>
                              <Grid xs={3} md={4} minWidth="280px">
                                <Item
                                  sx={{
                                    m: 1,
                                    width: "100%",
                                    border: "1px dashed grey",
                                  }}
                                >
                                  <Stack direction="column" spacing={1}>
                                    {process.env
                                      .REACT_APP_CE_FREE_PUBLICATION &&
                                    tipoAnuncio.type === "basico" ? (
                                      <>
                                        <Button variant="outlined">
                                          GRATIS POR TIEMPO LIMITADO
                                        </Button>
                                        <Typography
                                          paragraph
                                          variant="h4"
                                          gutterBottom
                                        >
                                          $0
                                        </Typography>
                                        <Typography
                                          paragraph
                                          variant="h9"
                                          gutterBottom
                                        >
                                          Dias normal: {numDiasNormal}, Dias
                                          promocion: {numDiasPromocion}
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        <Button variant="outlined">
                                          VALOR DEL ANUNCIO<br></br>${valorBase}{" "}
                                          Base + ${valorDiario} por dia
                                        </Button>

                                        <Typography
                                          paragraph
                                          variant="h4"
                                          gutterBottom
                                        >
                                          ${valorAnuncio}
                                        </Typography>
                                        <Typography
                                          paragraph
                                          variant="h9"
                                          gutterBottom
                                        >
                                          Dias normal: {numDiasNormal}, Dias
                                          promocion: {numDiasPromocion}
                                        </Typography>
                                      </>
                                    )}
                                  </Stack>
                                </Item>
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      ) : (
                        ""
                      )}

                      {index === 6 ? ( // informacion legal
                        <Grid container sx={{ m: 1, width: "95%" }}>
                          <Item>
                            <Typography paragraph variant="h7" gutterBottom>
                              Este anuncio es responsabilidad del anunciante. Al
                              publicar este anuncio, el anunciante confirma que
                              cumple con todas las leyes y regulaciones
                              aplicables. El anunciante se compromete a
                              proporcionar información precisa y veraz sobre el
                              producto o servicio ofrecido. El contenido del
                              anuncio debe ser legal, seguro y no violar los
                              derechos de terceros, incluyendo derechos de
                              propiedad intelectual. El anunciante acepta que el
                              sitio web/plataforma no se hace responsable de
                              cualquier reclamo, daño o consecuencia derivada
                              del contenido o la veracidad de este anuncio. El
                              sitio web/plataforma se reserva el derecho de
                              eliminar cualquier anuncio que no cumpla con las
                              políticas o que se considere inapropiado sin
                              previo aviso. Para obtener más información sobre
                              nuestras políticas y regulaciones, consulte
                              nuestros términos y condiciones.
                            </Typography>
                            <FormGroup>
                              <FormControlLabel
                                required
                                control={
                                  <Checkbox
                                    checked={checkedLegal}
                                    onChange={handleChangeCheckedLegal}
                                  />
                                }
                                label="Acepto las condiciones de uso"
                              />
                              {errorLegal && (
                                <Typography color="error" variant="body1">
                                  Debes aceptar las condiciones de uso antes de
                                  avanzar.
                                </Typography>
                              )}
                            </FormGroup>
                          </Item>
                        </Grid>
                      ) : (
                        ""
                      )}

                      {index === 7 ? ( // resumen pago
                        <Grid container spacing={2} sx={{ m: 1, width: "95%" }}>
                          <Item>
                            <PaymentSummary
                              promocion={true}
                              tipoPublicacion={"anuncio"}
                              tipoAnuncio={tipoAnuncio.type}
                              valorAnuncio={valorAnuncio}
                              valorBase={valorBase}
                              valorDiario={valorDiario}
                              numDiasNormal={numDiasNormal}
                              numDiasPromocion={numDiasPromocion}
                              textoAnuncio={textoAnuncio + ". " + comuna}
                              tituloAnuncio={tituloPremium}
                              pieAnuncio={piePremium}
                            />
                          </Item>
                        </Grid>
                      ) : (
                        ""
                      )}

                      <Box sx={{ mb: 2 }}>
                        <div>
                          {isPending ? (
                            <>
                              <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                {index === steps.length - 1
                                  ? "Pagar"
                                  : "Continuar"}
                              </Button>

                              <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                {index === steps.length - 1
                                  ? "Cancelar"
                                  : "Volver"}
                              </Button>
                            </>
                          ) : (
                            <>
                              <Stack
                                direction="row"
                                spacing={4}
                                justifyContent="center"
                                alignItems="center"
                              >
                                {process.env.REACT_APP_CE_FREE_PUBLICATION &&
                                tipoAnuncio.type === "basico" ? (
                                  <>
                                    <Button
                                      variant="contained"
                                      onClick={handleFreePublication}
                                    >
                                      Publicar
                                    </Button>
                                  </>
                                ) : (
                                  <PaymentButton
                                    url={url}
                                    token={token}
                                  ></PaymentButton>
                                )}

                                <Button
                                  variant="contained"
                                  onClick={handleCancelPublication}
                                >
                                  Cancelar
                                </Button>
                              </Stack>
                            </>
                          )}
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>
                    Todos los pasos han sido completados exitosamente, una vez
                    que el anuncio sea validado sera publicado en nuestro sitio
                    web.
                    <br></br>
                    ClasificadosExpress.cl agradece su confianza.
                  </Typography>
                  <Button
                    onClick={() => navigate("/")}
                    sx={{ mt: 1, mr: 1 }}
                    variant="contained"
                  >
                    Ir a ClasificadosExpress.cl
                  </Button>
                </Paper>
              )}
            </FormControl>
          </Box>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
