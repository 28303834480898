import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import RefreshIcon from "@mui/icons-material/Refresh";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { useThemeContext } from './utils/ThemeContext';


const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerBottom = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
}));

export default function Sidenavnpm(props) {
  const sourcePage = props.page;

  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { darkMode, toggleDarkMode } = useThemeContext();


  const [mode, setMode] = React.useState("light");
  const toggleColorMode = () => {
    setMode(mode === "light" ? "dark" : "light");
    toggleDarkMode();
  };


  const handleClickRefreshHome = () => {
    props.onRefreshHome();
  };


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, marginLeft: 'auto' }}>
            ClasificadosExpress
          </Typography>

          {process.env.REACT_APP_BAR_REFRESH ?
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleClickRefreshHome}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <RefreshIcon />
          </IconButton>
          :
          ""}

        </Toolbar>




      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            {
              page: "Clasificados",
              icon: "home",
              link: "/?banner=false",
              type: "link",
            },
            {
              page: "Publicar",
              icon: "post_add",
              link: "/Publish",
              type: "link",
            },

          ].map((option, index) => (
            <ListItem
              key={index}
              disablePadding
              onClick={() => {
                option.type === "function"
                  ? option.link()
                  : navigate(option.link);
                handleDrawerClose();
                }
              }
            >
              <ListItemButton>
                <ListItemIcon>
                  <Icon>{option.icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={option.page} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Box sx={{ height: "80%" }}></Box>

        <List>
            {[
              {
                page: mode === "light" ? "Modo Oscuro" : "Modo Claro",
                icon: mode === "light" ? "dark_mode" : "light_mode",
                link: toggleColorMode,
                type: "function",
              },
            ].map((option, index) => (
              <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  option.type === "function"
                    ? option.link()
                    : navigate(option.link);
                    handleDrawerClose();
                }
                }
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Icon>{option.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={option.page} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>


        <DrawerBottom>
        </DrawerBottom>
      </Drawer>
      <Main open={open}> </Main>
    </Box>
  );
}
