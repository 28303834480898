import * as React from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import Sidenav from "../Sidenav";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import DataServiceForAds from "../services/ads.service";
import service from "../services";
import AlertAddressDialog from "../others/alertAddress";
import PreviewAds from "../others/previewAds";
import CameraCapture from "../others/CameraCapture";
import OutlinedPaymentSummary from "../billing/paymentSummary";
import noDisponible from "../utils/nodisponible.jpg";

import {
  States,
  Cities,
  Categories,
  Agreements,
  KindsOf,
  AdsStyles,
} from "../services/dbAds";

const steps = [
  {
    label: "Informacion Personal",
    description: `Ingrese sus datos de contacto.`,
  },
  {
    label: "Informacion Geografica",
    description: `Ingrese la ubicacion del producto/servicio. La opcion de "usar ubicacion actual" permitira 
                  a sus clientes conocer la distancia a la cual se encuentra de su producto/servicio.`,
  },
  {
    label: "Informacion del Producto o Servicio",
    description: `Ingrese la categoria a la que pertenece su producto/servicio. Esto mejora la busqueda de su producto/servicio.`,
  },
  {
    label: "Informacion del Periodo de Publicacion",
    description: `Ingrese el rango de fechas en los que usted quiere publicar su anuncio. Si publicas la semana corrida, entonces sabado y
                  domingo son gratis!`,
  },
  {
    label: "Informacion a Publicar",
    description: `Ingrese un texto corto del producto/servicio a publicar.`,
  },

  {
    label: "Informacion Legal",
    description: ``,
  },
  {
    label: "Informacion del Pago",
    description: `Su anuncio sera publicado una vez que el pago haya sido realizado.`,
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Renew(props) {
  const navigate = useNavigate();

  const [cities, setCities] = React.useState([]);
  const [usarGPS, setUsarGPS] = React.useState(false);
  const [kindsof, setKindsOf] = React.useState([]);

  const [coordenadas, setCoordenadas] = React.useState({});

  const [textoBasic, setTextoBasic] = React.useState("");
  const [disabledTextoBasic, setDisabledTextoBasic] = React.useState(true);
  const [textoFull, setTextoFull] = React.useState("");
  const [disabledTextoFull, setDisabledTextoFull] = React.useState(true);
  const [tituloPremium, setTituloPremium] = React.useState("");
  const [disabledTituloPremium, setDisabledTituloPremium] =
    React.useState(true);

  const [piePremium, setPiePremium] = React.useState("");
  const [disabledPiePremium, setDisabledPiePremium] = React.useState(true);

  const [textoPremium, setTextoPremium] = React.useState("");
  const [disabledTextoPremium, setDisabledTextoPremium] = React.useState(true);

  const [errorAnuncio, setErrorAnuncio] = React.useState(false);
  const [tituloAnuncio, setTituloAnuncio] = React.useState("");
  const [pieAnuncio, setPieAnuncio] = React.useState("");

  const [textoAnuncio, setTextoAnuncio] = React.useState("");
  const [tipoAnuncio, setTipoAnuncio] = React.useState(AdsStyles[0]);

  const [initialDate, setInitialDate] = React.useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = React.useState(dayjs().startOf("day"));

  const [dueDate, setDueDate] = React.useState(dayjs().startOf("day"));

  const [isErrorPage, setIsErrorPage] = React.useState(false);
  const [isExpiredToken, setIsExpiredToken] = React.useState(false);

  const [isLoadingPage, setIsLoadingPage] = React.useState(true);
  const [tariff, setTariff] = React.useState(null);
  const [valorBase, setValorBase] = React.useState(0);
  const [valorDiario, setValorDiario] = React.useState(0);
  const [valorAnuncio, setValorAnuncio] = React.useState(0);
  const [numDiasNormal, setNumDiasNormal] = React.useState(0);
  const [numDiasPromocion, setNumDiasPromocion] = React.useState(0);

  // identificador del anuncio, recibido como parametro
  const [id, setId] = React.useState("");

  React.useEffect(() => {
    setIsLoadingPage(true);
    setIsErrorPage(false);
    setIsExpiredToken(false);

    DataServiceForAds.validateToken(props.token)
      .then((response) => {
        console.log("then");

        console.log("exito->" + response.status + ":" + response.statusText);
        if (response.status === 200) {
          setId(response.data.id);
        }
      })
      .catch((e) => {
        console.log("catch");
        setIsLoadingPage(false);
        setIsErrorPage(true);
        console.log(
          "error->" + e.response.status + ":" + e.response.statusText
        );
        if (e.response.status === 404) {
          // token expirado
          setIsExpiredToken(true);
        }
      })
      .finally(() => {
        console.log("finally");
      });
  }, [props.token]);

  React.useEffect(() => {
    if (id) {
      // carga tarifa
      service
        .getTariff({ adsDate: dayjs().format("YYYY-MM-DD") })
        .then((response) => {
          setTariff(response.tariff);
          setValorBase(response.tariff.valorBasico);
          setValorDiario(response.tariff.valorDia.lunes);
          setValorAnuncio(
            response.tariff.valorBasico + response.tariff.valorDia.lunes
          );
        });

      // carga anuncio
      service.getOne({ id: id }).then((response) => {
        setNombre(response.data.nombre);
        setEmail(response.data.email);
        setTelefono(response.data.telefono);

        setRegion(response.data.region);
        setCities(
          Cities.filter((city) => city.region === response.data.region)
        );
        setComuna(response.data.comuna);
        setDireccion(response.data.direccion);
        setValidDireccion(true);
        setUsarGPS(response.data.usarGPS);

        setCategoria(response.data.categoria);
        setKindsOf(
          KindsOf.filter(
            (kindOf) => kindOf.Category === response.data.categoria
          )
        );
        setOperacion(response.data.operacion);
        setProducto(response.data.producto);
        setEstadoProducto(response.data.estadoProducto);
        setImageData(response.data.imageData);

        setInitialDate(
          dayjs(response.data.fechaFin, { format: "YYYY-MM-DD HH:mm:ss" }).add(
            1,
            "day"
          )
        );
        setEndDate(
          dayjs(response.data.fechaFin, { format: "YYYY-MM-DD HH:mm:ss" }).add(
            1,
            "day"
          )
        );

        setDueDate(response.data.fechaFin);

        setNumDiasNormal(response.data.numDiasNormal);
        setNumDiasPromocion(response.data.numDiasPromocion);
        setValorAnuncio(response.data.valorAnuncio);
        setValorBase(response.data.valorBase);
        setValorDiario(response.data.valorDiario);

        setTipoAnuncio(
          AdsStyles.filter(
            (adsStyle) => adsStyle.type === response.data.tipoAnuncio
          )[0]
        );

        if (response.data.tipoAnuncio === "basico") {
          setTextoAnuncio(response.data.textoAnuncio);
          setTextoBasic(response.data.textoAnuncio);
          setLargoTextoBasico(response.data.textoAnuncio.length);
          setDisabledTextoBasic(false);
          setdisabledBasico(false);
        }
        if (response.data.tipoAnuncio === "full") {
          setTextoAnuncio(response.data.textoAnuncio);
          setTextoFull(response.data.textoAnuncio);
          setLargoTextoFull(response.data.textoAnuncio.length);
          setDisabledTextoFull(false);
          setdisabledFull(false);
        }
        if (response.data.tipoAnuncio === "premium") {
          setTextoAnuncio(response.data.textoAnuncio);
          setTituloAnuncio(response.data.tituloAnuncio);
          setPieAnuncio(response.data.pieAnuncio);

          setTextoPremium(response.data.textoAnuncio);
          setLargoTextoPremium(response.data.textoAnuncio.length);

          setPiePremium(response.data.pieAnuncio);
          setLargoPiePremium(response.data.pieAnuncio.length);
          setTituloPremium(response.data.tituloAnuncio);
          setLargoEncPremium(response.data.tituloAnuncio.length);

          setDisabledTextoPremium(false);
          setDisabledTituloPremium(false);
          setDisabledPiePremium(false);
          setdisabledPremium(false);
        }

        setCheckedLegal(response.data.aceptaCondiciones);
      });

      setIsLoadingPage(false);
      setIsErrorPage(false);
    }
  }, [id]);

  React.useEffect(() => {
    let diferenciaEnDias = endDate.diff(initialDate, "day");
    let numDiasPromocion = Math.floor((diferenciaEnDias + 1) / 7) * 2;
    let numDiasNormal = diferenciaEnDias + 1 - numDiasPromocion;

    setNumDiasPromocion(numDiasPromocion);
    setNumDiasNormal(numDiasNormal);
    setValorAnuncio(valorBase + valorDiario * numDiasNormal);
  }, [initialDate, endDate, valorBase, valorDiario]);

  const saveAds = (event) => {
    event.preventDefault();

    setIsSaving(true);

    var data = {
      nombre: nombre,
      email: email,
      telefono: telefono,

      region: region,
      comuna: comuna,
      direccion: direccion,
      coordenadas: coordenadas,
      usarGPS: usarGPS,

      categoria: categoria,
      operacion: operacion,
      producto: producto,
      estadoProducto: estadoProducto,
      imagen: flagImage ? imageData : null,

      fechaIni: initialDate,
      fechaFin: endDate,
      diasSemana: diasSemana,

      tipoAnuncio: tipoAnuncio.type,
      textoAnuncio: textoAnuncio,
      tituloAnuncio: tituloAnuncio,
      pieAnuncio: pieAnuncio,
      numDiasNormal: numDiasNormal,
      numDiasPromocion: numDiasPromocion,
      valorAnuncio: valorAnuncio,
      valorBase: valorBase,
      valorDiario: valorDiario,

      aceptaCondiciones: checkedLegal,
      estadoPago: "libre",
      modoPago: "libre",
      fechaPago: null,

      estadoAnuncio: {
        estado: "Nuevo",
        fecha: dayjs(),
        nota: "Ingreso Web",
        usuario: "web",
      },

      estadoRegistro: {
        estado: "Ingresado",
        fecha: dayjs(),
        nota: "Ingreso Web",
        usuario: "web",
      },

      anuncioRelacionado: id,
    };

    DataServiceForAds.updateStatus(id, {
      estado: "Renovado",
      fecha: dayjs(),
      nota: "Renovacion de anuncio",
      usuario: "web",
    })
      .then((response) => {
        console.log("response.data[" + response.data.message + "]");
      })
      .catch((e) => {
        setIsSaving(false);
        handleOpenErrorAlert();
        console.log(e);
        return;
      });

    DataServiceForAds.create(data)
      .then((response) => {
        console.log("response.data[" + response.data.message + "]");
        setIsSaving(false);
        handleOpenAlert();
      })
      .catch((e) => {
        setIsSaving(false);
        handleOpenErrorAlert();
        console.log(e);
      });
  };

  // validaciones informacion personal
  const [disabledNombre, setdisabledNombre] = React.useState(true);
  const [nombre, setNombre] = React.useState("");
  const [errorNombre, setErrorNombre] = React.useState(false);
  const handleNombreChange = (event) => {
    setNombre(event.target.value);
    if (event.target.value.length > 0) setErrorNombre(false);
  };

  const [disabledEmail, setdisabledEmail] = React.useState(true);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (event.target.value.length > 0) setErrorEmail(false);
  };

  const [disabledTelefono, setdisabledTelefono] = React.useState(true);
  const [errorTelefono, setErrorTelefono] = React.useState(false);
  const [telefono, setTelefono] = React.useState("");
  const handleTelefonoChange = (event) => {
    setTelefono(event.target.value);
    if (event.target.value.length > 0) setErrorTelefono(false);
  };

  // validaciones informacion geografica
  const [disabledRegion, setdisabledRegion] = React.useState(true);
  const [errorRegion, setErrorRegion] = React.useState(false);
  const [region, setRegion] = React.useState("");
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
    setComuna("");
    setDireccion("");
    setValidDireccion(false);
    setUsarGPS(false);
    setCities(Cities.filter((city) => city.region === event.target.value));
    if (event.target.value.length > 0) setErrorRegion(false);
  };

  const [disabledComuna, setdisabledComuna] = React.useState(true);
  const [errorComuna, setErrorComuna] = React.useState(false);
  const [comuna, setComuna] = React.useState("");
  const handleComunaChange = (event) => {
    setComuna(event.target.value);
    setDireccion("");
    setValidDireccion(false);
    setUsarGPS(false);
    if (event.target.value.length > 0) setErrorComuna(false);
  };

  const [disabledDireccion, setdisabledDireccion] = React.useState(true);
  const [disabledUsarGPS, setdisabledUsarGPS] = React.useState(true);
  const [errorGeocode, setErrorGeocode] = React.useState(true);
  const [validDireccion, setValidDireccion] = React.useState(false);
  const [errorDireccion, setErrorDireccion] = React.useState(false);
  const [direccion, setDireccion] = React.useState("");
  const handleDireccionChange = (event) => {
    setDireccion(event.target.value);
    setValidDireccion(false);
    setUsarGPS(false);
    if (event.target.value.length > 0) setErrorDireccion(false);
  };

  const handleUsarGPS = (event) => {
    setUsarGPS(event.target.checked);
    let googleAddres =
      direccion + "," + comuna + "," + process.env.REACT_APP_ALPHA_COUNTRY_CODE;
    if (!validDireccion) {
      service
        .getCoordinates({ address: googleAddres })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setErrorGeocode(false);
            if (response.location.location_type === "ROOFTOP") {
              setValidDireccion(true);
              setErrorDireccion(false);
              setCoordenadas(response.location);
              setUsarGPS(true);
            } else {
              console.log(
                "Se obtubo un resultado aproximado para la direccion"
              );
              setCoordenadas(response.location);
              handleClickOpenAlertAddress(
                "Se obtubo un resultado aproximado para la direccion -" +
                  response.location.formatted_address +
                  "-. Desea itentar nuevamente?"
              );
            }
          } else {
            console.log("No pude validar la direccion");
            setValidDireccion(false);
            setErrorDireccion(true);
            setUsarGPS(false);
            setErrorGeocode(true);

            handleClickOpenAlertAddress(
              "No pude validar la direccion, desea intentar nuevamente?"
            );
          }
        })
        .catch((error) => {
          setCoordenadas({});
          setValidDireccion(false);
          setErrorDireccion(true);
          setUsarGPS(false);
          setErrorGeocode(true);
          console.log(error.message);
        });
    }
  };

  const [openAlertAddress, setOpenAlertAddress] = React.useState(false);
  const [msgeAlertAddress, setMsgeAlertAddress] = React.useState("");
  const handleClickOpenAlertAddress = (msg) => {
    setMsgeAlertAddress(msg);
    setOpenAlertAddress(true);
  };
  const handleCloseAlertAddress = (opt) => {
    setOpenAlertAddress(false);
    console.log(opt);
    if (opt === "continue") {
      if (errorGeocode) {
        setUsarGPS(false);
      } else {
        setUsarGPS(true);
      }
      setValidDireccion(true);
      setErrorDireccion(false);
    } else {
      setValidDireccion(false);
      setErrorDireccion(true);
      setUsarGPS(false);
    }
  };

  // validaciones informacion categoria
  const [disabledCategoria, setdisabledCategoria] = React.useState(true);
  const [errorCategoria, setErrorCategoria] = React.useState(false);
  const [categoria, setCategoria] = React.useState("");
  const handleCategoriaChange = (event) => {
    setCategoria(event.target.value);
    if (event.target.value.length > 0) setErrorCategoria(false);
    setKindsOf(
      KindsOf.filter((kindOf) => kindOf.Category === event.target.value)
    );
  };

  const [disabledOperacion, setdisabledOperacion] = React.useState(true);
  const [errorOperacion, setErrorOperacion] = React.useState(false);
  const [operacion, setOperacion] = React.useState("");
  const handleOperacionChange = (event) => {
    setOperacion(event.target.value);
    if (event.target.value.length > 0) setErrorOperacion(false);
  };

  const [disabledProducto, setdisabledProducto] = React.useState(true);
  const [errorProducto, setErrorProducto] = React.useState(false);
  const [producto, setProducto] = React.useState("");
  const handleProductoChange = (event) => {
    setProducto(event.target.value);
    if (event.target.value.length > 0) setErrorProducto(false);
  };

  const [disabledEstadoProducto, setdisabledEstadoProducto] =
    React.useState(true);
  const [estadoProducto, setEstadoProducto] = React.useState("na");
  const handleRadioEstadoProducto = (event) => {
    setEstadoProducto(event.target.value);
  };

  const [flagImage, setFlagImage] = React.useState(false);
  const [imageData, setImageData] = React.useState("nodisponible.jpg");
  const [openCameraCapture, setOpenCameraCapture] = React.useState(false);
  const handleClickOpenCameraCapture = () => {
    setOpenCameraCapture(true);
  };
  const handleCloseCameraCapture = (imageData) => {
    if (imageData) {
      setFlagImage(true);
      setImageData(imageData);
    }
    setOpenCameraCapture(false);
  };

  // validaciones informacion del periodo
  // se considera fechas a partir de fin de publicacion del aviso
  const minDate = dayjs(initialDate);
  const maxDate = dayjs(initialDate).add(1, "month");

  const [errorInitialDate, setErrorInitialDate] = React.useState(null);
  const [errorEndDate, setErrorEndDate] = React.useState(null);

  const errorMessageInitialDate = React.useMemo(() => {
    //console.log(errorInitialDate);
    switch (errorInitialDate) {
      case "maxDate": {
        return "Por favor, ingrese un rango de fechas menor a un año";
      }
      case "minDate": {
        return "Por favor, ingrese una mecha mayor a la de hoy";
      }

      case "invalidDate": {
        return "La fecha ingresada no es valida";
      }

      default: {
        return "";
      }
    }
  }, [errorInitialDate]);

  const errorMessageEndDate = React.useMemo(() => {
    //console.log(errorEndDate);
    switch (errorEndDate) {
      case "maxDate": {
        return "Por favor, ingrese un rango de fechas menor a un año";
      }
      case "minDate": {
        return "Por favor, ingrese una fecha mayor a la fecha inicial de publicacion";
      }

      case "invalidDate": {
        return "La fecha ingresada no es valida";
      }

      default: {
        return "";
      }
    }
  }, [errorEndDate]);

  const handleInitialDate = (date) => {
    if (date.isAfter(minDate) || date.isSame(minDate, "day")) {
      setInitialDate(date);
      setErrorInitialDate("");
    } else {
      if (date.isBefore(minDate)) {
        console.log("fecha inicial no puede ser menor al dia de hoy");
        setErrorInitialDate("invalidDate");
      } else {
        console.log("fecha inicial es invalida");
        setErrorInitialDate("invalidDate");
      }
    }
  };

  const handleEndDate = (date) => {
    if (date.isAfter(initialDate) || date.isSame(initialDate, "day")) {
      setEndDate(date);
      setErrorEndDate("");
    } else {
      if (date.isBefore(initialDate)) {
        setErrorEndDate("minDate");
      } else {
        console.log("fecha final es invalida");
        setErrorEndDate("invalidDate");
      }
    }
  };

  const [diasSemana, setDiasSemana] = React.useState({
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false,
  });

  // validaciones informacion a publicar
  const [disabledBasico, setdisabledBasico] = React.useState(true);
  const [disabledFull, setdisabledFull] = React.useState(true);
  const [disabledPremium, setdisabledPremium] = React.useState(true);

  const MAX_LARGO_TEXTO_BASICO = 140;
  const MAX_LARGO_TEXTO_FULL = 120;
  const MAX_LARGO_TEXTO_PREMIUM = 100;
  const MAX_LARGO_ENC_PREMIUM = 15;
  const MAX_LARGO_PIE_PREMIUM = 20;

  const [largoTextoBasico, setLargoTextoBasico] = React.useState(0);
  const [largoTextoFull, setLargoTextoFull] = React.useState(0);
  const [largoTextoPremium, setLargoTextoPremium] = React.useState(0);
  const [largoEncPremium, setLargoEncPremium] = React.useState(0);
  const [largoPiePremium, setLargoPiePremium] = React.useState(0);

  const handleTextoAnuncioChange = (event) => {
    setTextoAnuncio(event.target.value);
    if (tipoAnuncio.type === "basico") {
      if (event.target.value.length <= MAX_LARGO_TEXTO_BASICO) {
        setTextoBasic(event.target.value);
        setLargoTextoBasico(event.target.value.length);
      }
    }
    if (tipoAnuncio.type === "full") {
      if (event.target.value.length <= MAX_LARGO_TEXTO_FULL) {
        setTextoFull(event.target.value);
        setLargoTextoFull(event.target.value.length);
      }
    }
    if (tipoAnuncio.type === "premium") {
      if (event.target.value.length <= MAX_LARGO_TEXTO_FULL) {
        setTextoPremium(event.target.value);
        setLargoTextoPremium(event.target.value.length);
      }
    }

    if (event.target.value.length > 0) setErrorAnuncio(false);
  };

  const handleTituloAnuncioChange = (event) => {
    if (event.target.value.length <= MAX_LARGO_ENC_PREMIUM) {
      setTituloPremium(event.target.value);
      setTituloAnuncio(event.target.value);
      setLargoEncPremium(event.target.value.length);
    }
  };

  const handlePieAnuncioChange = (event) => {
    if (event.target.value.length <= MAX_LARGO_PIE_PREMIUM) {
      setPiePremium(event.target.value);
      setPieAnuncio(event.target.value);
      setLargoPiePremium(event.target.value.length);
    }
  };

  const handleRadioadsStyle = (event) => {
    setTipoAnuncio(
      AdsStyles.filter((adsStyle) => adsStyle.type === event.target.value)[0]
    );
    if (event.target.value === "basico") {
      setValorBase(tariff.valorBasico);
      setValorAnuncio(tariff.valorBasico + valorDiario * numDiasNormal);

      setTextoAnuncio(textoBasic);
      setDisabledTextoBasic(false);
      setDisabledTextoFull(true);
      setDisabledTextoPremium(true);
      setDisabledTituloPremium(true);
      setDisabledPiePremium(true);
    }
    if (event.target.value === "full") {
      setValorBase(tariff.valorFull);
      setValorAnuncio(tariff.valorFull + valorDiario * numDiasNormal);

      setTextoAnuncio(textoFull);
      setDisabledTextoBasic(true);
      setDisabledTextoFull(false);
      setDisabledTextoPremium(true);
      setDisabledTituloPremium(true);
      setDisabledPiePremium(true);
    }
    if (event.target.value === "premium") {
      setValorBase(tariff.valorPremium);
      setValorAnuncio(tariff.valorPremium + valorDiario * numDiasNormal);

      setTextoAnuncio(textoPremium);
      setDisabledTextoBasic(true);
      setDisabledTextoFull(true);
      setDisabledTextoPremium(false);
      setDisabledTituloPremium(false);
      setDisabledPiePremium(false);
    }
  };

  // validaciones informacion legal
  const [disabledLegal, setdisabledLegal] = React.useState(true);
  const [errorLegal, setErrorLegal] = React.useState(false);
  const [checkedLegal, setCheckedLegal] = React.useState(false);
  const handleChangeCheckedLegal = (event) => {
    setCheckedLegal(event.target.checked);
    if (event.target.checked) setErrorLegal(false);
  };

  const [activeStep, setActiveStep] = React.useState(3); // pasa directo a modificar el periodo

  const handleNext = (event) => {
    if (activeStep === 0) {
      // informacion Personal
      if (nombre.length === 0) {
        setErrorNombre(true);
        return;
      }
      if (email.length === 0) {
        setErrorEmail(true);
        return;
      } else {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
          setErrorEmail(true);
          return;
        }
      }
      if (telefono.length === 0) {
        setErrorTelefono(true);
        return;
      }
    }
    if (activeStep === 1) {
      // informacion geografica
      if (region.length === 0) {
        setErrorRegion(true);
        return;
      }
      if (comuna.length === 0) {
        setErrorComuna(true);
        return;
      }
      if (direccion.length === 0) {
        setErrorDireccion(true);
        return;
      }
      if (usarGPS && !validDireccion) {
        setErrorDireccion(true);
        return;
      }
    }

    if (activeStep === 2) {
      // informacion de la categoria
      if (categoria.length === 0) {
        setErrorCategoria(true);
        return;
      }
      if (operacion.length === 0) {
        setErrorOperacion(true);
        return;
      }
      if (producto.length === 0) {
        setErrorProducto(true);
        return;
      }
    }

    if (activeStep === 3) {
      // validacion de fecha
      if (endDate.isBefore(initialDate)) {
        setErrorEndDate("invalidDate");
        return;
      }
      setErrorEndDate("");
      setErrorInitialDate("");
    }

    if (activeStep === 4) {
      // informacion del anuncio
      if (tipoAnuncio.type === "basico" && textoBasic.length === 0) {
        setErrorAnuncio(true);
        return;
      }
      if (tipoAnuncio.type === "full" && textoFull.length === 0) {
        setErrorAnuncio(true);
        return;
      }
      if (
        tipoAnuncio.type === "premium" &&
        (textoPremium.length === 0 || tituloPremium.length === 0)
      ) {
        setErrorAnuncio(true);
        return;
      }
    }

    if (activeStep === 5) {
      // informacion legal
      if (!checkedLegal) {
        setErrorLegal(true);
        return;
      }
    }

    if (activeStep === 6) {
      saveAds(event);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (event) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [locale, setLocale] = React.useState("es");

  const [isSaving, setIsSaving] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
  const handleOpenErrorAlert = () => {
    setOpenErrorAlert(true);
  };
  const handleCloseErrorAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };

  return (
    <>
      {1 === 0 ? (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingPage}
          >
            <Box component="main" sx={{ display: "flex" }}>
              <center>
                <Typography paragraph variant="h3" gutterBottom>
                  recuperando datos anuncio
                  <br></br>
                  <CircularProgress color="inherit" />
                </Typography>
              </center>
            </Box>
          </Backdrop>
        </div>
      ) : (
        ""
      )}

      <Box sx={{ height: 15 }}></Box>

      <Sidenav />

      <div>
        <Snackbar
          open={openAlert}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity="success"
            sx={{ width: "100%" }}
          >
            Anuncio ingresado — Pronto lo publicaremos!
          </Alert>
        </Snackbar>

        <Snackbar
          open={openErrorAlert}
          autoHideDuration={5000}
          onClose={handleCloseErrorAlert}
        >
          <Alert
            onClose={handleCloseErrorAlert}
            severity="error"
            sx={{ width: "100%" }}
          >
            Lo sentimos, no pudimos renovar su anuncio — Intente mas tarde o
            contactese con nosotros!
          </Alert>
        </Snackbar>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isSaving}
        >
          <Box component="main" sx={{ display: "flex" }}>
            <center>
              <Typography paragraph variant="h3" gutterBottom>
                guardando anuncio
                <br></br>
                <CircularProgress color="inherit" />
              </Typography>
            </center>
          </Box>
        </Backdrop>
      </div>

      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoadingPage}
        >
          <Box component="main" sx={{ display: "flex" }}>
            <center>
              <Typography paragraph variant="h3" gutterBottom>
                recuperando datos anuncio
                <br></br>
                <CircularProgress color="inherit" />
              </Typography>
            </center>
          </Box>
        </Backdrop>
      </div>

      <CameraCapture
        open={openCameraCapture}
        handleClose={handleCloseCameraCapture}
      />

      <AlertAddressDialog
        msge={msgeAlertAddress}
        open={openAlertAddress}
        handleClose={handleCloseAlertAddress}
      />

      {!isLoadingPage && !isErrorPage ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Centrar horizontalmente
              maxWidth: "920px", // Establece el ancho máximo a 400px (ajusta según tus necesidades)
              margin: "0 auto", // Para centrar horizontalmente
              padding: "16px", // Añade algún relleno para que se vea mejor
            }}
          >
            <Typography variant="body1" gutterBottom>
              <strong>ClasificadosExpress.Cl</strong> es un lugar ideal para
              promocionar productos, servicios o anuncios clasificados de manera
              sencilla y rápida. Sigue estos pasos para <strong>renovar</strong>{" "}
              la publicacion de tu anuncio que termina el{" "}
              <strong>{dayjs(dueDate).format("DD-MM-YYYY")}</strong>.
            </Typography>
          </Box>

          <Box
            component="main"
            sx={{
              display: "flex",
              justifyContent: "center", // Centrar horizontalmente
              maxWidth: "920px", // Establece el ancho máximo
              margin: "0 auto", // Para centrar horizontalmente
              padding: "16px", // Añade algún relleno para que se vea mejor
            }}
          >
            <FormControl>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        index === 6 ? (
                          <Typography variant="caption">Ultimo paso</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      {index === 0 ? ( //informacion personal
                        <>
                          <Grid
                            container
                            spacing={2}
                            sx={{ m: 1, width: "95%" }}
                          >
                            <Grid xs={12}>
                              <TextField
                                disabled={disabledNombre}
                                required
                                value={nombre}
                                id="nombre"
                                label="Nombre"
                                variant="filled"
                                sx={{ m: 1, width: "95%" }}
                                onChange={handleNombreChange}
                                helperText={
                                  errorNombre ? "Debe ingresar un nombre" : ""
                                }
                                error={errorNombre}
                              />
                            </Grid>
                            <Grid xs={12}>
                              <TextField
                                disabled={disabledEmail}
                                required
                                value={email}
                                id="email"
                                label="Email"
                                variant="filled"
                                sx={{ m: 1, width: "95%" }}
                                onChange={handleEmailChange}
                                helperText={
                                  errorEmail
                                    ? "Debe ingresar un e-mail valido"
                                    : ""
                                }
                                error={errorEmail}
                              />
                            </Grid>
                            <Grid xs={12}>
                              <TextField
                                disabled={disabledTelefono}
                                required
                                value={telefono}
                                id="telefono"
                                label="Telefono de Contacto"
                                variant="filled"
                                sx={{ m: 1, width: "95%" }}
                                onChange={handleTelefonoChange}
                                helperText={
                                  errorTelefono
                                    ? "Debe ingresar un telefono valido"
                                    : ""
                                }
                                error={errorTelefono}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      {index === 1 ? ( // informacion geografica
                        <>
                          <Grid container spacing={2}>
                            <Grid xs={12}>
                              <FormControl
                                disabled={disabledRegion}
                                required
                                sx={{ m: 1, width: "95%" }}
                                error={errorRegion}
                              >
                                <InputLabel id="region-helper-label">
                                  Region
                                </InputLabel>
                                <Select
                                  labelId="region-helper-label"
                                  id="region"
                                  value={region}
                                  label="Region"
                                  displayEmpty
                                  onChange={handleRegionChange}
                                  variant="filled"
                                  sx={{ m: 1, width: "95%" }}
                                >
                                  {States.map((state) => (
                                    <MenuItem key={state.id} value={state.id}>
                                      {state.nombre}
                                    </MenuItem>
                                  ))}
                                </Select>

                                <FormHelperText>
                                  {errorRegion
                                    ? "Debe seleccionar una region"
                                    : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid xs={12}>
                              <FormControl
                                disabled={disabledComuna}
                                required
                                sx={{ m: 1, width: "95%" }}
                                error={errorComuna}
                              >
                                <InputLabel id="city-helper-label">
                                  Comuna
                                </InputLabel>
                                <Select
                                  labelId="city-helper-label"
                                  id="city"
                                  value={comuna}
                                  label="Comuna"
                                  onChange={handleComunaChange}
                                  displayEmpty
                                  variant="filled"
                                  sx={{ m: 1, width: "95%" }}
                                >
                                  {cities.map((city) => (
                                    <MenuItem key={city.id} value={city.nombre}>
                                      {city.nombre}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorComuna
                                    ? "Debe seleccionar una comuna"
                                    : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid xs={12} sx={{ m: 1, width: "95%" }}>
                              <TextField
                                disabled={disabledDireccion}
                                required
                                value={direccion}
                                id="dreccion"
                                label="Direccion"
                                variant="filled"
                                sx={{ m: 1, width: "95%" }}
                                onChange={handleDireccionChange}
                                helperText={
                                  errorDireccion
                                    ? usarGPS
                                      ? "Debe ingresar una direccion valida"
                                      : "Debe ingresar una direccion"
                                    : ""
                                }
                                error={errorDireccion}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton></IconButton>
                                      {validDireccion ? (
                                        <LocationOnIcon color="success" />
                                      ) : (
                                        <LocationOffIcon color="action" />
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>

                            <Grid xs={12} sx={{ m: 1, width: "95%" }}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      disabled={disabledUsarGPS}
                                      checked={usarGPS}
                                      onChange={handleUsarGPS}
                                    />
                                  }
                                  label="Permite el uso de servicios google para determinar las coordenadas geograficas de la direccion ingresada?"
                                  labelPlacement="end"
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}

                      {index === 2 ? ( // informacion del producto o servicio
                        <>
                          <Grid
                            container
                            spacing={2}
                            sx={{ m: 1, width: "95%" }}
                          >
                            <Grid xs={12}>
                              <FormControl
                                sx={{ m: 1, width: "95%" }}
                                error={errorCategoria}
                              >
                                <InputLabel id="region-helper-label">
                                  Categoria
                                </InputLabel>
                                <Select
                                  disabled={disabledCategoria}
                                  labelId="category-helper-label"
                                  id="category"
                                  value={categoria}
                                  label="Categoria"
                                  displayEmpty
                                  onChange={handleCategoriaChange}
                                  variant="filled"
                                >
                                  {Categories.map((category) => (
                                    <MenuItem
                                      key={category.id}
                                      value={category.name}
                                    >
                                      {category.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorCategoria
                                    ? "Debe seleccionar una categoria"
                                    : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid xs={12}>
                              <FormControl
                                sx={{ m: 1, width: "95%" }}
                                error={errorOperacion}
                              >
                                <InputLabel id="agreement-helper-label">
                                  Operacion
                                </InputLabel>
                                <Select
                                  disabled={disabledOperacion}
                                  labelId="agreement-helper-label"
                                  id="agreement"
                                  value={operacion}
                                  label="Operacion"
                                  onChange={handleOperacionChange}
                                  displayEmpty
                                  variant="filled"
                                >
                                  {Agreements.map((agreement) => (
                                    <MenuItem
                                      key={agreement.id}
                                      value={agreement.name}
                                    >
                                      {agreement.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorOperacion
                                    ? "Debe seleccionar un tipo de operacion"
                                    : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid xs={12}>
                              <FormControl
                                sx={{ m: 1, width: "95%" }}
                                error={errorProducto}
                              >
                                <InputLabel id="kindof-helper-label">
                                  Tipo
                                </InputLabel>
                                <Select
                                  disabled={disabledProducto}
                                  labelId="kindof-helper-label"
                                  id="kindof"
                                  value={producto}
                                  label="Tipo"
                                  onChange={handleProductoChange}
                                  displayEmpty
                                  variant="filled"
                                >
                                  {kindsof.map((kindof) => (
                                    <MenuItem
                                      key={kindof.id}
                                      value={kindof.name}
                                    >
                                      {kindof.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errorProducto
                                    ? "Debe seleccionar un producto o servicio"
                                    : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid xs={12}>
                              <FormControl
                                disabled={disabledEstadoProducto}
                                sx={{ m: 1, width: "95%" }}
                              >
                                <FormLabel id="estado-group-label">
                                  Estado
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="estado-group-label"
                                  name="row-radio-buttons-group"
                                  onChange={handleRadioEstadoProducto}
                                  value={estadoProducto}
                                >
                                  <FormControlLabel
                                    value="Nuevo"
                                    control={<Radio />}
                                    label="Nuevo"
                                  />
                                  <FormControlLabel
                                    value="Usado"
                                    control={<Radio />}
                                    label="Usado"
                                  />
                                  <FormControlLabel
                                    value="NA"
                                    control={<Radio />}
                                    label="N/A"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid
                              xs
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Item
                                sx={{
                                  m: 1,
                                  width: "95%",
                                  border: "1px dashed grey",
                                  maxWidth: "400px",
                                }}
                              >
                                {imageData ? (
                                  <img
                                    src={imageData}
                                    srcSet={imageData}
                                    alt="imagen anuncio"
                                    loading="lazy"
                                    style={{ width: "100%" }}
                                  />
                                ) : (
                                  <img
                                    src={noDisponible}
                                    srcSet={noDisponible}
                                    alt="imagen no disponible"
                                    loading="lazy"
                                    style={{ width: "100%" }}
                                  />
                                )}
                              </Item>
                            </Grid>
                          </Grid>
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {index === 3 ? ( // informacion del periodo
                        <>
                          <Box component="main" sx={{ display: "flex" }}>
                            <Grid container spacing={1}>
                              <Grid xs={7} md={8} minWidth="300px">
                                <Item
                                  sx={{
                                    m: 1,
                                    width: "95%",
                                    border: "1px dashed grey",
                                  }}
                                >
                                  <Typography
                                    paragraph
                                    variant="h6"
                                    gutterBottom
                                  >
                                    Fecha de publicacion
                                  </Typography>

                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale={locale}
                                  >
                                    <DemoContainer
                                      components={[
                                        "DatePicker",
                                        "DatePicker",
                                        "DateRangePicker",
                                      ]}
                                    >
                                      <Stack direction="row" spacing={2}>
                                        <DemoItem label="Inicio">
                                          <DatePicker
                                            value={initialDate}
                                            onChange={handleInitialDate}
                                            format="dddd DD/MM/YYYY"
                                            placeholderText="DD-MM-YYYY"
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            onError={(newError) =>
                                              setErrorInitialDate(newError)
                                            }
                                            slotProps={{
                                              textField: {
                                                helperText:
                                                  errorMessageInitialDate,
                                              },
                                            }}
                                          />
                                        </DemoItem>
                                        <DemoItem label="Fin">
                                          <DatePicker
                                            value={endDate}
                                            onChange={handleEndDate}
                                            format="dddd DD/MM/YYYY"
                                            placeholderText="DD-MM-YYYY"
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            onError={(newError) =>
                                              setErrorEndDate(newError)
                                            }
                                            slotProps={{
                                              textField: {
                                                helperText: errorMessageEndDate,
                                              },
                                            }}
                                          />
                                        </DemoItem>
                                      </Stack>
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Item>
                              </Grid>
                              <Grid xs={3} md={4} minWidth="250px">
                                <Item
                                  sx={{
                                    m: 1,
                                    width: "95%",
                                    border: "1px dashed grey",
                                  }}
                                >
                                  <Stack direction="column" spacing={1}>
                                    <Button variant="outlined">
                                      VALOR DEL ANUNCIO<br></br>${valorBase}{" "}
                                      Base + ${valorDiario} por dia
                                    </Button>
                                    <Typography
                                      paragraph
                                      variant="h4"
                                      gutterBottom
                                    >
                                      ${valorAnuncio}
                                    </Typography>
                                    <Typography
                                      paragraph
                                      variant="h9"
                                      gutterBottom
                                    >
                                      Dias normal: {numDiasNormal}, Dias
                                      promocion: {numDiasPromocion}
                                    </Typography>
                                  </Stack>
                                </Item>
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      ) : (
                        ""
                      )}
                      {index === 4 ? ( //informacion del anuncio
                        <>
                          <Grid
                            container
                            spacing={2}
                            sx={{ m: 1, width: "95%" }}
                          >
                            <Grid xs={12}>
                              <Item
                                sx={{
                                  m: 1,
                                  border: "1px dashed grey",
                                }}
                              >
                                <Typography paragraph variant="h6" gutterBottom>
                                  Tipo de publicacion
                                </Typography>
                                <FormControl
                                  variant="filled"
                                  required
                                  error={errorAnuncio}
                                >
                                  <RadioGroup
                                    aria-labelledby="tipo-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={handleRadioadsStyle}
                                    value={tipoAnuncio.type}
                                  >
                                    {!disabledBasico ? (
                                      <Stack
                                        spacing={0}
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="baseline"
                                        sx={{
                                          width: "100%",
                                          p: 1,
                                          border: "1px ",
                                        }}
                                      >
                                        <FormControlLabel
                                          value="basico"
                                          control={
                                            <Radio disabled={disabledBasico} />
                                          }
                                          label={
                                            "Basico ($" +
                                            tariff.valorBasico +
                                            ")"
                                          }
                                        />
                                        <TextField
                                          id="filled-multiline-flexible"
                                          label={
                                            "Aviso (" +
                                            largoTextoBasico +
                                            "/" +
                                            MAX_LARGO_TEXTO_BASICO +
                                            ")"
                                          }
                                          multiline
                                          maxRows={3}
                                          variant="filled"
                                          sx={{ width: "100%" }}
                                          onChange={handleTextoAnuncioChange}
                                          disabled={disabledTextoBasic}
                                          value={textoBasic}
                                          inputProps={{
                                            maxLength: MAX_LARGO_TEXTO_BASICO,
                                          }}
                                        />
                                      </Stack>
                                    ) : (
                                      ""
                                    )}

                                    {!disabledFull ? (
                                      <Stack
                                        direction="row"
                                        spacing={0}
                                        justifyContent="flex-start"
                                        alignItems="baseline"
                                        sx={{
                                          width: "100%",
                                          p: 1,
                                          border: "1px ",
                                        }}
                                      >
                                        <FormControlLabel
                                          value="full"
                                          control={
                                            <Radio disabled={disabledFull} />
                                          }
                                          label={
                                            "Full ($" + tariff.valorFull + ")"
                                          }
                                        />
                                        <TextField
                                          id="filled-textarea"
                                          label={
                                            "Aviso (" +
                                            largoTextoFull +
                                            "/" +
                                            MAX_LARGO_TEXTO_FULL +
                                            ")"
                                          }
                                          multiline
                                          variant="filled"
                                          sx={{ width: "100%" }}
                                          onChange={handleTextoAnuncioChange}
                                          disabled={disabledTextoFull}
                                          value={textoFull}
                                          inputProps={{
                                            maxLength: MAX_LARGO_TEXTO_FULL,
                                          }}
                                        />
                                      </Stack>
                                    ) : (
                                      ""
                                    )}

                                    {!disabledPremium ? (
                                      <Stack
                                        direction="row"
                                        spacing={0}
                                        justifyContent="flex-start"
                                        alignItems="baseline"
                                        sx={{
                                          width: "100%",
                                          p: 1,
                                          border: "1px ",
                                        }}
                                      >
                                        <FormControlLabel
                                          value="premium"
                                          control={
                                            <Radio disabled={disabledPremium} />
                                          }
                                          label={
                                            "Premium ($" +
                                            tariff.valorPremium +
                                            ")"
                                          }
                                        />
                                        <Stack sx={{ width: "100%" }}>
                                          <TextField
                                            id="filled-multiline-flexible"
                                            label={
                                              "Encabezado (" +
                                              largoEncPremium +
                                              "/" +
                                              MAX_LARGO_ENC_PREMIUM +
                                              ")"
                                            }
                                            maxRows={1}
                                            variant="filled"
                                            sx={{ width: "100%" }}
                                            onChange={handleTituloAnuncioChange}
                                            disabled={disabledTituloPremium}
                                            value={tituloPremium}
                                            inputProps={{
                                              maxLength: MAX_LARGO_ENC_PREMIUM,
                                            }}
                                          />

                                          <TextField
                                            id="filled-multiline-static"
                                            label={
                                              "Anuncio (" +
                                              largoTextoPremium +
                                              "/" +
                                              MAX_LARGO_TEXTO_PREMIUM +
                                              ")"
                                            }
                                            multiline
                                            rows={3}
                                            variant="filled"
                                            sx={{ width: "100%" }}
                                            onChange={handleTextoAnuncioChange}
                                            disabled={disabledTextoPremium}
                                            value={textoPremium}
                                            inputProps={{
                                              maxLength:
                                                MAX_LARGO_TEXTO_PREMIUM,
                                            }}
                                          />
                                          <TextField
                                            id="filled-multiline-flexible"
                                            label={
                                              "Pie (" +
                                              largoPiePremium +
                                              "/" +
                                              MAX_LARGO_PIE_PREMIUM +
                                              ")"
                                            }
                                            maxRows={1}
                                            variant="filled"
                                            sx={{ width: "100%" }}
                                            onChange={handlePieAnuncioChange}
                                            disabled={disabledPiePremium}
                                            value={piePremium}
                                            inputProps={{
                                              maxLength: MAX_LARGO_PIE_PREMIUM,
                                            }}
                                          />
                                        </Stack>
                                      </Stack>
                                    ) : (
                                      ""
                                    )}
                                  </RadioGroup>
                                </FormControl>
                                <FormHelperText>
                                  {errorAnuncio
                                    ? " ... Debe ingresar el texto del anuncio ... "
                                    : ""}
                                </FormHelperText>
                              </Item>
                            </Grid>
                            <Grid xs={12} sx={{ m: 1, width: "95%" }}>
                              <Item>
                                <Typography paragraph variant="h5" gutterBottom>
                                  Asi se ve!
                                </Typography>

                                <PreviewAds
                                  tipoAnuncio={tipoAnuncio}
                                  texto={textoAnuncio + ". " + comuna}
                                  titulo={tituloPremium}
                                  pie={piePremium}
                                />
                              </Item>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}

                      {index === 5 ? (
                        <Grid container spacing={2} sx={{ m: 1, width: "95%" }}>
                          <Item>
                            <Typography paragraph variant="h7" gutterBottom>
                              Este anuncio es responsabilidad del anunciante. Al
                              publicar este anuncio, el anunciante confirma que
                              cumple con todas las leyes y regulaciones
                              aplicables. El anunciante se compromete a
                              proporcionar información precisa y veraz sobre el
                              producto o servicio ofrecido. El contenido del
                              anuncio debe ser legal, seguro y no violar los
                              derechos de terceros, incluyendo derechos de
                              propiedad intelectual. El anunciante acepta que el
                              sitio web/plataforma no se hace responsable de
                              cualquier reclamo, daño o consecuencia derivada
                              del contenido o la veracidad de este anuncio. El
                              sitio web/plataforma se reserva el derecho de
                              eliminar cualquier anuncio que no cumpla con las
                              políticas o que se considere inapropiado sin
                              previo aviso. Para obtener más información sobre
                              nuestras políticas y regulaciones, consulte
                              nuestros términos y condiciones.
                            </Typography>
                            <FormGroup>
                              <FormControlLabel
                                required
                                control={
                                  <Checkbox
                                    disabled={disabledLegal}
                                    checked={checkedLegal}
                                    onChange={handleChangeCheckedLegal}
                                  />
                                }
                                label="Acepto las condiciones de uso"
                              />
                              {errorLegal && (
                                <Typography color="error" variant="body1">
                                  Debes aceptar las condiciones de uso antes de
                                  avanzar.
                                </Typography>
                              )}
                            </FormGroup>
                          </Item>
                        </Grid>
                      ) : (
                        ""
                      )}

                      {index === 6 ? (
                        <Grid container spacing={2} sx={{ m: 1, width: "95%" }}>
                          <Item>
                            <OutlinedPaymentSummary
                              promocion={false}
                              tipoAnuncio={tipoAnuncio.type}
                              valorAnuncio={valorAnuncio}
                              valorBase={valorBase}
                              valorDiario={valorDiario}
                              numDiasNormal={numDiasNormal}
                              numDiasPromocion={numDiasPromocion}
                            />
                          </Item>
                        </Grid>
                      ) : (
                        ""
                      )}

                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {index === steps.length - 1
                              ? "Renovar"
                              : "Continuar"}
                          </Button>
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Volver
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>
                    Todos los pasos han sido completados exitosamente, una vez
                    que el anuncio sea validado sera publicado en nuestro sitio
                    web.
                    <br></br>
                    ClasificadosExpress.cl agradece su confianza.
                  </Typography>
                  <Button
                    onClick={() => navigate("/")}
                    sx={{ mt: 1, mr: 1 }}
                    variant="contained"
                  >
                    Ir a ClasificadosExpress.cl
                  </Button>
                </Paper>
              )}
            </FormControl>
          </Box>
        </>
      ) : (
        ""
      )}

      {!isLoadingPage && isErrorPage ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Centrar horizontalmente
              maxWidth: "920px", // Establece el ancho máximo a 400px (ajusta según tus necesidades)
              margin: "0 auto", // Para centrar horizontalmente
              padding: "16px", // Añade algún relleno para que se vea mejor
            }}
          >
            {isExpiredToken ? (
              <Typography variant="body1" gutterBottom>
                El token ha expirado, ya no puede renovar la publicacion.
                <strong>ClasificadosExpress.cl</strong> agradece su confianza.
              </Typography>
            ) : (
              <Typography variant="body1" gutterBottom>
                Ha ocurrido un error al intentar renovar el anuncio comuniquese
                con <strong>info@clasificadosexpress.cl</strong> y envienos el
                link recibido por usted indicandole que su anuncio esta proximo
                a vencer. <strong>ClasificadosExpress.cl</strong> agradece su
                confianza.
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Centrar horizontalmente
              maxWidth: "920px", // Establece el ancho máximo a 400px (ajusta según tus necesidades)
              margin: "0 auto", // Para centrar horizontalmente
              padding: "16px", // Añade algún relleno para que se vea mejor
            }}
          >
            {isExpiredToken ? (
              <Button
                onClick={() => navigate("/Publish")}
                sx={{ mt: 1, mr: 1 }}
                variant="contained"
              >
                Ir a Publicar
              </Button>
            ) : (
              <Button
                onClick={() => navigate("/")}
                sx={{ mt: 1, mr: 1 }}
                variant="contained"
              >
                Ir a ClasificadosExpress.cl
              </Button>
            )}
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
}
