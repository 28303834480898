import React from "react";

import { AdsStyles } from "../services/dbAds";
import "../dashboard/Ads.css";

const PreviewAds = (props) => {
  const [adsStyle, setAdsStyle] = React.useState(
    AdsStyles.filter((adsStyle) => adsStyle.type === props.tipoAnuncio)[0]
  );
  const [textOfAds, setTextOfAds] = React.useState(props.texto);
  const [titleOfAds, setTitleOfAds] = React.useState(props.titulo);
  const [footOfAds, setFootOfAds] = React.useState(props.pie);

  React.useEffect(() => {
    setAdsStyle(
      AdsStyles.filter((adsStyle) => adsStyle.type === props.tipoAnuncio)[0]
    );
    setTextOfAds(props.texto);
    setTitleOfAds(props.titulo);
    setFootOfAds(props.pie);
  }, [props.tipoAnuncio, props.texto, props.titulo, props.pie]);

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        height: adsStyle.height,
        width: "285px",
        lineHeight: "1.1",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "black",
          fontSize: "medium",
          textAlign: "left",
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
          width: "100%",
        }}
      >
        {adsStyle.type === "basico" ? (
          <div className="BasicAds">{textOfAds}</div>
        ) : (
          ""
        )}

        {adsStyle.type === "full" ? (
          <div className="FullAds">{textOfAds}</div>
        ) : (
          ""
        )}

        {adsStyle.type === "premium" ? (
          <div className="PremiumAds">
            <p
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              {titleOfAds}
            </p>
            <p style={{ marginTop: "5px", marginBottom: "5px" }}>{textOfAds}</p>
            <p
              style={{
                fontSize: "x-large",
                fontWeight: "bold",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              {footOfAds}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PreviewAds;
