import React from "react";
import Button from "@mui/material/Button";

function PaymentButton(props) {

  return (
      <form action={props.url} method="POST">
        <input type="hidden" name="token_ws" value={props.token} />
        <Button type="submit" variant="contained" color="primary" >
          Pagar
        </Button>
      </form>

  );
}

export default PaymentButton;
