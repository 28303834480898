import * as React from "react";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";

import Sidenav from "../Sidenav";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";

/*
  exito
  http://mingw64_nt-10.0-22621:3000/Commit?token_ws=01ab924cc62fee0e17ce729745cecea0573d9723935c88b0909c4cf20fecb7e6

  cancelacion
  http://mingw64_nt-10.0-22621:3000/Commit?TBK_TOKEN=01ab3b7ab8d1a954d4d0f1656f73d47fa7e11b5258494434558a22388e603df7&TBK_ORDEN_COMPRA=O-20&TBK_ID_SESION=S-6529e2c96712d42504d7b4fa
*/

import DataServiceForAds from "../services/ads.service";
import { functionFormatter } from "../utils/CurrencyFormatter";

import { Stack } from "@mui/material";

const Commit = () => {
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const token_ws = query.get("token_ws");
  const tbk_token = query.get("TBK_TOKEN");
  const tbk_orden_compra = query.get("TBK_ORDEN_COMPRA");
  const tbk_id_session = query.get("TBK_ID_SESION");


  const [isErrorPage, setIsErrorPage] = React.useState(false);
  const [isCancelPage, setIsCancelPage] = React.useState(false);

  const [order, setOrder] = React.useState("");
  const [id, setId] = React.useState("");
  const [estadoPago, setEstadoPago] = React.useState("FAIL");

  const [vci, setVci] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [status, setStatus] = React.useState("");
  const [buyOrder, setBuyOrder] = React.useState("");
  const [cardNumber, setCardNumber] = React.useState("");
  const [accountingDate, setAccountingDate] = React.useState("");
  const [transactionDate, setTransactionDate] = React.useState("");
  const [authorizationCode, setAuthorizationCode] = React.useState("");
  const [paymentTypeCode, setPaymentTypeCode] = React.useState("");
  const [responseCode, setResponseCode] = React.useState(undefined);
  const [textResume, setTextResume] = React.useState("");

  React.useEffect(() => {
    if (tbk_token || tbk_id_session) {
      console.log("la transaccion ha sido abortada");
      setIsCancelPage(true);
      // cambiar estado de transaccion a Cancelado
      var dataStatus = {};
      dataStatus = {estado: "Cancelado",
      fecha: dayjs(),
      nota: "transaccion cancelada por usuario",
      usuario: "web" };
      
      DataServiceForAds.updateStatus(tbk_id_session.substring(2), dataStatus)
      .then((response) => {
        //console.log("response.data[" + response + "]");
      })
      .catch((e) => {
        console.log(e.message);
        setIsErrorPage(true);
        // grave grave .. se cancelo el pago, pero el aviso sigue pendiente
        return;
      });      
      
    }
  
  }, [])


  React.useEffect(() => {

    if (token_ws) {
      console.log("la transaccion ha sido confirmada");
      setIsErrorPage(false);
  
      var dataStatus = {};
      var dataTbk = {
        token_ws: token_ws,
      };
      DataServiceForAds.commitTransactionWebPayPlus(dataTbk)
        .then((response) => {
          if (response.status === 200) {
  
            //"buy_order": "O-15",
            //"session_id": "S-6526bf60e0a4e59961ef2e03",
        
            setOrder(parseInt( response.data.buy_order.substring(2) ) );
            setId(response.data.session_id.substring(2));
            setVci(response.data.vci);
            setAmount(response.data.amount);
            setStatus(response.data.status);
            setBuyOrder(response.data.buy_order);
            setCardNumber(response.data.card_detail.card_number);
            setAccountingDate(response.data.accounting_date);
            setAuthorizationCode(response.data.authorization_code);
            setTransactionDate(response.data.transaction_date);
            setPaymentTypeCode(response.data.payment_type_code);
  
            if (response.data.response_code === 0) {
              setEstadoPago("SUCCESS");
              dataStatus = {estado: "Pagado",
                                fecha: dayjs(),
                                nota: "pagado con " + response.data.payment_type_code + "  usando webpay plus",
                                usuario: "web" }
            }
            else {
                setEstadoPago("FAIL");
                dataStatus = {estado: "Fail",
                                  fecha: dayjs(),
                                  nota: "pagado con " + response.data.payment_type_code + "  usando webpay plus",
                                  usuario: "web" }
  
            }
    
            DataServiceForAds.updateStatus(response.data.session_id.substring(2), dataStatus)
                .then((response) => {
                  //console.log("el anuncio ha sido actualizado");
                })
                .catch((e) => {
                  console.log(e.message);
                  setIsErrorPage(true);
                  // grave grave .. se confirmo el pago, pero el aviso sigue pendiente
                  return;
                });
  
            DataServiceForAds.findOne(response.data.session_id.substring(2))
            .then((response) => {
              //console.log("anuncio encontrado");
              //console.log(response);

              //setAds(response.data);
              setTextResume(response.data.tipoPublicacion + " " + response.data.tipoAnuncio + " por " +  (response.data.numDiasNormal+ response.data.numDiasPromocion) + " dias" );

             })
            .catch((e) => {
              console.log(e.message);
              setIsErrorPage(true);
              // grave grave .. no encontre aviso
              return;
            });

          }
          else {
            // error en la plataforma de pago o api
            console.log("error en la plataforma de pago o api");
            setIsErrorPage(true);
  
          }
          
        })
        .catch((e) => {
          setEstadoPago("FAIL");
          setIsErrorPage(true);
          console.log(e.message);
  
        })
        .finally(() => {
          ;
        });
  
    }

    }, []);
  /*
{
    "vci": "TSY",
    "amount": 3100,
    "status": "AUTHORIZED",
    "buy_order": "O-6526bf60e0a4e59961ef2e03",
    "session_id": "S-6526bf60e0a4e59961ef2e03",
    "card_detail": {
        "card_number": "6623"
    },
    "accounting_date": "1011",
    "transaction_date": "2023-10-11T15:29:36.211Z",
    "authorization_code": "1213",
    "payment_type_code": "VN",
    "response_code": 0,
    "installments_number": 0
}
*/

  const handleGotoHome = (event) => {
    event.preventDefault();
    if (estadoPago === "SUCCESS") navigate("/?banner=false");
    else navigate("/");
  };

  return (
    <>
      <Box sx={{ height: 15 }}></Box>

      <Sidenav />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Centrar horizontalmente
          maxWidth: "920px", // Establece el ancho máximo a 400px (ajusta según tus necesidades)
          margin: "0 auto", // Para centrar horizontalmente
          padding: "16px", // Añade algún relleno para que se vea mejor
        }}
      >

        {isCancelPage?
        <Stack>
        <Typography variant="h5" gutterBottom>
          La transaccion ha sido cancelada por el usuario. Esperamos verlo de nuevo por aca.
        </Typography>
          <Button variant="contained" onClick={handleGotoHome}>
          Ver Anuncios
        </Button>
        </Stack>

        :
        ""}

        {isErrorPage?
        <>
        <Stack>
        <Typography variant="h5" gutterBottom>
          La plataforma de pagos esta presentando problemas, por favor intente mas tarde.
        </Typography>
          <Button variant="contained" onClick={handleGotoHome}>
          Ver Anuncios
        </Button>
        </Stack>
        </>
        :
        ""
        }

        {!isErrorPage && !isCancelPage?
       <>
        <Stack>
          <Typography variant="h5" gutterBottom>
            {estadoPago === "SUCCESS"
              ? "PAGO EXITOSO"
              : "Lo sentimos pero tuvimos un problema con su pago"}
          </Typography>

          <Paper
            sx={{
              margin: "0 auto", // Para centrar horizontalmente
              padding: "16px", // Añade algún relleno para que se vea mejor
              textAlign: 'center',
            }}
          >
            <TextField
              value={order}
              id="buy_orden"
              label="Nro de Orden"
              variant="filled"
              aria-disabled
            />

            <TextField
              value={"$ " + functionFormatter(amount)}
              id="amount"
              label="Monto"
              variant="filled"
              aria-disabled
            />

            <TextField
              value={dayjs(transactionDate).format("YYYY-MM-DD HH:mm:ss")}
              id="transactionDate"
              label="Fecha Transacion"
              variant="filled"
              aria-disabled
            />
            <TextField
              value={authorizationCode}
              id="authorizationCode"
              label="Codigo Autorizacion"
              variant="filled"
              aria-disabled
            />
            <TextField
              value={paymentTypeCode}
              id="paymentTypeCode"
              label="Medio de Pago"
              variant="filled"
              aria-disabled
            />
            <TextField
              value={cardNumber}
              id="cardNumber"
              label="Numero Tarjeta"
              variant="filled"
              aria-disabled
            />
            <TextField
              value={textResume}
              id="producto"
              label="Producto"
              variant="filled"
              aria-disabled
            />
          </Paper>
          <Typography variant="body" gutterBottom>
            {estadoPago === "SUCCESS"
              ? "GRACIAS POR CONFIAR EN NOSOTROS"
              : "INTENTE MAS TARDE POR FAVOR"}
          </Typography>

          <Button variant="contained" onClick={handleGotoHome}>
            Ver Anuncios
          </Button>
        </Stack>
       
       
       </>
        :
        ""
          }


      </Box>
    </>
  );
};

export default Commit;
