
const validFileTypes = ['jpg', 'jpeg', 'png', 'gif']; // Tipos de archivo válidos

function isValidFile(file) {
    if (file) {
      const fileType = file.name.split('.').pop(); // Obtiene la extensión del archivo
      return validFileTypes.includes(fileType.toLowerCase());
    }
    return false;
  }
  
  function dv(T) {
    var M=0,S=1;
    for(;T;T=Math.floor(T/10))
      S=(S+T%10*(9-M++%6))%11;
    return S?(S-1)+"":'k';    
  }

  function isValidRUT(rutCompleto) {

    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto )) return false;
      var tmp 	= rutCompleto.split('-');
      var digv	= tmp[1]; 
      var rut 	= tmp[0];
      if ( digv === 'K' ) digv = 'k' ;

      return dv(rut) === digv;
  
  }
  
function isValidPhone(numTelefono) {

  if (/^(\+56)?[1-9]\d{8}$/.test( numTelefono )) return true;

  return false;

}

export {isValidFile, isValidRUT, isValidPhone}