import * as React from "react";
import { useLocation } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";

import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Sidenav from "../Sidenav";
import { isValidFile } from "../utils/utils";

import googleGeocodeService from "../services/google.service";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { Divider, Stack } from "@mui/material";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Banner from "../others/banner";

dayjs.extend(utc);
dayjs.extend(timezone);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
const ligthTheme = {
  palette: {
    mode: "light",
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
  },
};

const darkTheme = {
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
    },
    secondary: {
      main: "#ce93d8",
    },
    background: {
      default: "#121212",
      paper: "#121212",
    },
  },
};

function MyApp() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.default",
        color: "text.primary",
        borderRadius: 1,
        p: 3,
      }}
    >
      {theme.palette.mode} mode
      <IconButton
        sx={{ ml: 1 }}
        onClick={colorMode.toggleColorMode}
        color="inherit"
      >
        {theme.palette.mode === "dark" ? (
          <Brightness7Icon />
        ) : (
          <Brightness4Icon />
        )}
      </IconButton>
    </Box>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  backgroundImage: "welcome1.png",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ToggleColorMode() {
  const query = new URLSearchParams(useLocation().search);
  const banner = query.get("banner")
    ? query.get("banner") === "false"
      ? false
      : true
    : false;

  const [locale, setLocale] = React.useState("es");
  const [timezone, setTimezone] = React.useState("America/Santiago");

  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () => createTheme(mode === "light" ? ligthTheme : darkTheme),
    [mode]
  );

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openBanner, setOpenBanner] = React.useState(false);
  const handleClickOpenBanner = () => {
    setOpenBanner(true);
  };
  const handleCloseBanner = () => {
    setOpenBanner(false);
  };

  const [initialDate, setInitialDate] = React.useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = React.useState(
    dayjs().startOf("day").add(1, "day")
  );
  const handleEndDate = (date) => {
    if (date.isAfter(initialDate)) {
      setEndDate(date);
      setErrorEndDate("");
    } else {
      if (date.isBefore(initialDate) || date.isSame(initialDate, "day")) {
        setErrorEndDate("minDate");
      } else {
        setErrorEndDate("invalidDate");
      }
    }
  };

  // validaciones informacion del periodo
  const minDate = dayjs();
  const maxDate = dayjs().add(1, "month");
  const [errorInitialDate, setErrorInitialDate] = React.useState(null);
  const [errorEndDate, setErrorEndDate] = React.useState(null);

  const errorMessageInitialDate = React.useMemo(() => {
    switch (errorInitialDate) {
      case "maxDate": {
        return "Por favor, ingrese un rango de fechas menor a un año";
      }
      case "minDate": {
        return "Por favor, ingrese una mecha mayor a la de hoy";
      }

      case "invalidDate": {
        return "La fecha ingresada no es valida";
      }

      default: {
        return "";
      }
    }
  }, [errorInitialDate]);

  const errorMessageEndDate = React.useMemo(() => {
    switch (errorEndDate) {
      case "maxDate": {
        return "Por favor, ingrese un rango de fechas menor a un año";
      }
      case "minDate": {
        return "Por favor, ingrese una fecha mayor a la fecha inicial de publicacion";
      }

      case "invalidDate": {
        return "La fecha ingresada no es valida";
      }

      default: {
        return "";
      }
    }
  }, [errorEndDate]);

  const handleInitialDate = (date) => {
    if (date.isAfter(minDate) || date.isSame(minDate, "day")) {
      setInitialDate(date);
      setErrorInitialDate("");
    } else {
      if (date.isBefore(minDate)) {
        setErrorInitialDate("invalidDate");
      } else {
        setErrorInitialDate("invalidDate");
      }
    }
  };

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [base64String, setBase64String] = React.useState("");
  const [base64Image, setBase64Image] = React.useState(null);

  React.useEffect(() => {
    if (selectedImage) {
      const fileSizeKiloBytes = selectedImage.size / 1024;

      console.log(selectedImage);

      if (fileSizeKiloBytes < process.env.REACT_APP_MIN_FILE_SIZE) {
        setErrorMsg(
          "El archivo es muy pequeno. Minimo requerido es " +
            process.env.REACT_APP_MIN_FILE_SIZE +
            " kb"
        );
        setIsSuccess(false);
        return;
      }
      if (fileSizeKiloBytes > process.env.REACT_APP_MAX_FILE_SIZE) {
        setErrorMsg(
          "El archivo es muy grande. Maximo permitido es " +
            process.env.REACT_APP_MAX_FILE_SIZE +
            " kb"
        );
        setIsSuccess(false);
        return;
      }
      if (!isValidFile(selectedImage)) {
        setErrorMsg(
          "El tiene un formato no conocido, formatos validos son jpeg, jpg, png y gif"
        );
        setIsSuccess(false);
        return;
      }

      setImageUrl(URL.createObjectURL(selectedImage));

      const reader = new FileReader();
      reader.onload = (e) => {
        setBase64String(e.target.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage]);

  const [address, setAddress] = React.useState(
    "Calle Serrano, 22, Madrid, España"
  );
  const [geocode, setGeocode] = React.useState(null);

  const handleChange = (e) => {
    setAddress(e.target.value);
  };

  const [data, setData] = React.useState(null);
  const handleGeocode = async () => {
    try {
      const response = await googleGeocodeService.geocodeAddress(address);
      setData(response);

      console.log(response);

      if (response.status === 200) {
        if (response.location.location_type === "ROOFTOP")
          console.log("direccion exacta");
        else {
          console.log("direccion aproximada");
        }
      } else {
        console.log("no pude recuperar coordenadas");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dialogStyles = {
    dialogPaper: {
      backgroundColor: "#c5c6c8",
    },
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} sx={{ padding: 0 }}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ height: 15 }}></Box>

      <Sidenav />

      {banner ? <Banner /> : ""}

      <Box
        component="main"
        sx={{
          display: "flex",
          justifyContent: "center", // Centrar horizontalmente
          maxWidth: "920px", // Establece el ancho máximo a 400px (ajusta según tus necesidades)
          margin: "0 auto", // Para centrar horizontalmente
          padding: "10px", // Añade algún relleno para que se vea mejor
        }}
      >
        <Stack direction="column" spacing={2}>
          <Button variant="outlined" onClick={handleClickOpen}>
            Open form dialog
          </Button>

          <Divider></Divider>

          <Box component="main" sx={{ display: "flex" }}>
          <Grid container>
              <Grid xs={12} md={8} minWidth="285px">            
            <Item
              sx={{
                m: 1,
                width: "100%",
                border: "1px dashed grey",
              }}
            >
              <Typography paragraph variant="h6" gutterBottom>
                Fecha de publicacion
              </Typography>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locale}
              >
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <Stack direction="row" spacing={2}>
                    <DemoItem label="Inicio">
                      <DatePicker
                        value={initialDate}
                        onChange={handleInitialDate}
                        format="dddd DD/MM/YYYY"
                        placeholderText="DD-MM-YYYY"
                        minDate={minDate}
                        maxDate={maxDate}
                        renderInput={(params) => <input {...params} />}
                        onError={(newError) => setErrorInitialDate(newError)}
                        slotProps={{
                          textField: {
                            helperText: errorMessageInitialDate,
                          },
                        }}
                        timezone={timezone}
                      />
                    </DemoItem>
                    <DemoItem label="Fin">
                      <DatePicker
                        value={endDate}
                        onChange={handleEndDate}
                        format="dddd DD/MM/YYYY"
                        placeholderText="DD-MM-YYYY"
                        minDate={minDate}
                        maxDate={maxDate}
                        renderInput={(params) => <input {...params} />}
                        onError={(newError) => setErrorEndDate(newError)}
                        slotProps={{
                          textField: {
                            helperText: errorMessageEndDate,
                          },
                        }}
                        timezone={timezone}
                      />
                    </DemoItem>
                  </Stack>
                </DemoContainer>
              </LocalizationProvider>
            </Item>
            </Grid>
            </Grid>
          </Box>
          <Divider></Divider>
          <Box>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                accept="image/*"
                type="file"
                onChange={(e) => setSelectedImage(e.target.files[0])}
              />
            </Button>

            {imageUrl && selectedImage && (
              <Box mt={2} textAlign="center">
                <div>Image Preview:</div>
                <img
                  src={imageUrl}
                  alt={selectedImage.name}
                  width={300}
                  height={75}
                />
              </Box>
            )}

            {base64String && (
              <div>
                <p>Imagen en base64:</p>
                <img src={base64String} alt="Imagen" />
              </div>
            )}
          </Box>

          <Divider></Divider>

          <Box>
            <div>
              <input
                id="direccion"
                type="text"
                placeholder="Dirección"
                value={address}
                onChange={handleChange}
              />
              <button onClick={handleGeocode}>Geocodificar</button>

              {data && data.status === 200 && (
                <div>
                  <h3>Direccion: {data.location.formatted_address}</h3>
                  <h3>Latitud: {data.location.location.lat}</h3>
                  <h3>Longitud: {data.location.location.lng}</h3>
                </div>
              )}

              {data && data.status !== 200 && (
                <div>
                  <h3>{data.message}</h3>
                </div>
              )}
            </div>
          </Box>

          <Box></Box>
        </Stack>
      </Box>
    </>
  );
}
