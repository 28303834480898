import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const marks = [
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 80,
    label: '80',
  },
];


export default function RecordByPage(props) {
  const [value, setValue] = React.useState(props.recordByPage);

  const handleSliderChange = (event, newValue) => {
    props.onRecordByPage(newValue);
  };

  

  return (
    <Box sx={{ width: 250, padding: '5px', textAlign:'center' }}>
        <Typography id="input-slider" gutterBottom>
        Cuantos registros quieres ver por pagina?
      </Typography>                
      <Slider
        aria-label="Registros por pagina"
        defaultValue={value}
        step={null}
        valueLabelDisplay="off"
        marks={marks}
        onChange={handleSliderChange}
      />
    </Box>
  );
}
