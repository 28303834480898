
function swapLista(lista, filPorPagina, colPorPagina) {
  let listaOrdenada = [];
  for (let i = 0; i < filPorPagina; i++) {
    for (let j = 0; j < colPorPagina; j++) {
      listaOrdenada.push(lista[i + filPorPagina * j]);
    }
  }
  return listaOrdenada;
}


// Exporta la función para que pueda ser utilizada en otros componentes
export default function genIndexForAds(param_regPorPagina, param_colPorPagina) {
  const regPorPagina = param_regPorPagina;
  const colPorPagina = param_colPorPagina;
  const listaOrdenada = [];

  for (let j = 1; j <= colPorPagina; j++) {
    const lista = [];
    let filPorPagina = Math.ceil(regPorPagina / j);
    for (let i = 1; i <= j; i++) {
      for (let k = 1; k <= filPorPagina; k++) {
        let valor =
          filPorPagina * (i - 1) + k <= regPorPagina
            ? filPorPagina * (i - 1) + k
            : -1;
        lista.push(valor);
      }
    }
    listaOrdenada.push(swapLista(lista, filPorPagina, j));
  }
  
  return listaOrdenada;
}

export {genIndexForAds};