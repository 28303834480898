import React from 'react';

 export function functionFormatter(valorAFormatear) {
  const formattedPrecio = valorAFormatear.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'CLP', // Cambia 'EUR' al código de moneda que desees
    maximumFractionDigits: 0, // Mostrará 0 decimales
    minimumFractionDigits: 0, // Mostrará 0 decimales
    minimumIntegerDigits: 1,
    useGrouping: true,
  });
  return formattedPrecio;
}

function CurrencyFormatter({ valorAFormatear }) {
  // Formatea el valor numérico como moneda usando la configuración regional actual
  const formattedPrecio = valorAFormatear.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'CLP', // Cambia 'EUR' al código de moneda que desees
    maximumFractionDigits: 0, // Mostrará 0 decimales
    minimumFractionDigits: 0, // Mostrará 0 decimales
    minimumIntegerDigits: 1,
    useGrouping: true,
  });

  return <div>{formattedPrecio}</div>; 
}

export default CurrencyFormatter;
