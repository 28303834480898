import * as React from "react";
import dayjs from "dayjs";

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import service from "../services";

const labels = {
  0.5: "Inutil",
  1: "Inutil+",
  1.5: "Pobre",
  2: "Pobre+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Bueno",
  4: "Bueno+",
  4.5: "Excelente",
  5: "Excelente+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function MyFeedback(props) {
  const [value, setValue] = React.useState(4);
  const [hover, setHover] = React.useState(-1);

  const [mensaje, setMensaje] = React.useState("");
  const [errorMensaje, setErrorMensaje] = React.useState(false);
  const handleMensajeChange = (event) => {
    setMensaje(event.target.value);
    if (event.target.value.length > 0) setErrorMensaje(false);
  };

  const handleCloseFeedback = () => {
    props.onReceiveOpenFeedback();
  };

  const handleSendFeedback = () => {
    if (mensaje.length === 0) {
      setErrorMensaje(true);
      return;
    }
    setErrorMensaje(false);

    var data = { puntuacion: value, mensaje: mensaje, fecha: dayjs(), pantalla: props.pantalla };

    service
      .addFeedbackForm({ data })
      .then((response) => {
        if (response.data.status === 200) {
          console.log("Feedback grabado");
        } else {
          console.log("No pude grabar el feedback");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

    handleOpenAlert();
    props.onReceiveOpenFeedback();

  };


  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const theme = useTheme();
  
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          Gracias por darnos tu feedback!
        </Alert>
      </Snackbar>

      <Dialog open={props.open} onClose={handleCloseFeedback}>
        <DialogTitle component="span">
        <Typography color="textPrimary" variant="h5" gutterBottom>
            Feedback
            </Typography>         
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="span" sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}  >
            Tu feedback nos ayudara a mejorar nuestro sitio.

            <Box sx={{ height: 15 }}></Box>

            <Box
              sx={{
                width: 200,
                display: "flex",
                alignItems: "center",
              }}
              
            >
              <Rating
                size="large"
                name="hover-feedback"
                value={value}
                precision={0.5}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit"  />
                }
              />
              {value !== null && (
                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
              )}
            </Box>

            <Box sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}>
            <TextField
              autoFocus
              margin="dense"
              id="mensaje"
              label="Mensaje"
              type="text"
              fullWidth
              variant="standard"
              required
              helperText={errorMensaje ? "Debe ingresar un mensaje" : ""}
              error={errorMensaje}
              onChange={handleMensajeChange}
            />
            </Box>

          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseFeedback} variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleSendFeedback} variant="outlined">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
