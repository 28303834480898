import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "../utils/CurrencyFormatter";
import Grid from "@mui/material/Unstable_Grid2";

export default function PaymentSummary(props) {

  const paymentSummary = (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <div>
          <CardContent>
            <Typography
              sx={{ fontSize: 44 }}
              color="text.secondary"
              gutterBottom
            >
              Valor Publicacion
            </Typography>
            <Typography variant="h6" component="div">
              <Grid container spacing={2}>
                <Grid xs={6}>Valor Base Anuncio {props.tipoAnuncio}</Grid>
                <Grid xs={1}>$</Grid>
                <Grid xs={5}>
                  <CurrencyFormatter valorAFormatear={props.valorBase}/>                  
                </Grid>

                <Grid xs={6}>
                  Valor Publicacion (
                  {props.numDiasNormal + props.numDiasPromocion} dias)
                </Grid>
                <Grid xs={1}>$</Grid>
                <Grid xs={5}>
                <CurrencyFormatter valorAFormatear={(props.numDiasNormal + props.numDiasPromocion) * props.valorDiario}/>                  

                </Grid>

                <Grid xs={6}>
                  Dscto Dias Promo ({props.numDiasPromocion} dias)
                </Grid>
                <Grid xs={1}>$</Grid>
                <Grid xs={5}>
                  <CurrencyFormatter valorAFormatear={props.numDiasPromocion * props.valorDiario}/>                  

                </Grid>

                <Grid xs={6} sx={{ fontSize: 25 }}>
                  VALOR A PAGAR :
                </Grid>
                <Grid xs={1} sx={{ fontSize: 25 }}>
                  $
                </Grid>
                <Grid xs={5} sx={{ fontSize: 25 }}>
                  {process.env.REACT_APP_CE_FREE_PUBLICATION && props.tipoAnuncio==="basico"?
                  0
                  :
                  <CurrencyFormatter valorAFormatear={props.valorBase + props.numDiasNormal * props.valorDiario}/>                  
                  }
                </Grid>
              </Grid>
            </Typography>
          </CardContent>

          <CardActions>
          </CardActions>
        </div>
        {/* Imagen transparente superpuesta */}
        {props.tipoAnuncio === "basico" ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "url(GratisPorTiempoLimitado.png) center/contain no-repeat",
              opacity: 0.8,
              zIndex: 1, // Ajusta el índice Z para que la imagen esté detrás de los elementos
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
      <Card variant="outlined">
        {paymentSummary}
      
      </Card>
      
    </Box>
  );
}
