import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";

export default function EnableGpsIcon(props) {
  const [value, setValue] = React.useState(props.enableGpsIcon);

  const handleSwitchChange = (event) => {
    setValue(event.target.checked);
    props.onEnableGpsIcon(event.target.checked);
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  return (
    <Box sx={{ width: 250, padding: "5px", textAlign: "center" }}>
      <Typography id="input-slider" gutterBottom>
        Muestra GPS en anuncios?
      </Typography>
      <Switch
        checked={value}
        onChange={handleSwitchChange}
      />      
    </Box>
  );
}
