import { createTheme } from '@mui/material/styles';

export const muiLigthTheme = createTheme ( {
  palette: {
    mode: 'light',
    primary: {
      main: '#2f3c54',
    },
    secondary: {
      main: '#6f2e77',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
  },
});

export  const muiLigthThemeDefault = createTheme( {
    palette: {
      mode: 'light',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
    },    
  })

export  const muiDarkTheme = createTheme( {
    palette: {
      mode: 'dark',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
    },    

  })

  export  const muiTestTheme = createTheme( {
    palette: {
      mode: 'dark',
      primary: {
        main: '#0f0',
      },
      background: {
        default: '#111111',
        paper: '#212121',
      },
    },
    typography: {
      fontFamily: 'Open Sans',
      h1: {
        fontFamily: 'Ubuntu Mono',
      },
      h2: {
        fontFamily: 'Ubuntu Mono',
      },
      h3: {
        fontFamily: 'Ubuntu Mono',
      },
      h4: {
        fontFamily: 'Ubuntu Mono',
      },
      h6: {
        fontFamily: 'Ubuntu Mono',
      },
      h5: {
        fontFamily: 'Ubuntu Mono',
      },
      subtitle1: {
        fontFamily: 'Ubuntu Mono',
      },
      subtitle2: {
        fontFamily: 'Ubuntu Mono',
      },
      button: {
        fontFamily: 'Ubuntu Mono',
        fontWeight: 900,
      },
      overline: {
        fontFamily: 'Ubuntu Mono',
      },
    },
})