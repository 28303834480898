import * as React from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Feedback } from "@mui/icons-material";
import { Home } from "@mui/icons-material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { WhatsAppSignedIcon } from "./whatsapp";

import MyFeedback from "./Feedback";

const fabStyle = {
  position: "fixed",
  bottom: 16,
  right: 16,
  zIndex: 9999,  
};

const liStyle = {
  color: "blue",
  listStyleType: "none",
  padding: "5px",
  margin: "3px",
};


export default function HelpSpeedDial(props) {
  const navigate = useNavigate();

  const [openFeedback, setopenFeedback] = React.useState(false);

  const handleClickopenFeedback = () => {
    setopenFeedback(true);
  };

  const receiveOpenFeedback = () => {
    setopenFeedback(false);
  };

  const handleClickOpenWhatsapp = () => {
    window.open(
        process.env.REACT_APP_WHATSAPP_URL +
        process.env.REACT_APP_WHATSAPP_CE +
          "?text=" +
          process.env.REACT_APP_WHATSAPP_CE_TXT,
        "_blank",
        "noopener,noreferrer"
      );    
  };


  const handleClickGoHome = () => {
    navigate("/?banner=false");

  };


  const actions = [
    {
      icon: <WhatsAppSignedIcon />,
      name: "Contactanos por whatsapp",
      onclick: handleClickOpenWhatsapp,
    },

    {
      icon: <Feedback color="success"  />,
      name: "Danos tu feedback",
      onclick: handleClickopenFeedback,
    },
    {
      icon: <Home color="success"  />,
      name: "Ir a clasificadosexpress",
      onclick: handleClickGoHome,
    },

  ];

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));


  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          Gracias por darnos tu feedback!
        </Alert>
      </Snackbar>

        <MyFeedback  open={openFeedback} onReceiveOpenFeedback={receiveOpenFeedback} pantalla={props.pantalla}  />

      <Box style={fabStyle}>
        <SpeedDial
          color="secondary"
          ariaLabel="Opciones"
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onclick}
            />
          ))}
        </SpeedDial>
      </Box>
    </>
  );
}
