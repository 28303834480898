import React, { useState } from "react";
import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

import { isValidFile } from "../utils/utils";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


function resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
  return new Promise(resolve => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;
      let shouldResize = false;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
          shouldResize = true;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
          shouldResize = true;
        }
      }
      if (shouldResize) {
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL("image/jpeg", 0.9));
      } else {
        resolve(base64Str);
      }
    };
  });
}


const ImageUpload = (props) => {
  const [errorMsg, setErrorMsg] = React.useState("");
  const [captureDisabled, setCaptureDisabled] = useState(true);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageResizedUrl, setImageResizedUrl] = useState(null);

  const handleClose = () => {
    setSelectedImage(null);
    setImageResizedUrl(null);
    setCaptureDisabled(true);
    props.handleClose(null);
  };

  const handleReturn = () => {
    setCaptureDisabled(true);
    props.handleClose(imageResizedUrl);

    setSelectedImage(null);
    setImageResizedUrl(null);
  };

  React.useEffect(() => {
    setSelectedImage(null);
    setImageResizedUrl(null);
  }, []);

  const handleChange = e => {

    const fileProperties = e.target.files[0];

    if (!isValidFile(fileProperties)) {
      setErrorMsg(
        "El archivo tiene un formato no conocido, formatos validos son jpeg, jpg, png y gif"
      );

      setSelectedImage(null);
      setImageResizedUrl(null);
  
      return;
    }

    const reader = new FileReader();
    reader.onload = function(e) {
      setCaptureDisabled(false);
      setSelectedImage(e.target.result);
      resizeImage(e.target.result, 350, 350).then(result => {
        setImageResizedUrl(result);
      });
    };
    reader.readAsDataURL(e.target.files[0]);

  };


  return (
    <div >
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth >
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Subir Imagen
              <VisuallyHiddenInput
                accept="image/*"
                type="file"
                onChange={handleChange}
              />
            </Button>
            <FormHelperText>{errorMsg}</FormHelperText>
          </FormControl>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component="span">
            <Box
              sx={{
                width: 300,
                minHeight: 200,
                border: "1px solid #000",
              }}
            >
              {(!imageResizedUrl || !selectedImage) && <div>imagen</div>}

              {imageResizedUrl && selectedImage && (
                <img
                  src={imageResizedUrl}
                  alt={selectedImage.name}
                  width={"100%"}
                  height={"100%"}
                />
              )}

            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleReturn}
            disabled={captureDisabled}
          >
            Aceptar
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageUpload;
