import React, { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const CameraCapture = (props) => {

  const videoRef = useRef(null);
  const [imageData, setImageData] = useState(null);
  const [stream, setStream] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState('');
  const [existsCameras, setExistsCameras] = useState(false);
  const [captureDisabled, setCaptureDisabled] = useState(true);

  React.useEffect(() => {
    const getVideoDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === "videoinput");
        setCameras(videoDevices);
        setExistsCameras(true);
      } catch (error) {
        console.error("Error al obtener la lista de cámaras:", error);
      }
    };

    getVideoDevices();

  }, []);

  React.useEffect(() => {
    const startCamera = async () => {
      try {
        if (selectedCamera) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: selectedCamera },
          });
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error("Error al acceder a la camara:", error);
      }
    };

    if (selectedCamera) {
      startCamera();
      setCaptureDisabled(false);
    }

    return () => {
      // Detener la cámara al desmontar el componente si es necesario
      if (videoRef.current) {
        if (videoRef.current.srcObject) {
          videoRef.current.srcObject
            .getTracks()
            .forEach((track) => track.stop());
        }
      }
    };

  }, [selectedCamera]);


  const captureImage = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    const context = canvas.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL("image/jpeg");
    setImageData(dataURL);
  };


  const handleClose = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      setStream(null);
    }
    if (videoRef.current){
      if ( videoRef.current.srcObject) {
      videoRef.current.srcObject
            .getTracks()
            .forEach((track) => track.stop());
      }
    }
    setSelectedCamera('');
    setCaptureDisabled(true);
    props.handleClose(imageData);
  };


  const handleChange = (event) => {
    setSelectedCamera(event.target.value);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {existsCameras && cameras ? (
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
              <InputLabel id="demo-select-small-label">
                Seleccione Camara
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedCamera}
                label="Seleccione Camara"
                onChange={handleChange}
              >
                  {cameras.map((camera) => (
                    <MenuItem key={camera.deviceId} value={camera.deviceId}>
                      {camera.label}
                    </MenuItem>
                  ))}

              </Select>
            </FormControl>
          ) : (
            "No encontre camara de video"
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <video ref={videoRef} width="300" height="400" autoPlay></video>
            <br />
            {imageData && (
              <img
                src={imageData}
                alt="Imagen capturada"
                width="400"
                height="300"
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={captureImage} disabled={captureDisabled}>
            Capturar imagen
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CameraCapture;
