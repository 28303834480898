//import * as React from "react";
import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";
import service from "../services";

const fabStyle = {
  position: "fixed",
  bottom: 16,
  right: 16,
};

export default function FloatingActionPagination(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [recordByPage, setRecordByPage] = React.useState(props.pageSize);
  const [filters, setFilters] = React.useState(props.filters);
  const [refresh, setRefresh] = React.useState(false);

  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const isTablet = window.matchMedia('(min-width: 769px) and (max-width: 1024px)').matches;
  const isDesktop = window.matchMedia('(min-width: 1025px)').matches;

  // Función para actualizar el ancho de la ventana cuando cambie el tamaño de la ventana
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const [pagination, setPagination] = React.useState({
    count: 0,
    from: 0,
    to: props.pageSize,
  });

  const handlePageChange = (event, page) => {
    const from = (page - 1) * props.pageSize;
    const to = (page - 1) * props.pageSize + props.pageSize;
    setPagination({ ...pagination, from: from, to: to });
  };


  const [flagLoading, setFlagLoading] = React.useState(false);

  // Agrega un listener para actualizar el ancho de la ventana cuando cambie el tamaño de la ventana
  React.useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []); // Solo se ejecuta al montar el componente


  React.useEffect(() => {
    if (!flagLoading) {
      setFlagLoading(true);

      var data = {
        region: props.filters.region,
        comuna: props.filters.comuna,
        categoria: props.filters.categoria,
        operacion: props.filters.operacion,
        producto: props.filters.producto,
        estadoProducto: props.filters.estadoProducto,
        textoAnuncio: props.filters.textoAnuncio,
        limit: props.pageSize,
        skip: pagination.from,
      };

      props.onLoading();
      service
        .getData({ from: pagination.from, to: pagination.to, filter: data })
        .then((response) => {
          setPagination({ ...pagination, count: response.count });
          props.onArregloCreado(response.data);
          props.onCountAds(response.count);
          setFlagLoading(false);
        });
      setRefresh(false);
    }
  }, [pagination.from, pagination.to, refresh, props.refresh, props.pageSize]);

  useEffect(() => {
    if (!props.isLoading) {
      const page = 1;
      const from = (page - 1) * props.pageSize;
      const to = (page - 1) * props.pageSize + props.pageSize;
      setPagination({ ...pagination, from: from, to: to });
      setRefresh(true);
    }
  }, [props.filters]);


  return (
    <Box
      style={fabStyle}
      sx={{
        borderRadius: "10px",
        padding: "2px",
        margin: "0px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
      }}
    >     
      <Pagination
        count={Math.ceil(pagination.count / props.pageSize)}
        color="primary"
        size="large"
        variant="outlined"
        siblingCount={0}
        boundaryCount={0}
        onChange={handlePageChange}
        showFirstButton={isTablet||isDesktop?true:false} 
        showLastButton={isTablet||isDesktop?true:false}
      />
    </Box>
  );
}
