import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";

import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "@mui/material/Link";

import LocationOnIcon from "@mui/icons-material/LocationOn";

import GoogleMapReact from "google-map-react";
import { red } from "@mui/material/colors";

import dayjs from "dayjs";
import { agregarObjeto, eliminarObjeto } from "../utils/Favorites";
import { socialNetworks } from "../services/dbAds";
import noDisponible from "../utils/nodisponible.jpg";

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      position: "absolute",
      transform: "translate(-50%, -50%)",
      padding: "5px",
    }}
  >
    {text}
    <LocationOnIcon sx={{ color: red[500] }} fontSize="large" />
  </div>
);

const Item = ({ text, ...props }) => {
  const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    position: "relative",
    "&::before": {
      content: `'${text}'`,
      position: "absolute",
      top: "-14px",
      left: 0,
      padding: "4px",
      color: theme.palette.text.primary,
      fontSize: "12px",
    },
  }));

  return <StyledPaper {...props} />;
};

export default function ViewAds(props) {
  const defaultProps = {
    zoom: 17,
    key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    coordinates: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  };

  const coordenadas =
    props.ads.coordenadas === undefined
      ? defaultProps.coordinates
      : props.ads.coordenadas;

  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    props.handleClose();
  };

  const handleAddFavorite = () => {
    let ads = {
      _id: props.ads._id,
      tipoAnuncio: props.ads.tipoAnuncio,
      tituloAnuncio: props.ads.tituloAnuncio,
      textoAnuncio: props.ads.textoAnuncio,
      pieAnuncio: props.ads.pieAnuncio,
      fechaFin: props.ads.fechaFin,
      usarGPS: props.ads.usarGPS,
      usarRRSS: props.ads.usarRRSS,
      comuna: props.ads.comuna,
    };
    agregarObjeto(props.ads._id, ads);
    props.onAddFavorite(props.ads._id);
    props.handleClose();
  };

  const handleRemoveFavorite = () => {
    eliminarObjeto(props.ads._id);
    props.onRemoveFavorite(props.ads._id);
    props.handleClose();
  };

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  const buscarPorRRSS = (rrss) => {
    return socialNetworks.find((network) => network.id === rrss);
  };

  const handleRRSS = (redSocial) => {
    if (redSocial.rrss === "whatsapp") {
      window.open(
        process.env.REACT_APP_WHATSAPP_URL +
          (redSocial.prefix ? "+"+redSocial.prefix : "") + redSocial.url +
          "?text=" +
          process.env.REACT_APP_WHATSAPP_TXT,
        "_blank",
        "noopener,noreferrer"
      );
    } else window.open(redSocial.url, "_blank", "noopener,noreferrer");
  };

  const [openChildDialog, setOpenChildDialog] = React.useState(false);
  const openChild = () => {
    setOpenChildDialog(true);
  };

  const closeChild = () => {
    setOpenChildDialog(false);
  };

  const cursorStyle = {
    cursor: "pointer",
  };

  function obtenURLBase(url) {
    // Encuentra el índice del primer "?" en la URL
    const indicePregunta = url.indexOf("?");

    // Si hay un "?" en la URL, toma la parte de la URL antes de "?"
    // De lo contrario, devuelve la URL sin cambios
    const urlBase =
      indicePregunta !== -1 ? url.substring(0, indicePregunta) : url;

    return urlBase;
  }

  const handleClickOpenWhatsapp = () => {
    window.open(
      process.env.REACT_APP_WHATSAPP_URL +
        "?text=" +
        "*"+ props.ads.textoAnuncio.substring(0,99) +"...!!!*" +
        "%0A" +
        obtenURLBase(window.location.href) +
        "?id=" +
        props.ads._id,
      "_blank",
      "noopener,noreferrer"
    );

    props.handleClose();
  };


  return (
    <>
      <Dialog fullScreen={fullScreen}
              open={openChildDialog} 
              onClose={closeChild}>
        <DialogContent>
          <img
            src={props.ads.imagen ? props.ads.imagen : noDisponible}
            srcSet={props.ads.imagen ? props.ads.imagen : noDisponible}
            alt="imagen anuncio"
            loading="lazy"
            style={{ width: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="outlined"
            onClick={closeChild}
            startIcon={<CloseIcon />}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" component="span">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" key="1" color="inherit">
              {props.ads.comuna}
            </Link>

            <Link underline="hover" key="2" color="inherit">
              {props.ads.categoria}
            </Link>
            <Link underline="hover" key="3" color="inherit">
              {props.ads.operacion}
            </Link>
            <Link underline="hover" key="4" color="inherit">
              {props.ads.producto}
            </Link>
          </Breadcrumbs>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="span">
            <Box sx={{ flexGrow: 1 }}>
              <Stack
              >
                <Item text="">
                  {props.ads.imagen ? (
                    <img
                      src={props.ads.imagen}
                      srcSet={props.ads.imagen}
                      alt="imagen anuncio"
                      loading="lazy"
                      style={{ width: "100%", ...cursorStyle }}
                      onClick={openChild}
                    />
                  ) : (
                    <img
                      src={noDisponible}
                      srcSet={noDisponible}
                      alt="imagen no disponible"
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  )}
                </Item>
                <Item text="">
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Item text="Periodo Publicacion">
                        {dayjs(props.ads.fechaIni).format("YYYY-MM-DD")} al{" "}
                        {dayjs(props.ads.fechaFin).format("YYYY-MM-DD")} -{" "}
                        {props.ads.numVisitas} {" visitas"}
                      </Item>
                    </Grid>

                    <Grid xs={12}>
                      <Item text="Contacto">
                        {props.ads.nombre}
                        <br></br>
                        {"Telefono " + props.ads.telefono}
                        {props.ads.email ? (
                          <>
                            <br></br>
                            {"Email " + props.ads.email}
                          </>
                        ) : (
                          ""
                        )}
                      </Item>
                    </Grid>

                    <Grid xs={12}>
                      <Item text="Redes Sociales">
                        {props.ads.redesSociales ? (
                          <ButtonGroup
                            label="Link a redes sociales"
                            size="small"
                            variant="contained"
                            aria-label="outlined primary button group"
                          >
                            {props.ads.redesSociales.map((network) => (
                              <IconButton
                                key={network.url}
                                aria-label={network.name}
                                onClick={() => handleRRSS(network)}
                              >
                                {buscarPorRRSS(network.rrss).iconOn}
                              </IconButton>
                            ))}
                          </ButtonGroup>
                        ) : (
                          "No hay informacion"
                        )}
                      </Item>
                    </Grid>

                    <Grid xs={12}>
                      <Item text="Direccion">
                        {props.ads.direccion + ". " + props.ads.comuna}{" "}
                      </Item>
                    </Grid>
                    <Grid xs={12}>
                      <Item text="Anuncio">
                        {props.ads.tituloAnuncio !== ""
                          ? props.ads.tituloAnuncio
                          : ""}
                        <p>{props.ads.textoAnuncio}</p>
                        {props.ads.pieAnuncio !== ""
                          ? props.ads.pieAnuncio
                          : ""}
                      </Item>
                    </Grid>
                  </Grid>
                </Item>
              </Stack>

              {props.ads.usarGPS === true ? (
                <>
                  <br></br>
                  <Stack>
                    <Item text="Ubicacion">
                      <div style={{ height: "300px", width: "100%" }}>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: defaultProps.key }}
                          defaultCenter={coordenadas.location}
                          defaultZoom={defaultProps.zoom}
                        >
                          <AnyReactComponent
                            lat={coordenadas.location.lat}
                            lng={coordenadas.location.lng}
                            text={props.ads.direccion}
                          />
                        </GoogleMapReact>
                      </div>
                    </Item>
                  </Stack>
                </>
              ) : (
                ""
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            autoFocus
            variant="outlined"
            onClick={handleClickOpenWhatsapp}
            startIcon={<IosShareIcon />}
          >
            Compartir
          </Button>

          {props.isFavorite ? (
            <Button
              size="small"
              variant="outlined"
              onClick={handleRemoveFavorite}
              startIcon={<HeartBrokenIcon />}
            >
              Remover
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              onClick={handleAddFavorite}
              startIcon={<FavoriteIcon />}
            >
              Agregar
            </Button>
          )}
          <Button
            autoFocus
            size="small"
            variant="outlined"
            onClick={handleClose}
            startIcon={<CloseIcon />}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
