import DataServiceForAds from "../services/ads.service";

const service = {

  countFreeAds: ({phoneNumber }) => {
    return new Promise((resolve, reject) => {
      DataServiceForAds.countFreeAds(phoneNumber)
        .then((response) => {
          resolve({
            data: response.data,
          });
        })
        .catch((e) => {
          console.log(e);
          window.location = '/Error?e='+e;
        });
    });
  },


  updateStatus: ({_id, data }) => {
    return new Promise((resolve, reject) => {
      DataServiceForAds.updateStatus(_id, data)
        .then((response) => {
          resolve({
            data: response.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },


  createData: ({ data }) => {
    return new Promise((resolve, reject) => {
      DataServiceForAds.create(data)
        .then((response) => {
          resolve({
            data: response.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },
  
  /*
  getData: ({ from, to, filter }) => {
    const exito = false;
    const reintentos = 0;
    return new Promise((resolve, reject) => {
      DataServiceForAds.findAll(filter)
        .then((response) => {
          resolve({
            count: response.data.count,
            data: response.data.data,
          });
        })
        .catch((e) => {
          console.log(e);
          window.location = '/Error?e='+e;
        });
    });
  },
  */

  getData: ({ from, to, filter }) => {
    return new Promise((resolve, reject) => {
      let attempts = 0;
  
      const makeRequest = () => {
        DataServiceForAds.findAll(filter)
        .then((response) => {
          resolve({
            count: response.data.count,
            data: response.data.data,
          });
        })
        .catch((error) => {
          console.log(`Attempt ${attempts + 1} failed:`, error);
          attempts++;
          if (attempts < 3) {
            console.log("Retrying...");
            setTimeout(() => {
              makeRequest(); // Retry the request after 1 second
            }, 1500);
          } else {
            console.log("All attempts failed. Redirecting to error page.");
            window.location = '/Error?e=' + error;
          }

        });

      }
      makeRequest(); // Initial request

      });
  },


  getOne: ({id}) => {
    return new Promise((resolve, reject) => {
      DataServiceForAds.findOne(id)
        .then((response) => {
          resolve({
            data: response.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },

  getCoordinates: ({address}) => {
    return new Promise((resolve, reject) => {
      DataServiceForAds.getCoordinates(address)
        .then((response) => {
          resolve({"location": response.data,
                   "message": "SUCCESS",
                   "status":200},
                 );
        })
        .catch((e) => {
          console.log(e);
          //resolve({"message":e.response.statusText,
          //         "status":e.response.status},
          //       );
          //window.location = '/Error';
        });
    });
  },

  /*
  getTariff: ({adsDate, adsType}) => {
    return new Promise((resolve, reject) => {
      DataServiceForAds.getTariff(adsDate,adsType)
        .then((response) => {
          resolve({"tariff": response.data,
                   "message": "SUCCESS",
                   "status":200},
                 );
        })
        .catch((e) => {
          console.log(e);
          window.location = '/Error?e='+e;

        });
    });
  },
  */

  getTariff: ({adsDate, adsType}) => {
    return new Promise((resolve, reject) => {
      let attempts = 0;
  
      const makeRequest = () => {
        DataServiceForAds.getTariff(adsDate, adsType)
          .then((response) => {
            resolve({
              "tariff": response.data,
              "message": "SUCCESS",
              "status": 200
            });
          })
          .catch((error) => {
            console.log(`Attempt ${attempts + 1} failed:`, error);
            attempts++;
            if (attempts < 3) {
              console.log("Retrying...");
              setTimeout(() => {
                makeRequest(); // Retry the request after 1 second
              }, 1000);
            } else {
              console.log("All attempts failed. Redirecting to error page.");
              window.location = '/Error?e=' + error;
            }
          });
      };
  
      makeRequest(); // Initial request
    });
  }, 

  addContactForm: ({ data }) => {
    return new Promise((resolve, reject) => {
      DataServiceForAds.addContactForm(data)
        .then((response) => {
          resolve({
            data: response.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },

  addFeedbackForm: ({ data }) => {
    return new Promise((resolve, reject) => {
      DataServiceForAds.addFeedbackForm(data)
        .then((response) => {
          resolve({
            data: response.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },



  validateToken: ({ token }) => {
    return new Promise((resolve, reject) => {
      DataServiceForAds.validateToken(token)
        .then((response) => {
          resolve({"data": response.data,
          "message": "SUCCESS",
          "status":200}
          );
        })
        .catch((e) => {
          resolve({"id":null,
                   "message":e.response.data.message,
                   "status":e.response.status},
                 );

        });
    });
  },

  createTransactionWebPayPlus: ({data}) => {
    return new Promise((resolve, reject) => {
      DataServiceForAds.createTransactionWebPayPlus(data)
        .then((response) => {
          resolve({"token": response.token,
                   "url": response.url, 
                   "message": "SUCCESS",
                   "status":200},
                 );
        })
        .catch((e) => {
          resolve({"message":e.response.statusText,
                   "status":e.response.status},
                 );
        });
    });
  },

  commitTransactionWebPayPlus: ({data}) => {
    console.log(data);
    return new Promise((resolve, reject) => {
      DataServiceForAds.commitTransactionWebPayPlus(data)
        .then((response) => {
          resolve({"data": response.data, 
                   "message": "SUCCESS",
                   "status":200},
                 );
        })
        .catch((e) => {
          resolve({"message":e.response.statusText,
                   "status":e.response.status},
                 );
        });
    });
  },


};

export default service;
