
const st0 = {
    fill:'#BD081C'
};

const st1 = {
    fill:'#FFFFFF'
};

const PinterestSignedIcon = () => {
    return (    

<svg id="Layer_1" 
version="1.1" 
viewBox="0 0 1000 1000" 
xmlns="http://www.w3.org/2000/svg" 
width="33px"
height="33px"
enableBackground={"new 0 0 1000 1000"}

>
<title/>
<g>
    <path style={st0} d="M500,1000L500,1000C223.9,1000,0,776.1,0,500v0C0,223.9,223.9,0,500,0h0c276.1,0,500,223.9,500,500v0   C1000,776.1,776.1,1000,500,1000z"/>
    <g>
    <path style={st1}  d="M500.5,150.5c-193.3,0-350,156.7-350,350c0,148.3,92.3,275,222.5,326c-3.1-27.7-5.8-70.3,1.2-100.5    c6.4-27.3,41-174,41-174s-10.5-21-10.5-52c0-48.7,28.2-85,63.3-85c29.9,0,44.3,22.4,44.3,49.3c0,30-19.1,74.9-29,116.5    c-8.2,34.8,17.5,63.2,51.8,63.2c62.2,0,110-65.6,110-160.3c0-83.8-60.2-142.4-146.2-142.4c-99.6,0-158,74.7-158,151.9    c0,30.1,11.6,62.3,26,79.9c2.9,3.5,3.3,6.5,2.4,10c-2.7,11.1-8.6,34.8-9.7,39.7c-1.5,6.4-5.1,7.8-11.7,4.7    c-43.7-20.3-71-84.3-71-135.6c0-110.4,80.2-211.8,231.3-211.8c121.4,0,215.8,86.5,215.8,202.1C724,603,648,700.1,542.4,700.1    c-35.5,0-68.8-18.4-80.2-40.2c0,0-17.5,66.8-21.8,83.2c-7.9,30.4-29.2,68.5-43.5,91.8c32.7,10.1,67.5,15.6,103.6,15.6    c193.3,0,350-156.7,350-350C850.5,307.2,693.8,150.5,500.5,150.5z"/>
    </g>
</g>
</svg>
 );
};

export {PinterestSignedIcon};