import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const images = [
  {
    id:1,
    url: "/images/lanzamiento_xs.png",
    title: "Clasificados Express",
    width: "100vh",
    to: "/",
  },
];

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  backgroundSize: "100% 100%",
}));

export default function Launch() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dialogStyles = {
    dialogPaper: {
      backgroundColor: "#c5c6c8",
    },
  };

  const [openBanner, setOpenBanner] = React.useState(true);
  const handleCloseBanner = () => {
    setOpenBanner(false);
  };

  return (
    <Dialog
      open={openBanner}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth={true}
      aria-describedby="alert-dialog-description"
      scroll="body"
      PaperProps={{
        style: dialogStyles.dialogPaper,
      }}
      onClose={handleCloseBanner}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            minWidth: 300,
            width: "100%",
            padding: 1,
            minHeight: 600,
            maxHeight: 800,
          }}
        >
          {images.map((image) => (
            <Image
              key={image.id}
              style={{ backgroundImage: `url(${image.url})` }}
              onClick={handleCloseBanner}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
