import * as React from "react";

import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { Stack } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useNavigate, useLocation } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Sidenav from "../Sidenav";
import DataServiceForAds from "../services/ads.service";
import PreviewAds from "../others/previewAds";
import { CancelingReasons } from "../services/dbAds";
import { isValidPhone } from "../utils/utils";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Terminate() {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const id = query.get("id");

  const [currentDate, setCurrentDate] = React.useState(dayjs().endOf("day"));
  const [alertMessage, setAlertMessage] = React.useState("error");
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleOpenErrorAlert = () => {
    setOpenErrorAlert(true);
  };
  const handleCloseErrorAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };

  const [isErrorPage, setIsErrorPage] = React.useState(false);
  const navigate = useNavigate();
  const [ads, setAds] = React.useState(null);

  const [fechaInicio, setFechaInicio] = React.useState("");
  const [fechaFin, setFechaFin] = React.useState("");

  const [numIntentos, setNumIntentos] = React.useState(1);

  const [errorTelefono, setErrorTelefono] = React.useState(false);
  const [telefono, setTelefono] = React.useState("");
  const [telefonoAnterior, setTelefonoAnterior] = React.useState("");

  const [errorMotivo, setErrorMotivo] = React.useState(false);
  const [motivo, setMotivo] = React.useState(undefined);

  const handleTelefonoChange = (event) => {
    setTelefono(event.target.value);
    if (event.target.value.length > 0) setErrorTelefono(false);
  };

  // se debe recibir el ID del  anuncio y un token con el id y la fecha de expiracion
  const [estadoAnuncio, setEstadoAnuncio] = React.useState(undefined);
  const [ultimoEstado, setUltimoEstado] = React.useState(undefined);

  React.useEffect(() => {
    // valida token
    DataServiceForAds.validateToken(token)
      .then((responseValidate) => {
        console.log(responseValidate);
        if (responseValidate.status === 200) {
          console.log("token valido ahora a validar el id");
          if (responseValidate.data.id === id) {
            console.log("wii...token corresponde al id");

            DataServiceForAds.findOneMasked(id)
              .then((response) => {
                //console.log(response.data);
                setAds(response.data);
                setTelefonoAnterior(response.data.telefono);
                setFechaInicio(response.data.fechaIni);
                setFechaFin(response.data.fechaFin);
                setEstadoAnuncio(response.data.estadoAnuncio);
                setUltimoEstado(response.data.estadosAnuncio);
              })
              .catch((e) => {
                console.log(e.message);
                setIsErrorPage(true);
                // grave grave .. no encontre aviso
                return;
              });
          } else {
            console.log("token invalido");
            setIsErrorPage(true);
          }
        } else {
          console.log("token invalido o expirado");
          setIsErrorPage(true);
        }
      })
      .catch((e) => {
        console.log(e.message);
        setIsErrorPage(true);
        // grave grave .. token invalido
        return;
      });

    //console.log(currentDate.toDate());
  }, [id, token]);

  const [estadoActual, setEstadoActual] = React.useState("");
  React.useEffect(() => {
    //console.log(estadoAnuncio);

    if (new Date(fechaFin) < currentDate.toDate()) {
      setEstadoActual("Expirado");
    } else {
      if (estadoAnuncio === "Dado de baja") {
        setEstadoActual("Baja");
      }
      //if (
      //  estadoAnuncio === "Pagado" &&
      //  new Date(fechaFin) < currentDate.toDate()
      //) {
      //  setEstadoActual("Expirado");
      //}
      if (
        estadoAnuncio === "Pagado" &&
        new Date(fechaFin) >= currentDate.toDate()
      ) {
        setEstadoActual("Vigente");
      }
    }
  }, [estadoAnuncio, fechaFin]);

  const handleGotoHome = (event) => {
    event.preventDefault();
    navigate("/?banner=false");
  };

  const handleShutdown = (event) => {
    event.preventDefault();

    if (telefono.length === 0) {
      setErrorTelefono(true);
      return;
    } else {
      if (!isValidPhone(telefono)) {
        setErrorTelefono(true);
        return;
      }
    }

    DataServiceForAds.validateOwner({ id: ads._id, phoneNumber: telefono })
      .then((response) => {
        if (response.data.exists) {
          console.log("wii encontre anuncio");
          DataServiceForAds.updateStatus(ads._id, {
            estado: "Dado de baja",
            fecha: dayjs(),
            nota: motivo ? motivo : "usuario no ingreso motivo",
            usuario: "web",
          })
            .then((status) => {
              navigate("/?banner=false");
            })
            .catch((e) => {
              console.log(e.message);
              setIsErrorPage(true);
              return;
            });
        } else {
          //console.log("ops parece que el anuncio no es del usuario")
          setAlertMessage(
            "Ops, el anuncio esta asociado a otro numero de telefono"
          );
          setOpenErrorAlert(true);
          setNumIntentos(numIntentos + 1);
        }
      })
      .catch((e) => {
        console.log(e.message);
        setIsErrorPage(true);
        // grave grave .. no encontre aviso
        return;
      });
  };

  return (
    <>
      <Snackbar
        open={openErrorAlert}
        autoHideDuration={3000}
        onClose={handleCloseErrorAlert}
      >
        <Alert
          onClose={handleCloseErrorAlert}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <Box sx={{ height: 5 }}></Box>
      <Sidenav />
      <Box
        component="main"
        sx={{
          display: "flex",
          justifyContent: "center", // Centrar horizontalmente
          maxWidth: "920px", // Establece el ancho máximo a 400px (ajusta según tus necesidades)
          margin: "0 auto", // Para centrar horizontalmente
          padding: "16px", // Añade algún relleno para que se vea mejor
        }}
      >
        {isErrorPage ? (
          <>
                    <Stack direction="column" spacing={2}>

            <Typography variant="h4" gutterBottom>
              Token Invalido
            </Typography>

            <Button variant="contained" onClick={handleGotoHome}>
              Ir a ClasificadosExpress
            </Button>
            </Stack>
          </>
        ) : (
          ""
        )}

        {!isErrorPage ? (
          <Stack direction="column" spacing={2}>
            <Typography variant="h6" gutterBottom>
              {estadoActual === "Baja"
                ? "Anuncio fue dado de baja el dia " +
                  dayjs(ultimoEstado.fecha).format("YYYY-MM-DD")
                : estadoActual === "Expirado"
                ? "Anuncio expiro el dia " +
                  dayjs(fechaFin).format("YYYY-MM-DD")
                : estadoActual === "Vigente"
                ? "Ingrese la informacion requerida para proceder a dar de baja el anuncio."
                : ""}
            </Typography>

            {ads ? (
              <>
                <Typography variant="body" gutterBottom>
                  Anuncio/banner
                </Typography>

                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {ads.tipoPublicacion === "banner" ? (
                    <Box
                      sx={{
                        height:
                          ads.tipoAnuncio === "basico"
                            ? 55
                            : ads.tipoAnuncio === "full"
                            ? 155
                            : 205,
                        width: "100%",
                        maxWidth: "346px",
                        border: "1px solid #000",
                      }}
                    >
                      <img
                        src={ads.banner.imagen}
                        alt={ads.banner.nombre}
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                  ) : (
                    <PreviewAds
                      tipoAnuncio={ads.tipoAnuncio}
                      texto={ads.textoAnuncio + ". " + ads.comuna}
                      titulo={ads.tituloAnuncio}
                      pie={ads.pieAnuncio}
                    />
                  )}
                </Grid>

                <Grid xs={12}>
                  <TextField
                    disabled
                    value={
                      dayjs(fechaInicio).format("YYYY-MM-DD") +
                      " al " +
                      dayjs(fechaFin).format("YYYY-MM-DD")
                    }
                    id="periodoPublicacion"
                    label={" Periodo de publicacion"}
                    variant="filled"
                    sx={{ m: 1, width: "100%" }}
                  />
                </Grid>

                {estadoActual === "Vigente" ? (
                  <>
                    <Typography variant="body" gutterBottom>
                      Confirme el telefono de contacto
                    </Typography>
                    <Grid xs={12}>
                      <TextField
                        required
                        value={telefono}
                        id="telefono"
                        label={"Telefono de Contacto " + telefonoAnterior}
                        variant="filled"
                        sx={{ m: 1, width: "100%" }}
                        onChange={handleTelefonoChange}
                        helperText={
                          errorTelefono
                            ? "Debe ingresar un numero de telefono valido"
                            : ""
                        }
                        error={errorTelefono}
                      />
                    </Grid>

                    <Typography variant="body" gutterBottom>
                      Seleccione el motivo
                    </Typography>

                    <Grid xs={12} sx={{ m: 1, width: "100%" }}>
                      <FormControl fullWidth>
                        <InputLabel id="region-helper-label">Motivo</InputLabel>
                        <Select
                          labelId="category-helper-label"
                          id="category"
                          value={motivo}
                          label="Motivo"
                          displayEmpty
                          //onChange={handleMotivoChange}
                          variant="filled"
                          defaultValue={"Otros"}
                        >
                          {CancelingReasons.map((reason) => (
                            <MenuItem key={reason.id} value={reason.name}>
                              {reason.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Button variant="contained" onClick={handleShutdown}>
                      Dar de Baja
                    </Button>
                  </>
                ) : (
                  <Button variant="contained" onClick={handleGotoHome}>
                    Ir a ClasificadosExpress
                  </Button>
                )}
              </>
            ) : (
              ""
            )}
          </Stack>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
