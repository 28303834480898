import React, { useState, useEffect, useRef  } from "react";
import { useNavigate } from "react-router-dom";


import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { genIndexForAds } from "../utils/GenIndexForAds";
import FloatingActionPagination from "./FloatingActionsPagination";
import FloatingActionFilters from "./FloatingActionsFilters";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import "./Ads.css";
import PrintAds from "./Print";
import service from "../services";
import ViewAds from "./ViewAds";
import { buscarObjeto } from "../utils/Favorites";
import { Categories} from "../services/dbAds";

const MAX_COLUMN_PAGE = 5;
const MAX_RECORDS_PAGE = 20;
//const indexAds = genIndexForAds(MAX_RECORDS_PAGE, MAX_COLUMN_PAGE); // generar nuevo indexAds cuando cambia el pageSize
const COLUMN_BREAKPOINTS = [300, 600, 900, 1200, 1500];
const WIDTH_HAMBURGUER_MENU = 300;

const liStyle = {
  listStyleType: "none",
  padding: "5px",
  margin: "3px",
};

const Mural = (props) => {
  const id = props.id;
  const category = props.category;

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    navigate("/?banner=false");
  };

  const [indexAds, setIndexAds] = useState(
    genIndexForAds(MAX_RECORDS_PAGE, MAX_COLUMN_PAGE)
  );
  const [arreglo, setArreglo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Declaración del estado
  const [ads, setAds] = useState({});

  const viewAds = (data) => {
    service.getOne({ id: data }).then((response) => {
      setOpen(true);
      setAds(response.data);
    });
  };

  const publishAds = () => {
    navigate("/Publish");

  }

  // calcula el numero de columnas visibles en la pagina
  const [columns, setColumns] = useState(5);
  const recibirArreglo = (nuevoArreglo) => {
    setArreglo(nuevoArreglo);
    setIndexAds(
      genIndexForAds(
        nuevoArreglo.length >= pageSize ? nuevoArreglo.length : pageSize,
        MAX_COLUMN_PAGE
      )
    );

    setIsLoading(false);
  };


  const [countAds, setCountAds] = useState(0);
  const recibirCountAds = (count) => {
    setCountAds(count);
  };

  const esperarCarga = () => {
    setIsLoading(true);
  };

  const [pageSize, setPageSize] = useState(MAX_RECORDS_PAGE);
  const recibirPageSize = (nuevoPageSize) => {
    setPageSize(nuevoPageSize);
  };

  const [enableGpsIcon, setEnableGpsIcon] = useState(false);
  const recibirEnableGpsIcon = (enableDisable) => {
    setEnableGpsIcon(enableDisable);
  };

  const [enableRRSSIcon, setEnableRRSSIcon] = useState(false);
  const recibirEnableRRSSIcon = (enableDisable) => {
    setEnableRRSSIcon(enableDisable);
  };

  const [flagInitialPage, setFlagInitialPage] = useState(false);
  const [flagFilter, setFlagFilter] = useState(false);
  const [filters, setFilters] = useState( {} );
  const recibirFilters = (filters) => {
    setFilters(filters);
    setFlagInitialPage(!flagInitialPage);
  };

  const recibirFlagFilter = (flag) => {
    setFlagFilter(flag)
  };

  const [initFilters, setInitFilters] = useState( false );
  useEffect(() => {
    if (category !== "-1") {
      // validar que categoria exista en lista de categorias
      const categoryName = category.toLowerCase();
      const categoryFound = Categories.find(category => category.name.toLowerCase() === categoryName);
      if (categoryFound) {
        let filters = {"region":"","comuna":"","categoria":categoryFound.name,"operacion":"","producto":"","estadoProducto":"","textoAnuncio":""};
        recibirFilters(filters)
        setInitFilters(true);
      }
      else {
        console.log("algo malo paso , seras destruido en 5 segundos");
      }
    }
    else {
      setInitFilters(false);
    }
  }, []);



  useEffect(() => {
    const updateColumns = () => {
      const windowWidth = window.innerWidth;
      let newColumns = MAX_COLUMN_PAGE;
      for (let i = 0; i < COLUMN_BREAKPOINTS.length; i++) {
        if (windowWidth - WIDTH_HAMBURGUER_MENU < COLUMN_BREAKPOINTS[i]) {
          newColumns = i + 1;
          break;
        }
      }
      setColumns(newColumns);
    };
    updateColumns();
    window.addEventListener("resize", updateColumns);
    return () => {
      window.removeEventListener("resize", updateColumns);
    };
  }, []);

  const [indexFavorites, setIndexFavorites] = useState([]);
  const [arrayFavorites, setArrayFavorites] = useState([]);

  const updateArrayFavorites = (storedFavorites) => {
    var favorites = [];
    storedFavorites.forEach((id) => {
      const objetoEncontrado = buscarObjeto(id);
      favorites.push(objetoEncontrado);
    });
    setArrayFavorites(favorites || []);
  };

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setIndexFavorites(storedFavorites);

    updateArrayFavorites(storedFavorites);
  }, []);

  // Agregar un nuevo favorito
  const addFavorite = (newFavorite) => {
    if (!newFavorite || indexFavorites.includes(newFavorite)) return;
    const updatedFavorites = [...indexFavorites, newFavorite];
    setIndexFavorites(updatedFavorites);
    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));

    updateArrayFavorites(updatedFavorites);
  };

  // Eliminar un favorito
  const removeFavorite = (favorite) => {
    const updatedFavorites = indexFavorites.filter((item) => item !== favorite);
    setIndexFavorites(updatedFavorites);
    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));

    updateArrayFavorites(updatedFavorites);
  };

  // Eliminar lista de favoritos
  const cleanFavorites = (favorite) => {

    //elimina favoritos
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    storedFavorites.forEach((id) => {
      localStorage.removeItem(id);
    });

    //elimina lista
    const emptyFavorites = [];
    setIndexFavorites(emptyFavorites);
    localStorage.removeItem("favorites");
    updateArrayFavorites(emptyFavorites);

  };



  // Verificar si un ID es un favorito
  const checkFavorite = (id) => {
    return indexFavorites.includes(id);
  };

  useEffect(() => {
    if (id !== "-1") {
      viewAds(id);
    }
  }, [props.id]);



  const [refresh, setRefresh] = useState(false);
  const ejecutaRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (props.refreshMural) {
        props.offRefreshHome();
        ejecutaRefresh();
    }
  }, [props.refreshMural]);


  return (
    <>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <Box component="main" sx={{ display: "flex" }}>
            <center>
              <Typography paragraph variant="h3" gutterBottom>
                cargando anuncios
                <br></br>
                <CircularProgress color="inherit" />
              </Typography>
            </center>
          </Box>
        </Backdrop>
      </div>

      <ViewAds
        ads={ads}
        open={open}
        handleClose={handleClose}
        onAddFavorite={addFavorite}
        onRemoveFavorite={removeFavorite}
        isFavorite={checkFavorite(ads._id)}
      />

      <FloatingActionFilters
        onPageSize={recibirPageSize}
        onEnableGpsIcon={recibirEnableGpsIcon}
        enableGpsIcon={enableGpsIcon}
        onEnableRRSSIcon={recibirEnableRRSSIcon}
        enableRRSSIcon={enableRRSSIcon}
        onFilters={recibirFilters}
        onFlagFilter = {recibirFlagFilter}
        flagFilter= {flagFilter}
        pageSize={pageSize}
        onCleanFavorites={cleanFavorites}
        onRemoveFavorite={removeFavorite}
        favorites={arrayFavorites}
        ads={countAds}
        onViewAds={viewAds}
        pantalla={"home"}
        onRefresh={ejecutaRefresh}
        filters={filters}
        initFilters={initFilters}
      />

      <FloatingActionPagination
        onLoading={esperarCarga}
        onArregloCreado={recibirArreglo}
        pageSize={pageSize}
        filters={filters}
        refresh={refresh}
        flagInitialPage={flagInitialPage}
        onCountAds={recibirCountAds}
        isLoading={isLoading}
        initFilters={initFilters}

      />

      <div >
        {!isLoading & (arreglo.length > 0) ? (
            <>
              <ResponsiveMasonry
                columnsCountBreakPoints={{
                  300: 1,
                  600: 2,
                  900: 3,
                  1200: 4,
                  1500: 5,
                }}
              >
                <Masonry>
                  {indexAds[columns - 1].map((index, j) =>
                    index === -1 ? (
                      ""
                    ) : index <= arreglo.length ? (
                      <li key={j} style={liStyle}>
                        <PrintAds
                          anuncio={arreglo[index - 1]}
                          funcion={viewAds}
                          isFavorite={
                            arreglo[index - 1]._id
                              ? checkFavorite(arreglo[index - 1]._id)
                              : false
                          }
                          enableGpsIcon={enableGpsIcon}
                          enableRRSSIcon={enableRRSSIcon}
                          enlace={publishAds}

                        />
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </Masonry>
              </ResponsiveMasonry>
            </>
        ) : (
          ""
        )}

        {!isLoading && arreglo.length === 0 ? (
          <Box component="main" sx={{ display: "flex" }}>
            <Typography paragraph variant="h3" gutterBottom>
              No encontre anuncios
            </Typography>
          </Box>
        ) : (
          ""
        )}

      </div>
    </>
  );
};
export default Mural;
