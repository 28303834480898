import * as React from "react";
import { Divider, Stack } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Sidenav from "../Sidenav";

export default function Home() {
  const query = new URLSearchParams(useLocation().search);
  const error = query.get("e");
  const navigate = useNavigate();

  const handleGotoHome = (event) => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <>
      <Box sx={{ height: 15 }}></Box>
      <Sidenav />
      <Box
        component="main"
        sx={{
          display: "flex",
          justifyContent: "center", // Centrar horizontalmente
          maxWidth: "920px", // Establece el ancho máximo a 400px (ajusta según tus necesidades)
          margin: "0 auto", // Para centrar horizontalmente
          padding: "16px", // Añade algún relleno para que se vea mejor
        }}
      >
        <Stack direction="column" spacing={2}>
          <Typography variant="h5" gutterBottom>
            Estamos presentando algunos problemas en nuestros servicios, por
            favor, vuelve mas tarde.
          </Typography>

          <Divider></Divider>
          <Typography variant="h6" gutterBottom>
            {error}
          </Typography>
          <Divider></Divider>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Button variant="contained" onClick={handleGotoHome}>
              Ir al inicio
            </Button>
          </Box>
        </Stack>
      </Box>

    </>
  );
}
