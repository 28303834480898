
const API_KEY = process.env.REACT_APP_GOOGLE_GEO_API_KEY; // Reemplaza con tu propia clave de API

async function geocodeAddress(address) {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${API_KEY}`
  );

  if (!response.ok) {
    throw new Error('Error en la solicitud de geocodificación');
  }

  const data = await response.json();
  if (data.status === "OK") {
    return({
      status: 200,
      message: "SUCCESS",
      location: {
        query_date: new Date(),
        formatted_address: data.results[0].formatted_address,
        place_id: data.results[0].place_id,
        location_type: data.results[0].geometry.location_type,
        location: data.results[0].geometry.location,
      },
    });

  }
  else {
    return ({
      status: 500,
      message: data.error_message,
      location: null
    });    
  }

  
  //return data;
}

export default { geocodeAddress };
