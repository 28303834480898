import React from "react";

import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import InputAdornment from "@mui/material/InputAdornment";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { socialNetworks } from "../services/dbAds";

const arrRRSS = [];
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const divStyle = {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Centrar los elementos horizontalmente
  marginTop: '0px',     // Margen en la parte superior
  marginBottom: '0px', // Margen en la parte inferior
  marginLeft: '1px',    // Margen en el lado izquierdo
  marginRight: '1px',   // Margen en el lado derecho  
};

const InputRRSS = (props) => {
  const [openRRSS, setOpenRRSS] = React.useState(false);
  const [onoffRRSS, setOnOffRRSS] = React.useState("");
  const wnRegex = /^\+56[1-9]\d{8}$/;
  const urlRegex = /^(http|https):\/\/[^ "]+$/;
  const [prefix, setPrefix] = React.useState(process.env.REACT_APP_NUMERIC_COUNTRY_CODE);
  const [url, setUrl] = React.useState("");
  const [format, setFormat] = React.useState("");
  const [title, setTitle] = React.useState("");

  const [isValidUrl, setIsValidUrl] = React.useState(true);

  React.useEffect(() => {
   resetSocialNetworks();
  }, []);

  const handleOnOffRRSS = (onoff, format, title) => {
    setOnOffRRSS(onoff);
    let elemRRSS = buscarElementoPorIndice(arrRRSS, onoff);
    setUrl(elemRRSS.url);
    setFormat(format);
    setTitle(title)
    setPrefix(process.env.REACT_APP_NUMERIC_COUNTRY_CODE);
    setOpenRRSS(true);
  };

  const handleCloseRRSS = () => {
    setOpenRRSS(false);
  };

  const handleClean = () => {
    //console.log("debo limpiar [", onoffRRSS);
    eliminarElementoPorIndice(arrRRSS, onoffRRSS);
    setIsValidUrl(true);
    setOpenRRSS(false);
    setUrl("");
    setFormat("");
  };

  const handleUrlChange = (e) => {
    const inputUrl = e.target.value;
    setUrl(inputUrl);
    if (inputUrl.length === 0) setIsValidUrl(true);
  };

  const handleSubmit = () => {
    if (url.length > 0) {
      let isValid = format === "url" ? urlRegex.test(url) : wnRegex.test("+" + prefix + url);
      if (isValid) {
        setIsValidUrl(true);
        agregarOActualizarElemento(arrRRSS, { rrss: onoffRRSS, url: url, prefix: prefix });
        handleCloseRRSS();
      } else {
        // La URL no es válida, muestra un mensaje de error o realiza la acción correspondiente
        setIsValidUrl(false);
      }
    } else {
      handleCloseRRSS();
      setIsValidUrl(true);
      setUrl("");
      setFormat("");
      eliminarElementoPorIndice(arrRRSS, onoffRRSS);
    }
  };

  function buscarElementoPorIndice(arreglo, rrssABuscar) {
    let rrss = { rrss: "", url: "" };
    const index = arreglo.findIndex(
      (elemento) => elemento.rrss === rrssABuscar
    );
    if (index !== -1) {
      rrss = arreglo[index];
    }
    return rrss;
  }

  function eliminarElementoPorIndice(arreglo, rrssAEliminar) {
    const index = arreglo.findIndex(
      (elemento) => elemento.rrss === rrssAEliminar
    );
    if (index !== -1) {
      arreglo.splice(index, 1);
    }
    onOffSocialNetworks(rrssAEliminar, false);
    props.recibirRedesSociales(arreglo);
  }

  function agregarOActualizarElemento(arreglo, nuevoRRSS) {
    const index = arreglo.findIndex(
      (elemento) => elemento.rrss === nuevoRRSS.rrss
    );
    if (index !== -1) {
      // Si el índice ya existe, reemplaza el objeto completo en el arreglo
      arreglo[index] = nuevoRRSS;
    } else {
      // Si el índice no existe, agrega un nuevo elemento al arreglo
      arreglo.push(nuevoRRSS);
    }
    onOffSocialNetworks(nuevoRRSS.rrss, true);
    props.recibirRedesSociales(arreglo);
  }

  function onOffSocialNetworks(rrss, flagOnOff) {
    const index = socialNetworks.findIndex((elemento) => elemento.id === rrss);
    if (index !== -1) {
      flagOnOff === true
        ? (socialNetworks[index].statusOO = true)
        : (socialNetworks[index].statusOO = false);
    } else {
      console.log("ops...no encontre red social :-O");
    }
  }

  function resetSocialNetworks() {
    for (let i = 0; i < socialNetworks.length; i++) {
      socialNetworks[i].statusOO = false;
    }     

   }

  return (
    <>
      <div>
        <Dialog open={openRRSS} onClose={handleCloseRRSS}>
          <DialogTitle component="span">
            Redes Sociales
            </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {title}
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={format === "wn" ? "numero de telefono" : "url"}
              type="text"
              fullWidth
              variant="standard"
              value={url}
              onChange={handleUrlChange}
              error={!isValidUrl}
              helperText={
                !isValidUrl
                  ? format === "wn"
                    ? "Numero de telefono invalido"
                    : "URL no válida"
                  : ""
              }
              InputProps={{
                startAdornment: (
                  format === "wn" ? <InputAdornment position="start">+{prefix}</InputAdornment> : ""
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRRSS}>Cancelar</Button>
            <Button onClick={handleClean}>Limpiar</Button>
            <Button onClick={handleSubmit}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      </div>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Item elevation={0}>
          <Typography variant="subtitle1" gutterBottom>
            Redes sociales
          </Typography>
        </Item>
        <Item elevation={0}>
          <ButtonGroup
            label="Link a redes sociales"
            size="small"
            variant="contained"
            aria-label="outlined primary button group"
          >
            {socialNetworks.map((network) => (
              <Tooltip key={network.id} title={network.name} placement="bottom">
                <div style={divStyle}>
                  <IconButton
                    key={network.id}
                    aria-label={network.name}
                    onClick={() => handleOnOffRRSS(network.id, network.format, network.title)}
                    size="small"
                  >
                    {network.statusOO ? (
                      <Badge badgeContent={"✓"} color="success">
                        {network.iconOn}
                      </Badge>
                    ) : (
                      network.iconOff
                    )}
                  </IconButton>
                </div>
              </Tooltip>
            ))}
          </ButtonGroup>
        </Item>
      </Stack>
    </>
  );
};

export default InputRRSS;
