import React from 'react';
import {Route, Routes, BrowserRouter} from "react-router-dom";

import Home from "./pages/Home";
import Publish from "./pages/Publish";


import Renew from "./pages/Renew";
import Commit from "./pages/Commit";
import Terminate from "./pages/Terminate";

import Error from "./pages/Error";
import Test from "./pages/Test";

import { MyThemeProvider } from './utils/ThemeContext'; // Importa el ThemeProvider


function App() {

  return (
      <MyThemeProvider >
      <BrowserRouter>
        <Routes>
          (process.env.REACT_APP_DEVELOP_MODE ?
            <Route path="/TestCE20231201PM" element={<Test/>}></Route>
          :
          ""
          )
          <Route path="/Commit" element={<Commit />}> </Route>
          <Route path="/Publish" element={<Publish />}></Route>
          <Route path="/Error" element={<Error />}></Route>
          <Route path="/Renew" element={<Renew />}> </Route>
          <Route path="/Terminate" element={<Terminate />}> </Route>

          <Route path="/*" element={<Home />}>   </Route>

        </Routes>

      </BrowserRouter>
      </MyThemeProvider>      
    );
}

export default App;
