import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const images = [
  {
    url: '/images/mibanner1.png',
    title: 'Ver Clasificados',
    width: '50%',
    to: "/",
  },
  {
    url: '/images/mibanner2.png',
    title: 'Publicar Anuncio',
    width: '50%',
    to: "/Publish",
  }
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 300,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 330,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: '100% 100%',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 80,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(37% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

export default function Banner() {
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));  
    const dialogStyles = {
      dialogPaper: {
          backgroundColor: "#c5c6c8"
      },
    };
  
    const HandleDialog = (url) => {
        if (url==="/") {
            setOpenBanner(false);
        }
        else {
            navigate(url);
        }
    
      };

      const [open, setOpen] = React.useState(false);
      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
    
      const [openBanner, setOpenBanner] = React.useState(true);
      const handleClickOpenBanner = () => {
        setOpenBanner(true);
      };
      const handleCloseBanner = () => {
        setOpenBanner(false);
      };

      const isVertical = useMediaQuery('(orientation: portrait)');
      
  return (

    <Dialog
    open={openBanner}
    aria-labelledby="responsive-dialog-title"
    fullScreen={fullScreen}
    fullWidth={true}
    aria-describedby="alert-dialog-description"
    scroll="body"
    PaperProps={{
        style: dialogStyles.dialogPaper,
      }}   
    onClose={handleCloseBanner} 
  >
      <DialogContent >

     <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%', padding:1, 
            minHeight: isVertical ? 600 : 300, // Ajusta la altura en función de la orientación
            maxHeight: isVertical ? 700 : 400, // Ajusta la altura en función de la orientación
    }}>     
      {images.map((image) => (
        <ImageButton
          focusRipple
          key={image.title}
          style={{
            width: image.width,
          }}

          onClick={() => HandleDialog(image.to)}          
        >
          <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="h5"
              color="inherit"
              sx={{
                position: 'relative',
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              {image.title}
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        </ImageButton>
      ))}
    </Box>

    </DialogContent>
        </Dialog>

  );
}
