// Función para agregar un objeto en localStorage
export function agregarObjeto(clave, valor) {
    try {
      localStorage.setItem(clave, JSON.stringify(valor));
      return true; // Éxito
    } catch (error) {
      console.error('Error al agregar en localStorage:', error);
      return false; // Error
    }
  }
  
  // Función para eliminar un objeto de localStorage
export  function eliminarObjeto(clave) {
    try {
      localStorage.removeItem(clave);
      return true; // Éxito
    } catch (error) {
      console.error('Error al eliminar de localStorage:', error);
      return false; // Error
    }
  }
  
  // Función para buscar un objeto en localStorage
export  function buscarObjeto(clave) {
    try {
      const valor = localStorage.getItem(clave);
      return valor ? JSON.parse(valor) : null; // Devuelve el objeto si se encuentra o null si no se encuentra
    } catch (error) {
      console.error('Error al buscar en localStorage:', error);
      return null; // Error
    }
  }
  