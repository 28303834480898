import * as React from "react";

import { useTheme } from "@mui/material/styles";

import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoodIcon from "@mui/icons-material/Mood";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { green, pink, red, blue, grey } from "@mui/material/colors";

import "./Ads.css";
import banner from "../utils/banner2.png";

export default function PrintAds(props) {
  const theme = useTheme();

  let anuncio = props.anuncio;

  const viewAds = (anuncio) => {
    props.funcion(anuncio);
  };

  const publishAds = () => {
    props.enlace();
  };

  return (
    <>
      {anuncio.tipoPublicacion === "dummy" ? (
        <>
          {anuncio.tipoAnuncio === "banner" ? (
            <div className="BasicAds">
              <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                <img
                  src={banner}
                  srcSet={banner}
                  alt="banner clasificadosExpress"
                  loading="lazy"
                  style={{ width: "100%" }}
                  height={55}
                  onClick={() => publishAds()}
                />
              </p>
            </div>
          ) : (
            ""
          )}
          {anuncio.tipoAnuncio === "grupo" ? (
            <div className="GroupAds" style={{backgroundColor:(theme.palette.mode === "dark" ? grey[800] : "#f0f0f0")}}>
              <p style={{ fontStyle: "italic", fontWeight: "bold" ,marginTop: "10px", marginBottom: "5px" }}>
                {anuncio.tituloAnuncio}
              </p>
            </div>
          ) : (
            ""
          )}
          {anuncio.tipoAnuncio === "subgrupo" ? (
            <div className="GroupAds" style={{backgroundColor:(theme.palette.mode === "dark" ? grey[800] : "#f0f0f0")}}>
              <p style={{ fontStyle: "italic", fontWeight: "bold", marginTop: "10px", marginBottom: "5px" }}>
                {anuncio.tituloAnuncio}
              </p>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {anuncio.tipoPublicacion === "banner" ? (
        <>
          {anuncio.tipoAnuncio === "basico" ? (
            <div className="BasicAds" onClick={() => viewAds(anuncio._id)} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                <img
                  src={anuncio.textoAnuncio}
                  srcSet={anuncio.textoAnuncio}
                  alt="imagen anuncio"
                  loading="lazy"
                  height={55}
                  width={"100%"}
                />
              </p>
            </div>
          ) : (
            ""
          )}

          {anuncio.tipoAnuncio === "full" ? (
            <div className="FullAds" onClick={() => viewAds(anuncio._id)} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                <img
                  src={anuncio.textoAnuncio}
                  srcSet={anuncio.textoAnuncio}
                  alt="imagen anuncio"
                  loading="lazy"
                  height={155}
                  width={"100%"}

                />
              </p>
            </div>
          ) : (
            ""
          )}

          {anuncio.tipoAnuncio === "premium" ? (
            <div className="PremiumAds" onClick={() => viewAds(anuncio._id)} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <p style={{ marginTop: "5px", marginBottom: "5px"}}>
                <img
                  src={anuncio.textoAnuncio}
                  srcSet={anuncio.textoAnuncio}
                  alt="imagen anuncio"
                  loading="lazy"
                  height={205}
                  width={"100%"}

                />
              </p>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {anuncio.tipoPublicacion === undefined ||
      anuncio.tipoPublicacion === "anuncio" ? (
        <>
          {anuncio.tipoAnuncio === "basico" ? (
            <>

            <div className="BasicAds" onClick={() => viewAds(anuncio._id)}    >

              <p style={{ marginBlock:"5px"}}>
                {anuncio.textoAnuncio}
                {". " + anuncio.comuna}
                {props.isFavorite ? (
                  <FavoriteIcon fontSize="small" sx={{ color: red[500] }} />
                ) : (
                  ""
                )}
                {anuncio.usarGPS && props.enableGpsIcon ? (
                  <LocationOnIcon fontSize="small" sx={{ color: green[500] }} />
                ) : (
                  ""
                )}
                {anuncio.usarRRSS && props.enableRRSSIcon ? (
                  <MoodIcon fontSize="small" sx={{ color: blue[500] }} />
                ) : (
                  ""
                )}
                {anuncio.tieneImagen ? (
                  <PhotoCameraIcon fontSize="small" color="primary"  />
                ) : (
                  ""
                )}
              </p>

            </div>

            </>


          ) : (
            ""
          )}

          {anuncio.tipoAnuncio === "full" ? (
            <div className="FullAds" onClick={() => viewAds(anuncio._id)}>
              <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                {anuncio.textoAnuncio}
                {". " + anuncio.comuna}
                {props.isFavorite ? (
                  <FavoriteIcon fontSize="small" sx={{ color: pink[500] }} />
                ) : (
                  ""
                )}
                {anuncio.usarGPS && props.enableGpsIcon ? (
                  <LocationOnIcon fontSize="small" sx={{ color: green[500] }} />
                ) : (
                  ""
                )}
                {anuncio.usarRRSS && props.enableRRSSIcon ? (
                  <MoodIcon fontSize="small" sx={{ color: blue[500] }} />
                ) : (
                  ""
                )}
                {anuncio.tieneImagen ? (
                  <PhotoCameraIcon fontSize="small" color="primary"  />
                ) : (
                  ""
                )}
              </p>
            </div>
          ) : (
            ""
          )}

          {anuncio.tipoAnuncio === "premium" ? (
            <div className="PremiumAds" onClick={() => viewAds(anuncio._id)}>
              <p
                style={{
                  fontStyle: "italic",
                  fontWeight: "bold",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {anuncio.tituloAnuncio}
              </p>
              <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                {anuncio.textoAnuncio}
                {". " + anuncio.comuna}
              </p>
              <p
                style={{
                  fontSize: "x-large",
                  fontWeight: "bold",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                {anuncio.pieAnuncio}
                {props.isFavorite ? (
                  <FavoriteIcon fontSize="small" sx={{ color: pink[500] }} />
                ) : (
                  ""
                )}
                {anuncio.usarGPS && props.enableGpsIcon ? (
                  <LocationOnIcon fontSize="small" sx={{ color: green[500] }} />
                ) : (
                  ""
                )}
                {anuncio.usarRRSS && props.enableRRSSIcon ? (
                  <MoodIcon fontSize="small" sx={{ color: blue[500] }} />
                ) : (
                  ""
                )}
                {anuncio.tieneImagen ? (
                  <PhotoCameraIcon fontSize="small" color="primary"  />
                ) : (
                  ""
                )}
              </p>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}
